/**
 * Custom select toolbar for measure table
 * 
 * @author JHO 
 * Last changed: 28.09.2021
 * Changes: PBI-475 Added download option
 */

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import EventIcon from '@material-ui/icons/Event';
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import ConfirmDialog from "../confirm/ConfirmDialog";

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {

  handleClickDelete = () => {
    this.props.onRowsDelete(this.props.selectedRows);
  };

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.iconContainer}>
        <Tooltip title={"Delete"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickDelete}>
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        <ConfirmDialog open={this.props.open} handleCancel={this.props.handleCancel} handleConfirm={this.props.handleConfirm} selectedRows={this.props.selectedRows}/>
      </div>
    );
  }
}

export default withTranslation()(withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect));
