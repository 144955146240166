import {useTranslation} from "react-i18next";
import Dialog from '@material-ui/core/Dialog';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '40vh',
    maxHeight: '70vh',
    maxWidth: '60vh',
    padding: theme.spacing(2)
  },
  warningIcon: {
    minHeight: '110px',
    minWidth: '110px',
    padding: "20px"
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px"
  },
  title: {
    display: "flex",
    flexFlow: "column ",
    alignItems: "center",
    fontSize: "20pt"
  },
  content: {
    fontSize: "10pt",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  text: {
    color: "primary",
    textAlign: "center",
    padding: "20px",
  }
}));

const ConfirmDialog = (props) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Dialog classes={{ paper: classes.dialog }} open={props.open} onClose={props.handleClose}>
      <Typography className={classes.title}>
        <WarningIcon className={classes.warningIcon} color="secondary"/>
        {t('delete_text_1')}
      </Typography>
      <DialogContent className={classes.content}>
        <Typography className={classes.text}>
          {t('delete_text_2')}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button onClick={props.handleCancel} color="primary" variant="contained">
          {t('cancel').toUpperCase()}
        </Button>
        <Button onClick={() => props.handleConfirm(props.selectedRows)} color="secondary" variant="contained">
          {t('delete').toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog;