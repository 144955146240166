/**
 * Landing component
 * 
 * @author JHO 
 * Last changed: 16.08.2021
 * Changes: Multilanguage support
 */
import './Landing.css';
import Button from '@material-ui/core/Button';
import logo from '../../common/lnc_logo.png';
import { useTranslation } from "react-i18next";
import withAppInsights from './../../common/appInsights'

function Landing() {
  const { t } = useTranslation();

  return (
      <header className="Menu-header">
      <img src={logo} className="Menu-logo" alt="logo" />
      <p className="Menu-text">
        {t('welcome_message')}
      </p>
    </header>
  );
}

export default withAppInsights(Landing);