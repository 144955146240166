/**
 * Devices component
 * 
 * @author MFA & JHO
 * Last changed: 06.10.2022
 * Changes: PBI-1079 Mobile device settings
 */

import './Devices.css';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import * as endpoints from '../../service/endpoints';
import * as routes from '../../routing/routes';
import * as constants from '../../common/constants';
import React, { useState, useEffect } from 'react';
import {checkError401, formatTimestamp} from '../../common/helpers';
import TableToolbar from "./TableToolbar";
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import withAppInsights from './../../common/appInsights';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import EditDeviceDialog from "./edit/EditDeviceDialog";
import AddDeviceDialog from "./add/AddDeviceDialog";
import ConfirmDialog from "../confirm/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  bread: {
    marginBottom: theme.spacing(1)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline'
    }     
  }
}));

const permissionLevels = require('../../common/permissions.json')

function Devices(props) {
  const [data, setData] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [device, setDevice] = useState({});
  const [deviceSettings, setDeviceSettings] = useState({});
  const [devicesLoading, setDevicesLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const classes = useStyles();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [rowsToDelete, setRowsToDelete] = useState([])

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const httpConfig = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  useEffect(() => {
    window.sessionStorage.setItem("lastRoute", routes.DEVICES);
  });

  const handleConfirmDelete = () => {
    setConfirmDialogOpen(true)
  }

  const handleCloseConfirm = () => {
    setConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    handleCloseConfirm()
  }

  const handleConfirm = (selectedRows) => {
    deleteDevices(selectedRows, 0)
    handleCloseConfirm()
  }

  const getDeviceSettings = (id) => {
    axios.get(endpoints.DEVICE_SETTINGS + "/" + id,httpConfig)
      .then(response => {
        setDeviceSettings(response.data);
        setOpenEdit(true);
      })
      .catch(error => {
        checkError401(error);
      })
  }

  const handleError = () => {
    console.log(props.userSettings)
    console.log(props.userSettings.notification_time)
    enqueueSnackbar(t('notification_error_general'), {
      variant: 'error',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const handleCloseEdit = (body) => {
    setOpenEdit(false);
    axios.patch(endpoints.DEVICE_SETTINGS + "/" + device._id,body,httpConfig)
      .then(response => {
        handleSuccessEdit();
      })
      .catch(error => {
        handleError();
      })
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleSuccessEdit = () => {
    axios.get(endpoints.DEVICES,httpConfig)
        .then(response => {
          setData(response.data.data);
        })
        .catch(error => {
          checkError401(error);
        })
    enqueueSnackbar(t('notification_success_device_edit'), {
      variant: 'success',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const handleSuccessAdd = () => {
    axios.get(endpoints.DEVICES,httpConfig)
        .then(response => {
          setData(response.data.data);
        })
        .catch(error => {
          checkError401(error);
        })
    enqueueSnackbar(t('notification_success_device_add'), {
      variant: 'success',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const deleteDevices = (rows, idx) => {
    axios.delete(endpoints.DEVICES + '/' + data[rows[idx].index]._id,httpConfig)
          .then(response => {
            if(idx < (rows.length -1)) {
              deleteDevices(rows, idx + 1);
            }
            else {
              axios.get(endpoints.DEVICES,httpConfig)
                .then(response => {
                  setData(response.data.data);
                })
                .catch(error => {
                  handleError();
                  checkError401(error);
                })
                enqueueSnackbar(t('notification_success_device_delete'), {
                  variant: 'info',
                  autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
                });
              }
          })
          .catch(error => {
            handleError();
          })
  }

  const refreshDevices = () => {
    setDevicesLoading(true)
    axios.get(endpoints.DEVICES,httpConfig)
      .then(response => {
        setData(response.data.data);
        setDevicesLoading(false)
      })
      .catch(error => {
        setDevicesLoading(false)
        checkError401(error);
      })
  }

// Data table
const options = {
  filterType: 'checkbox',
  print: false,
  enableNestedDataAccess: '.',
  rowsPerPageOptions: [10,20,50],
  customToolbar: () => {
    return (
      <TableToolbar handleClick={handleOpenAdd} actionRefresh={refreshDevices}/>
    );
  },
  onTableInit: (action, tableState) => {
    refreshDevices()
  },
  onRowsDelete: (rowsDeleted) => {
    setRowsToDelete(rowsDeleted.data)
    handleConfirmDelete()
  },
  textLabels: {
    body: {
        noMatch: devicesLoading ?
        <CircularProgress /> :
            t('table_no_data'),
            toolTip: t('table_body_toolTip'),    
          },
          selectedRows: {
            text: t('table_row_selected'),
            delete: t('table_row_delete'),
            deleteAria: t('table_row_deleteAria'),
          }, 
          pagination: {
            next: t('table_pagination_next'),
            previous: t('table_pagination_previous'),
            rowsPerPage: t('table_pagination_rowsPerPage'),
            displayRows: t('table_pagination_displayRows'),
            jumpToPage: t('table_pagination_jumpToPage'),
          },
          toolbar: {
            search: t('table_toolbar_search'),
            downloadCsv: t('table_toolbar_downloadCsv'),
            print: t('table_toolbar_print'),
            viewColumns: t('table_toolbar_viewColumns'),
            filterTable: t('table_toolbar_filterTable'),
          },
          filter: {
            all: t('table_filter_all'),
            title: t('table_filter_title'),
            reset: t('table_filter_reset'),
          },
          viewColumns: {
            title: t('table_viewColumns_title'),
            titleAria: t('table_viewColumns_titleAria'),
          },
    
},
};

const columns =
[
  {
    name: "androidID",
    label: t('table_header_deviceID'),
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: "name",
    label: t('table_header_name'),
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: "ip",
    label: t('table_header_deviceIP'),
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: "model",
    label: t('table_header_model'),
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: "status",
    label: t('table_header_status'),
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: "lastActivity",
    label: t('table_header_activity'),
    options: {
      filter: false,
      sort: true,
      customBodyRenderLite: (dataIndex) => formatTimestamp(data[dataIndex]["lastActivity"],props.userSettings ? props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT,t('locale')),
    }
  },
  {
    name: "lastUser.userName",
    label: t('table_header_last_user'),
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: "appVersion",
    label: t('table_header_version'),
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: "enabled",
    label: t('table_header_enabled'),
    options: {
      sort: true,
      empty: true,
      customBodyRenderLite: (dataIndex) => {
        return (
          <Checkbox
            checked={data[dataIndex].enabled}
            onChange={(e) => { 
              let items = [...data];
              let item = {...data[dataIndex]};
              e.target.checked ? item.enabled = 1 : item.enabled = 0;
              //update device
              axios.patch(endpoints.DEVICES + '/' + item._id,item,httpConfig)
                .then(response => {
                  if(e.target.checked) {
                    enqueueSnackbar(t('notification_success_device_activate'), { 
                      variant: 'info',
                      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
                    });
                  }
                  else {
                    enqueueSnackbar(t('notification_success_device_deactivate'), { 
                      variant: 'info',
                      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
                    });
                  }
                })
                .catch(error => {
                  enqueueSnackbar(t('notification_error_general'), { 
                    variant: 'error',
                    autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
                  });
                })

                items[dataIndex] = item;
                setData(items)
             }}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          );
        }
      }
    },
  {
  name: "edit",
  label: t('component_header_settings'),
  options: {
    filter: false,
    sort: true,
    empty: true,
    customBodyRenderLite: (dataIndex) => {
      return (
        <IconButton color="primary" aria-label="add an alarm" onClick={() => {setDevice({...data[dataIndex]}); getDeviceSettings({...data[dataIndex]}._id)}}>
          <EditIcon />
        </IconButton>
        );
      }
    }
  }
];

return (
<div className="Devices">
    <Breadcrumbs aria-label="breadcrumb" className={classes.bread}>
      <Link to={routes.DASHBOARD} className={classes.link} onClick={() => {
        props.setSelectedItem(1)
        props.setBold(1)
      }}>
        <HomeIcon className={classes.icon} />
      </Link>
      <Link to={routes.DEVICES} className={classes.link}>
      {t('component_header_devices')}
      </Link>
    </Breadcrumbs>
    <MUIDataTable
      title={""}
      data={data}
      columns={columns}
      options={options}
    />
    <AddDeviceDialog open={openAdd} handleClose={handleCloseAdd} handleSuccess={handleSuccessAdd} handleError={handleError} token={props.token}/>
    <EditDeviceDialog open={openEdit} deviceSettings={deviceSettings} handleClose={handleCloseEdit} handleSuccess={handleSuccessEdit} handleError={handleError} token={props.token} user={props.user} sites={props.sites}/>
    <ConfirmDialog open={confirmDialogOpen} handleCancel={handleCancel} handleConfirm={handleConfirm} selectedRows={rowsToDelete}/>
  </div>
  );
}

export default withAppInsights(Devices);
