/**
 * CSV export functions
 * 
 * @author JHO 
 * Last changed: 12.01.2022
 * Changes: PBI-703 Added export function to work with measure data
 */

 import axios from 'axios';
 import * as endpoints from '../../service/endpoints';
 import * as constants from '../../common/constants';
 import { convertLength, convertVolume, convertWeight, formatTimestamp} from '../../common/helpers'

 export function buildCSVContent(columns,data,exportMeasuresOption,exportAllArticles,props,t,httpConfig,fromMeasures) {
    let header = ''
    const fields = (props.csvConfig.active !== undefined && props.csvConfig.templates[props.csvConfig.active]) ? props.csvConfig.templates[props.csvConfig.active].fields : constants.EXPORT_FIELDS
    const useHeader = (props.csvConfig.active !== undefined && props.csvConfig.templates[props.csvConfig.active]) ? props.csvConfig.templates[props.csvConfig.active].headerline : constants.HEADER_DEFAULT
    const delimiter = (props.csvConfig.active !== undefined && props.csvConfig.templates[props.csvConfig.active]) ? props.csvConfig.templates[props.csvConfig.active].delimiter : constants.DELIMITER_DEFAULT
    const dateFormat = (props.csvConfig.active !== undefined && props.csvConfig.templates[props.csvConfig.active]) ? props.csvConfig.templates[props.csvConfig.active].date_format : constants.DATE_FORMAT_DEFAULT;
    const lengthUnit = (props.csvConfig.active !== undefined && props.csvConfig.templates[props.csvConfig.active]) ? props.csvConfig.templates[props.csvConfig.active].length_unit : constants.LENGTH_UNIT_DEFAULT;
    const volumeUnit = (props.csvConfig.active !== undefined && props.csvConfig.templates[props.csvConfig.active]) ? props.csvConfig.templates[props.csvConfig.active].volume_unit : constants.VOLUME_UNIT_DEFAULT;
    const weightUnit = (props.csvConfig.active !== undefined && props.csvConfig.templates[props.csvConfig.active]) ? props.csvConfig.templates[props.csvConfig.active].weight_unit : constants.WEIGHT_UNIT_DEFAULT;

    for(let idx in fields) {
          if(useHeader) {
            if(fields[idx].header === "Image path") {
              for(let i = 1; i < 4; i++) {
                header = header.concat(fields[idx].header + " " + i)
                header = header.concat(delimiter)
              }
            }
            else if(fields[idx].header.includes("Custom")) {
              switch(fields[idx].header) {
                case "Custom String 1":
                  header = header.concat((props.customFields.custom_string_1 && props.customFields.custom_string_1.name !== "")  ? props.customFields.custom_string_1.name : fields[idx].header);
                  break;
                case "Custom String 2":
                  header = header.concat((props.customFields.custom_string_2 && props.customFields.custom_string_2.name !== "")  ? props.customFields.custom_string_2.name : fields[idx].header);
                  break;
                case "Custom Int 1":
                  header = header.concat((props.customFields.custom_int_1 && props.customFields.custom_int_1.name !== "") ? props.customFields.custom_int_1.name : fields[idx].header);
                  break;
                case "Custom Int 2":
                  header = header.concat((props.customFields.custom_int_2 && props.customFields.custom_int_2.name !== "")  ? props.customFields.custom_int_2.name : fields[idx].header);
                  break;
                case "Custom Float":
                  header = header.concat((props.customFields.custom_float && props.customFields.custom_float.name !== "") ? props.customFields.custom_float.name : fields[idx].header);
                  break;
                default:
                  break;
              }
              header = header.concat(delimiter)
            } 
            else {
              header = header.concat(t(fields[idx].lang_key))
              header = header.concat(delimiter)
              if(fields[idx].header.includes("Height")) {
                header = header.concat(t("export_header_length_unit"))
                header = header.concat(delimiter)
              }
              else if(fields[idx].header.includes("Weight")) {
                header = header.concat(t("export_header_weight_unit"))
                header = header.concat(delimiter)
              }
              else if(fields[idx].header.includes("Volume")) {
                header = header.concat(t("export_header_volume_unit"))
                header = header.concat(delimiter)
              }
            }
          }
    }
    header = header.slice(0,-1)
    if(useHeader)
      header = header.concat('\n')
    
    if(!fromMeasures)
      return header + buildCSVBodyFromArticle(data,delimiter,fields,exportMeasuresOption,exportAllArticles,httpConfig,props,dateFormat,lengthUnit,weightUnit,volumeUnit)
    else
      return header + buildCSVBodyFromMeasures(data,delimiter,fields,exportMeasuresOption,httpConfig,props,dateFormat,lengthUnit,weightUnit,volumeUnit)
}

// csv body from measure json
const buildCSVBodyFromMeasures = (data,separator,fields,exportAllMeasures,httpConfig,props,dateFormat,lengthUnit,weightUnit,volumeUnit) => {
  let body = ''
  for(let measure of data) {
      for(let field of fields) {
        if(field.key.includes("Measures")) {
          if(field.key.includes("images")) {
              for(let i = 0; i < 3; i++) {
                if(measure[field.key.split(".")[1]].length > i) {
                  body = body.concat(measure[field.key.split(".")[1]][i].path)
                }
                if(i < 2)
                  body = body.concat(separator)
              }
          }
          else {
            let value = measure[field.key.split(".")[1]]
            if(field.key.includes("length") || field.key.includes("width") || field.key.includes("height")) {
              value = convertLength(measure[field.key.split(".")[1]],lengthUnit,2, true)
            } else if(field.key.includes("weight")) {
              value = convertWeight(measure[field.key.split(".")[1]], weightUnit,2, true)
            } else if(field.key.includes("volume")) {
              value = convertVolume(measure[field.key.split(".")[1]], volumeUnit,2, true)
            } else if(field.key.includes("timeStamp") || field.key.includes("updatedAt")) {
              value = formatTimestamp(measure[field.key.split(".")[1]], 'export',"de")
            } else if(field.key.includes("barcode") ) {
              value = measure[field.key.split(".")[1]] ? measure[field.key.split(".")[1]].barcode : ''
            }
            body = body.concat(value)
            body = body.concat(separator)

            if(field.key.includes("height")) {
              body = body.concat(lengthUnit)
              body = body.concat(separator)
            } else if(field.key.includes("weight")) {
              body = body.concat(weightUnit)
              body = body.concat(separator)
            }
            else if(field.key.includes("volume")) {
              body = body.concat(volumeUnit + "3")
              body = body.concat(separator)
            }
        }}
        else {
          if(measure["bundle"][field.key] && (field.key === "handlingUnit" || field.key === "itemsPerHU")){
            body = body.concat(measure["bundle"][field.key])
          }
          else if(measure["article"] && measure["article"][field.key]) {
            body = body.concat(measure["article"][field.key])
          }
          body = body.concat(separator)
        }
      }
      body = body.slice(0,-1)
      body = body.concat('\n')
      patchExported(measure.id,httpConfig)
  }
  return body
}

// csv body from article json
const buildCSVBodyFromArticle  = (data,separator,fields,exportMeasureOptions,exportAllArticles,httpConfig,props,dateFormat,lengthUnit,weightUnit,volumeUnit) => {
    let body = ''
    for(let el of data) {
      if(el["measures"].length > 0) {
        for(let measureIdx = el["measures"].length - 1; measureIdx >= 0; measureIdx--) {

          // continue if export only selected measure enabled and measure is not selected one
          if(exportMeasureOptions === constants.MEASURE_EXPORT_VALUES.SELECTED && el["measures"][measureIdx].id !== el.pickedMeasureID)
            continue;

          // create csv line
          for(let field of fields) {
            if(field.key.includes("Measures")) {
              if(field.key.includes("images")) {
                if(el["measures"].length > measureIdx && el["measures"][measureIdx][field.key.split(".")[1]]) {
                  for(let i = 0; i < 3; i++) {
                    if(el["measures"][measureIdx][field.key.split(".")[1]].length > i) {
                      body = body.concat(el["measures"][measureIdx][field.key.split(".")[1]][i].path)
                    }
                    if(i < 2)
                      body = body.concat(separator)
                  }
                }
              }
              else {
                if(el["measures"].length > measureIdx && el["measures"][measureIdx][field.key.split(".")[1]]) {
                  let value = el["measures"][measureIdx][field.key.split(".")[1]]
                  if(field.key.includes("length") || field.key.includes("width") || field.key.includes("height")) {
                    value = convertLength(el["measures"][measureIdx][field.key.split(".")[1]],lengthUnit,2, true)
                  } else if(field.key.includes("weight")) {
                    value = convertWeight(el["measures"][measureIdx][field.key.split(".")[1]], weightUnit,2, true)
                  } else if(field.key.includes("volume")) {
                    value = convertVolume(el["measures"][measureIdx][field.key.split(".")[1]], volumeUnit,2, true)
                  } else if(field.key.includes("timeStamp") || field.key.includes("updatedAt")) {
                    value = formatTimestamp(el["measures"][measureIdx][field.key.split(".")[1]], "export","de")
                  }
                  body = body.concat(value)
                }
                body = body.concat(separator)

                if(field.key.includes("height")) {
                  body = body.concat(lengthUnit)
                  body = body.concat(separator)
                } else if(field.key.includes("weight")) {
                  body = body.concat(weightUnit)
                  body = body.concat(separator)
                }
                else if(field.key.includes("volume")) {
                  body = body.concat(volumeUnit + "3")
                  body = body.concat(separator)
                }
            }}
            else {
              if(el[field.key])
                body = body.concat(el[field.key])
              body = body.concat(separator)
            }
          }
          body = body.slice(0,-1)
          body = body.concat('\n')

          //set measure as exported
          patchExported(el["measures"][measureIdx].id,httpConfig)

          //end if not all measure per article should be exported
          if(exportMeasureOptions !== constants.MEASURE_EXPORT_VALUES.ALL)
            break;
        }
      }
      else if(exportAllArticles) {
        //export articles without measure
        for(let field of fields) {
          if(!field.key.includes("Measures")) {
            if(el[field.key])
              body = body.concat(el[field.key])
          }
          body = body.concat(separator)
        }
        body = body.slice(0,-1)
        body = body.concat('\n')
      }
    }
    return body
}

const patchExported = (measureId,httpConfig) => {
    const measureBody = {
      "exported": 1
    }
    axios.patch(endpoints.MEASURES + '/' + measureId,measureBody,httpConfig)
      .then(response => {
      })
      .catch(error => {
      })
};