/**
 * Add plan dialog
 * 
 * @author JHO 
 * Last changed: 19.01.20212
 * Changes: initial implementation
 */

 import React from 'react';
 import Dialog from '@material-ui/core/Dialog';
 import AddPlanForm from './AddPlanForm';
 import Typography from '@material-ui/core/Typography';
 import { makeStyles } from '@material-ui/core/styles';
 import { useTranslation } from "react-i18next";
import users from "../../users/Users";
 
 //size of measure history dialog
const useStyles = makeStyles((theme) => ({
    dialog: {
        minHeight: '50vh',
        maxHeight: '70vh',
        padding: theme.spacing(2)
     },
}));

 const AddPlanDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Dialog classes={{ paper: classes.dialog }} fullWidth={true} maxWidth={'md'} open={props.open} onClose={props.handleClose}>
        <Typography variant="h5" color="black" gutterBottom>
          {t("create_new_plan")}
        </Typography>
        <AddPlanForm userSettings={props.userSettings} users={props.users} handleClose={props.handleClose} token={props.token} permissionLevel={props.permissionLevel}/>
        </Dialog>
    );
 };
 
 export default AddPlanDialog;