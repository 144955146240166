/**
 * Edit article dialog
 *
 * @author JHO
 * Last changed: 30.08.2021
 * Changes: added custom fields to props
 */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import AddToPlanForm from "./AddToPlanForm";

//size of measure history dialog
const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '50vh',
    maxHeight: '70vh',
    padding: theme.spacing(2)
  },
}));

const AddToPlanDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth={true} maxWidth={'md'} open={props.open} onClose={props.handleClose}>
      <Typography variant="h5" color="black" gutterBottom>
        {t('add_to_plan')}
      </Typography>
      <AddToPlanForm userSettings={props.userSettings} handleClose={props.handleClose} handleError={props.handleError} token={props.token} plans={props.plans} selectedRows={props.selectedRows} planID={props.planID}/>
    </Dialog>
  );
};

export default AddToPlanDialog;