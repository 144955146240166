/**
 * Multilanguage support library setup
 * 
 * @author JHO 
 * Last changed: 16.08.2021
 * Changes: initial config
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./common/locales/en/translation.json";
import translationDE from "./common/locales/de/translation.json";
import translationSR from "./common/locales/sr/translation.json";
import translationSL from "./common/locales/sl/translation.json";
import translationHR from "./common/locales/hr/translation.json";



const fallbackLng = ["en"];
const availableLanguages = ["en", "de", "sr", "sl", "hr"];

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  sr: {
    translation: translationSR
  },
  sl: {
    translation: translationSL
  },
  hr: {
    translation: translationHR
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
