/**
 * Main component
 * 
 * @author JHO 
 * Last changed: 23.12.2021
 * Changes: PBI-674 Added portal table column settings
 */

import './App.css';
import React, { useEffect, useState } from 'react';
import Login from '../login/Login';
import ForgotPassword from '../forgotpassword/ForgotPassword';
import SignUp from '../signup/SignUp';
import ResetPassword from '../resetpassword/ResetPassword';
import VerifyAccount from '../verifyaccount/VerifyAccount';
import Navigation from '../navigation/Navigation';
import Error404 from '../error404/Error404';
import { useSnackbar } from 'notistack';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import * as routes from '../../routing/routes';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./../../common/appInsights";
import axios from "axios";
import * as constants from "../../common/constants";
import { useTranslation } from "react-i18next";
import i18next from "i18next";


const themeJson = require('../../common/theme.json');
const theme = createMuiTheme(themeJson);

function App() {

  const { enqueueSnackbar } = useSnackbar();
  const [tooManyRequests, setTooManyRequests] = useState(true)
  const { t } = useTranslation();
  const [warning, setWarning] = useState(() => {
    return window.localStorage.getItem("warning") ? (window.localStorage.getItem("warning") === 'true') : false;
  });
  const [token, setToken] = useState(() => {
    return window.localStorage.getItem("token") || null;
  });
  const [userId, setUserId] = useState(() => {
    return window.localStorage.getItem("userId") || null;
  });
  const [permissionLevel, setPermissionLevel] = useState(() => {
    return window.localStorage.getItem("permissionLevel") || null;
  });
  const [csvConfig, setCSVConfig] = useState(() => {
    const saved = window.localStorage.getItem("csvConfig");
    try {
      const initialValue = JSON.parse(saved);
      return initialValue || null;
    } catch (error) {
      return null;
    }
  });
  const [user, setUser] = useState(() => {
    const saved = window.localStorage.getItem("user");
    try {
      const initialValue = JSON.parse(saved);
      return initialValue || null;
    } catch (error) {
      return null;
    }
  });
  const [customFields, setCustomFields] = useState(() => {
    const saved = window.localStorage.getItem("customFields");
    try {
      const initialValue = JSON.parse(saved);
      return initialValue || null;
    } catch (error) {
      return null;
    }
  });
  const [handlingUnits, setHandlingUnits] = useState(() => {
    const saved = window.localStorage.getItem("handlingUnits");
    try {
      const initialValue = JSON.parse(saved);
      return initialValue || null;
    } catch (error) {
      return null;
    }
  });
  const [userSettings, setUserSettings] = useState(() => {
    const saved = window.localStorage.getItem("userSettings");
    try {
      const initialValue = JSON.parse(saved);
      return initialValue || null;
    } catch (error) {
      return null;
    }
  });
  const [permissionSettings, setPermissionSettings] = useState(() => {
    const saved = window.localStorage.getItem("permissions");
    try {
      const initialValue = JSON.parse(saved);
      return initialValue || null;
    } catch (error) {
      return null;
    }
  });
  const [appSettings, setAppSettings] = useState(() => {
    const saved = window.localStorage.getItem("vosapp");
    try {
      const initialValue = JSON.parse(saved);
      return initialValue || null;
    } catch (error) {
      return null;
    }
  });
  const [portalSettings, setPortalSettings] = useState(() => {
    const saved = window.localStorage.getItem("portalSettings");
    try {
      const initialValue = JSON.parse(saved);
      return initialValue || null;
    } catch (error) {
      return null;
    }
  });
  const [sites, setSites] = useState(() => {
    const saved = window.localStorage.getItem("sites");
    const initialValue = JSON.parse(saved);
    return initialValue || null;
  });
  const [lastRoute, setLastRoute] = useState(() => {
    return window.sessionStorage.getItem("lastRoute") || routes.DASHBOARD;
  });

  useEffect(() => {
    if (!tooManyRequests) {
      enqueueSnackbar(t('error_429'), {
        variant: 'error',
        autoHideDuration: 5000
      });
    }
  }, [tooManyRequests]);

  axios.interceptors.request.use(function (request) {
    // console.log(request);
    return request;
  })
  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    // Do something with response data
    // console.log(response)
    setTooManyRequests(true)
    return response;
  }, function (error) {
    console.log(tooManyRequests)
    if (error.response && error.response.status === 429 && tooManyRequests) {
      setTooManyRequests(false)
    }

    // Do something with response error
    return Promise.reject(error);
  });

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path={routes.MAIN}>
              {!token ? <Redirect to={routes.LOGIN} /> : <Redirect to={lastRoute} />}
            </Route>
            <Route path={routes.MENU}>
              {!token ? <Redirect to={routes.LOGIN} /> : <Redirect to={lastRoute} />}
              <Navigation user={user} warning={warning} token={token} userId={userId} sites={sites} setSites={setSites} permissionLevel={permissionLevel} csvConfig={csvConfig} setCSVConfig={setCSVConfig} customFields={customFields} setCustomFields={setCustomFields} userSettings={userSettings} setUserSettings={setUserSettings} permissionSettings={permissionSettings} setPermissionSettings={setPermissionSettings} handlingUnits={handlingUnits} setHandlingUnits={setHandlingUnits} appSettings={appSettings} setAppSettings={setAppSettings} portalSettings={portalSettings} setPortalSettings={setPortalSettings} />
            </Route>
            <Route path={routes.LOGIN}>
              {!token ? <Redirect to={routes.LOGIN} /> : <Redirect to={lastRoute} />}
              <Login setUser={setUser} setSites={setSites} setWarning={setWarning} setToken={setToken} setUserId={setUserId} setPermissionLevel={setPermissionLevel} setCSVConfig={setCSVConfig} setCustomFields={setCustomFields} setUserSettings={setUserSettings} setPermissionSettings={setPermissionSettings} setAppSettings={setAppSettings} setPortalSettings={setPortalSettings} />
            </Route>
            <Route path={routes.FORGOT}>
              <ForgotPassword />
            </Route>
            <Route path={routes.SIGNUP}>
              <SignUp />
            </Route>
            <Route path={routes.RESET}>
              <ResetPassword />
            </Route>
            <Route path={routes.VERIFY}>
              <VerifyAccount />
            </Route>
            <Route component={Error404} />
          </Switch>
        </Router>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  );
}

export default App;

