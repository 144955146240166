/**
 * Daily measures chart component
 *
 * @author JHO
 * Last changed: 04.11.2021
 * Changes: PBI-623 Initial implementation
 */

import { Bar, Line } from "react-chartjs-2";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  CardBody,
  Grid,
  Avatar,
  useTheme,
  colors,
  Typography,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  formatDailyMeasuresCount,
  formatDailyMeasuresDate,
  formatDailyMeasuresDateSpan,
  formatDailyMeasuresCountSpan,
} from "../../../common/helpers";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@mdi/react";
import { mdiWheelchairAccessibility } from "@mdi/js";
import { mdiChartLine } from "@mdi/js";
import React, { useState, useEffect } from "react";
import * as endpoints from "../../../service/endpoints";
import axios from "axios";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import dayjs from "dayjs";
import { DatePicker, Space, Button } from "antd";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#ef7d00",
    height: 56,
    width: 56,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#999999",
    },
  },
  cards: {
    //height: '100%',
    boxShadow:
      "rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "0.75rem",
  },
}));

const rangePresets = [
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
];

const DailyMeasures = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;

  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");
  const formattedFromDate = `${year}-${month}-${day - 1}T00:00:00`;
  const formattedToDate = `${year}-${month}-${day}T23:59:59`;

  const [fromDate, setFromDate] = useState(formattedFromDate);
  const [toDate, setToDate] = useState(formattedToDate);
  const [firstMeasureData, setFirstMeasureData] = useState();
  const [secondMeasureData, setSecondMeasureData] = useState();
  const [amountDays, setAmountDays] = useState(1);
  const [data, setData] = useState({});
  const [compareGraph, isCompareGraph] = useState(false);

  const httpConfig = {
    headers: { Authorization: `Bearer ${props.token}` },
  };

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = {
        fromDate: fromDate || formattedFromDate,
        toDate: toDate || formattedToDate,
      };

      try {
        await axios
          .get(endpoints.STATISTICS_MEASURES_FROM_TO, {
            ...httpConfig,
            params: queryParams,
          })
          .then((response) => {
            setFirstMeasureData(response.data.daily_measures_firstMeasure);
            setSecondMeasureData(response.data.daily_measures_secondMeasure);
            setAmountDays(response.data.amount_days);
          });
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [fromDate, toDate]);

  useEffect(() => {
    if (firstMeasureData !== undefined && secondMeasureData !== undefined) {
      setData({
        datasets: [
          {
            key: "dataset_this_measure",
            backgroundColor: theme.palette.secondary.main,
            barPercentage: 0.5,
            barThickness: 15,
            borderRadius: 4,
            categoryPercentage: 0.5,
            data: formatDailyMeasuresCountSpan(
              firstMeasureData,
              true,
              amountDays,
              toDate
            ),
            label: t("this_measure"),
            maxBarThickness: 30,
          },
          compareGraph
            ? {
                key: "dataset_last_measure",
                backgroundColor: colors.grey[300],
                barPercentage: 0.5,
                barThickness: 12,
                borderRadius: 4,
                categoryPercentage: 0.5,
                data: formatDailyMeasuresCountSpan(
                  secondMeasureData,
                  false,
                  amountDays,
                  toDate
                ),
                label: t("last_measure"),
                maxBarThickness: 10,
              }
            : {},
        ],
        labels: formatDailyMeasuresDateSpan(amountDays, toDate),
      });
    }
  }, [firstMeasureData, secondMeasureData, amountDays, compareGraph]);

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 10 },
    legend: { display: false },
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      const date0 = new Date(dates[0]);
      const year1 = date0.getFullYear();
      const month1 = (date0.getMonth() + 1).toString().padStart(2, "0");
      const day1 = date0.getDate().toString().padStart(2, "0");
      const fromRange = `${year1}-${month1}-${day1}T00:00:00`;

      const date1 = new Date(dates[1]);
      const year2 = date1.getFullYear();
      const month2 = (date1.getMonth() + 1).toString().padStart(2, "0");
      const day2 = date1.getDate().toString().padStart(2, "0");
      const toRange = `${year2}-${month2}-${day2}T23:59:59`;

      setFromDate(fromRange);
      setToDate(toRange);
    } else {
      setFromDate(formattedFromDate);
      setToDate(formattedToDate);
    }
  };

  const handleCompareButton = () => {
    isCompareGraph(!compareGraph);
  };

  return (
    <Card className={classes.cards}>
      <CardContent>
        <Grid
          container
          spacing={1}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Grid item xs={12} sm={4}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
              align="center"
            >
              {t("dashboard_daily_measures")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box style={{ position: "relative" }}>
                <Space direction="vertical" size={12}>
                  <RangePicker
                    presets={rangePresets}
                    onChange={onRangeChange}
                    defaultValue={[dayjs(fromDate), dayjs(toDate)]}
                    style={{ width: "130%" }}
                  />
                </Space>
              </Box>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              onClick={handleCompareButton}
              fullWidth
              style={{
                marginLeft: "12px",
                backgroundColor: compareGraph ? "blue" : "white",
                color: compareGraph ? "white" : "black", 
              }}
            >
              {t("compare")}
            </Button>
          </Grid>
        </Grid>
        <Box style={{ position: "relative" }}>
          <Line data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default DailyMeasures;
