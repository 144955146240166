/**
 * Articles component
 * 
 * @author JHO 
 * Last changed: 11.01.2021
 * Changes: PBI-723 Added conflicts
 */
import './Articles.css';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import * as endpoints from '../../service/endpoints';
import * as routes from '../../routing/routes';
import * as constants from '../../common/constants';
import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import MeasureHistory from './measureHistory/MeasureHistory';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import Chip from '@material-ui/core/Chip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EditArticleDialog from './edit/EditArticleDialog';
import ConfirmDialog from  '../confirm/ConfirmDialog';
import ExportSettingsDialog from './export/ExportSettingsDialog';
import Backdrop from '@material-ui/core/Backdrop';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ShowImagesDialog from '../measures/images/ShowImagesDialog';
import EventIcon from '@material-ui/icons/Event';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from "react-i18next";
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import CustomToolbar from "./table/CustomToolbar";
import CustomToolbarSelect from "./table/CustomToolbarSelect";
import { formatTimestamp, checkError401, reformatDate, getNumberOfConflicts, setConflicts} from '../../common/helpers';
import { useSnackbar } from 'notistack';
import MuiAlert from '@material-ui/lab/Alert';
import { buildCSVContent } from '../../common/csv/export'
import { buildImportArticlesBody } from '../../common/csv/import'
import AddIcon from '@material-ui/icons/Add';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import {format, isBefore, isValid} from "date-fns";
import {FormGroup, FormLabel, TableCell, TextField} from "@material-ui/core";
//import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; 
import { DateTimePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import fileDownload from 'js-file-download';
import ClearIcon from "@material-ui/icons/Clear";
import { debounce } from 'lodash';
import withAppInsights from './../../common/appInsights'

import AddToPlanDialog from "./plan/AddToPlanDialog";
import de from 'date-fns/locale/de';
import moment from 'moment';
import DoneIcon from '@material-ui/icons/Done';

//size of measure history dialog
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '10vh',
    maxHeight: '90vh',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  progress: {
    width: 'auto',
    display: 'block'
  },
  dialog: {
    minHeight: '30vh',
    maxHeight: '90vh',
    padding: theme.spacing(2),
    alignItems: 'left'
  },
  bread: {
    marginBottom: theme.spacing(2),
    display: 'inline'
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  selectedButton: {
    flexGrow: 1,
  },
  fullWidth: {
    width: '100vh',
    height: '10vh',
    marginBottom: theme.spacing(2),
    display: 'inline'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline'
    }
  },
  child: {
    display: 'inline'
  },
  MuiChip: {
    outlined: {
      border: 'none',
      backgroundColor: "#e0e0e0"
    }
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap' 
  } 
}));

function Articles(props) {
  //usages
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  //state
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDataConflicts, setSelectedDataConflicts] = useState(false);
  const [ok, setOK] = useState(0);
  const [error, setError] = useState(0);
  const [updated, setUpdated] = useState(0);
  const [importDialog, setImportDialog] = useState((window.location.href.indexOf("import") > -1));
  const [importInfoDialog, setImportInfoDialog] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAddToPlan, setOpenAddToPlan] = useState(false);
  const [dataPlanned, setDataPlanned] = useState([]);
  const [openExport, setOpenExport] = useState((window.location.href.indexOf("export") > -1));
  const [historyArticleData, setHistoryArticleData] = useState([]);
  const [articlesLoading, setArticlesLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [loadProgress, setLoadProgress] = useState(0);
  const [historyRequest, setHistoryRequest] = useState(false);
  const [currentArticle, setCurrentArticle] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [imagesRequest, setImagesRequest] = useState(false);
  const [imagesDialogState, setImagesDialogState] = React.useState({
    "openImages": false,
    "imageSteps": [],
    "loaded": false
  })
  const [update, setUpdate] = useState(false);
  const [promises, setPromises] = useState([]);
  const [pickedMeasureID, setPickedMeasureID] = useState();
  const [currentArticleID, setCurrentArticleID] = useState();
  const [numberOfConflicts, setNumberOfConflicts] = useState(getNumberOfConflicts(data))
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [sortColumn, setSortColumn] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState(null);
  const [sortParam, setSortParam] = React.useState(null);
  const [filterValues, setFilterValues] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const history = useHistory();

  //configs
  const httpConfig = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  useEffect(() => {
    window.sessionStorage.setItem("lastRoute", routes.ARTICLES);
  });

  useEffect(() => {
    if(selectedData.length > 0)
    {
      for(const item of selectedData)
      {
        if(item.measures.length > 1 && item.pickedMeasureID === null)
        {
          setSelectedDataConflicts(true)
          return
        }
      }
      setSelectedDataConflicts(false)
    }
  });

  const handleCloseImport = () => {
    axios.get(endpoints.BUNDLES + "?size=" + rowsPerPage, httpConfig)
      .then(response => {
        setData(response.data.data);
        handleClose();
      })
      .catch(error => {
        console.log(error);
        checkError401(error);
      })
  };

  const handleOnComplete = () => {
    showSnackbar();
  }

  const handleDownloadAll = () => {
    setSelectedData(data);
    setOpenExport(true);
  }

  const handleDownload = (selectedRows) => {
    let selectedData = []
    for (let row of selectedRows) {
      selectedData.push(data[row.dataIndex])
    }
    setSelectedData(selectedData);
    setOpenExport(true);
  }

  const handleAddToPlan = (selectedRows) => {
    let selectedData = []
    for (let row of selectedRows) {
      selectedData.push(data[row.dataIndex])
    }
    setSelectedData(selectedData);
    getOpenPlans()
    setOpenAddToPlan(true)
  }

  const handleConfirmDelete = (selectedRows) => {
    setConfirmDialogOpen(true)
  }

  const handleCloseConfirm = () => {
    setConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    handleCloseConfirm()
  }

  const handleConfirm = (selectedRows) => {
    handleDelete(selectedRows)
    handleCloseConfirm()
  }

  const handleDelete = (selectedRows) => {
    if(selectedRowsNumber === totalRows){
      const queryParams = generateQueryString(filterValues);
      deleteBundles({...JSON.parse(queryParams), search: searchValue});
      setSelectAllChecked(false);
    }
    else{
      let temp = [];
      let deleteData = [];
      for (var i = 0; i < data.length; i++) {
        if (!selectedRows.includes(i)) {
          temp.push(data[i]);
        }
        else {
          deleteData.push(data[i]);
        }
      }
      setData(temp);
      if (deleteData.length > 0){
        deleteArticlesById(deleteData, 0);
      }
  }
    setSelectedRows([]);
    setSelectedRowsNumber(0);
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      getArticlesByPageAndSize({page: 1, size: rowsPerPage, search: searchValue, sort: sortParam});
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [searchValue])

  const getOpenPlans = () => {
    axios.get(endpoints.PLANS + "?status=Created",httpConfig)
      .then(response => {
        setDataPlanned(response.data.data)
      })
      .catch(error => {
        console.log(error);
        //checkError401(error);
      })
  }

  // handle column sort change event
  const handleColumnSortChange = (columnName, direction) => {
    setSortColumn(columnName);
    setSortOrder(direction);
    const sort = `${direction === 'desc' ? '-' : ''}${columnName}`;
    setSortParam(sort)
    getArticlesByPageAndSize({page: 1, size: rowsPerPage, search: searchValue, sort: sort});

  };

  const setInitialConflictFilter = () => {
    let newFilterValues = filterValues;
    newFilterValues["conflict"] = 1;
    setFilterValues(newFilterValues);
    const queryParams = generateQueryString(newFilterValues);
    getArticlesByPageAndSize({page: page, size: rowsPerPage, search: searchValue, sort: sortParam, ...JSON.parse(queryParams)});
  }

  const handleFilterChange = (changedColumn, filterList, type, changedColumnIndex, displayData) => {
    //console.log(typeof changedColumn, changedColumn);
    //console.log(typeof filterList, filterList);
    //console.log(typeof type, type);
    //console.log(typeof changedColumnIndex, changedColumnIndex);
    let newFilterValues = {};
    if(changedColumn !== null){
      newFilterValues = filterValues;
      let value = filterList[changedColumnIndex];
      if(type == "custom"){
        newFilterValues.from = moment(filterList[changedColumnIndex][0]).format("YYYY-MM-DD HH:mm:ss");
        newFilterValues.to = moment(filterList[changedColumnIndex][1]).format("YYYY-MM-DD HH:mm:ss");
      }
      else if(value[0] !== undefined){
        newFilterValues[changedColumn] = value[0];
      }
      else{
        delete newFilterValues[changedColumn]
      }
    }
    setFilterValues(newFilterValues);
    const queryParams = generateQueryString(newFilterValues);
    getArticlesByPageAndSize({page: page, size: rowsPerPage, search: searchValue, sort: sortParam, ...JSON.parse(queryParams)});

  };

  //Generate query String for filterparames
  const generateQueryString = (filterValues) => {
    const queryParams = {};
    for (const column in filterValues) {
      const value = filterValues[column];
      // Add the column and value to the query string only if the value is not empty
      if (value !== undefined && value !== null && value !== "") {
        queryParams[column] = value;
      }
    }
    return JSON.stringify(queryParams);
  };

  const httpConfigImages = {
    headers: { Authorization: `Bearer ${props.token}` }
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsNumber, setSelectedRowsNumber] = useState(0);

  //api calls
  const deleteArticlesById = (data,idx) => {
    axios.delete(endpoints.BUNDLES + '/' + data[idx]._id,httpConfig)
          .then(response => {
            if(idx < (data.length -1)) {
              deleteArticlesById(data, idx + 1);
            }
            else {
              axios.get(endpoints.BUNDLES + "?size=" + rowsPerPage,httpConfig)
                .then(response => {
                  setData(response.data.data);
                })
                .catch(error => {
                  handleError();
                  checkError401(error);
                })
                enqueueSnackbar(t('notification_success_article_delete'), {
                  variant: 'info',
                  autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
                });
              }
          })
          .catch(error => {
            handleError();
          })
  }

  const deleteBundles = (query) => {
    const getConfig = {headers: httpConfig.headers, params: query}
    axios.delete(endpoints.BUNDLES, getConfig)
          .then(response => {
              axios.get(endpoints.BUNDLES + "?size=" + rowsPerPage,httpConfig)
                .then(response => {
                  setData(response.data.data);
                })
                .catch(error => {
                  handleError();
                  checkError401(error);
                })
                enqueueSnackbar(t('notification_success_article_delete'), {
                  variant: 'info',
                  autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
                });
              })
          .catch(error => {
            handleError();
          })
  }

  // CSV methods
  const saveCsvStringAsFile = (csvString, fileName) => {
    const url = window.URL.createObjectURL(new Blob([csvString]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    refreshTable();
  };

  //column helper function
  const getNumberOfImages = (measures) => {
    var images = 0
    for (const measure of measures){
      images += measure.images? measure.images.length : 0;
    }
    return images
  }

  const handleSelectAllClick = (event) => {

    if (event.target.checked) {
      // Checkbox is checked, select all rows across all pages
      setSelectedRows([...Array(totalRows).keys()]);
      setSelectedRowsNumber(totalRows);
      setSelectAllChecked(true);
    } else {
      // Checkbox is unchecked, clear the selection
      setSelectedRowsNumber(0);
      setSelectedRows([]);
      setSelectAllChecked(false);
    }
  };

  const handleRowClick = (event, dataIndex) => {
    if (event.target.checked) {
      // Checkbox is checked, add row to the selectedRows array
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, dataIndex]);
    } else {
      // Checkbox is unchecked, remove row from the selectedRows array
      setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((rowIndex) => rowIndex !== dataIndex));
    }
  };

  const columns = [
    { 
      name: "SelectAll", 
      options: {
        filter: false,
        customHeadRender: ({index, ...column}) => {
          return (
            <th key={index}>
            <Checkbox
              onChange={handleSelectAllClick}
              checked={selectAllChecked}
            />
          </th>
            
          )
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            //<TableCell key={dataIndex}>
              <Checkbox
                checked={selectedRows.includes(dataIndex)|| selectedRowsNumber === totalRows} 
                onChange={(event) => handleRowClick(event, dataIndex)}
                />
           // </TableCell>
          );
      }
    }
    },
    {
      name: "articleID",
      label: t('table_header_articleID'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Article ID") ? true : 'excluded',
      }
    },
    {
      name: "barcodes",
      label: t('table_header_barcodes'),
      options: {
        filter: false,
        sort: false,
        filterType: 'multiselect',
        display: props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Barcodes") ? true : 'excluded',
        customBodyRenderLite: (dataIndex) => {
          let value = data[dataIndex]["barcodes"];
          if (value) {
            return value.map((item) => {
              return <text>{item["barcode"]}<br></br></text>;

            });
          }
          return "";
        },
      }
    },
    {
      name: "description",
      label: t('table_header_description'),
      options: {
        customBodyRenderLite: (dataIndex) => {
          let value = data[dataIndex]["article"][0];
          if (value) {
            return value.description;
          }
          return "";
        },
        filter: false,
        sort: false,
        display: props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Description") ? true : 'excluded',

      }
    },
    {
      name: "handlingUnit",
      label: t('table_header_hu'),
      options: {
         filter: false, //props.portalSettings && props.portalSettings.article_filters && props.portalSettings.article_filters.includes("Handling unit"),
        sort: true,
        display: props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Handling unit") ? true : 'excluded',
        // filterOptions: {
        //   renderValue: value => { return (!value || value === "") ? t('filter_unknown') : value }
        // },
        // customFilterListOptions: {
        //   render: value => { return (!value || value === "") ? t('filter_unknown') : value }
        // }
      }
    },
    {
      name: "itemsPerHU",
      label: t('table_header_pieces'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Items per HU") ? true : 'excluded',
      }
    },
    {
      name: "customString1",
      label: props.customFields ? props.customFields.custom_string_1.name : "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let value = data[dataIndex]["article"][0];
          if (value) {
            return value.customString1;
          }
          return "";
        },
        filter: false,
        sort: false,
        display: props.customFields && props.customFields.custom_string_1.enabled && props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Custom String 1") ? true : 'excluded'
      }
    },
    {
      name: "customString2",
      label: props.customFields ? props.customFields.custom_string_2.name : "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let value = data[dataIndex]["article"][0];
          if (value) {
            return value.customString2;
          }
          return "";
        },
        filter: false,
        sort: false,
        display: props.customFields &&  props.customFields.custom_string_2.enabled && props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Custom String 2") ? true : 'excluded'
      }
    },
    {
      name: "customInt1",
      label: props.customFields ? props.customFields.custom_int_1.name : "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let value = data[dataIndex]["article"][0];
          if (value) {
            return value.customInt1;
          }
          return "";
        },
        filter: false,
        sort: false,
        display: props.customFields &&  props.customFields.custom_int_1.enabled && props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Custom Int 1") ? true : 'excluded'
      }
    },
    {
      name: "customInt2",
      label: props.customFields ? props.customFields.custom_int_2.name : "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let value = data[dataIndex]["article"][0];
          if (value) {
            return value.customInt2;
          }
          return "";
        },
        filter: false,
        sort: true,
        display: props.customFields &&  props.customFields.custom_int_2.enabled && props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Custom Int 2") ? true : 'excluded'
      }
    },
    {
      name: "customFloat",
      label: props.customFields ? props.customFields.custom_float.name : "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let value = data[dataIndex]["article"][0];
          if (value) {
            return value.customFloat;
          }
          return "";
        },
        filter: false,
        sort: false,
        display: props.customFields &&  props.customFields.custom_float.enabled && props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Custom Float") ? true : 'excluded'
      }
    },
    {
      name: "source",
      label: t('table_header_source'),
      options: {
        filter: false, //props.portalSettings && props.portalSettings.article_filters && props.portalSettings.article_filters.includes("Source"),
        sort: true,
        display: props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Source") ? true : 'excluded',

      }
    },
    {
      name: "conflict",
      label: t('table_header_conflict'),
      options: {
        filter: true,
        //filterList: history.location.state !== undefined && history.location.state.detail === "fromDashboardConflict" ? [1] : [],
        sort: false,
        display: true,
        customBodyRenderLite: (dataIndex) => {
          return (data[dataIndex]["measures"].length > 1 && (data[dataIndex].pickedMeasureID == null || data[dataIndex].pickedMeasureID === undefined))
          ? <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><Button style={{backgroundColor: "#f44336", color: "white"}} aria-label="add an alarm" onClick={() => { openDetailsDialog({ ...data[dataIndex] }) }} endIcon={<ErrorOutlineIcon style={{fill: "white"}}/>}>{t('resolve')}</Button></div>
          : ((data[dataIndex]["measures"].length > 0) 
              ?  (
              ((data[dataIndex]["measures"].length > 1) 
                ? <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><Button style={{backgroundColor: "green", color: "white"}} aria-label="add an alarm" onClick={() => { openDetailsDialog({ ...data[dataIndex] }) }} endIcon={<SwapHorizIcon/>}>{t('resolved')}</Button></div> 
                : <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><Button style={{backgroundColor: "green", color: "white"}} aria-label="add an alarm" onClick={() => { openDetailsDialog({ ...data[dataIndex] }) }} endIcon={<DoneIcon/>}>{t('none')}</Button></div>))
              : <RemoveIcon />)
        },
        filterOptions: {
          names: [0, 1],
          renderValue: value => {return !value ? <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('conflict_no')}</span><CheckIcon className="export" style={{fill: "green"}}></CheckIcon></div> : <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('conflict_yes')}</span><ErrorOutlineIcon className="export" style={{fill: "red"}}></ErrorOutlineIcon></div> }
        },
        customFilterListOptions: {
          render: value => { return !value ? <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('conflict_no')}</span><CheckIcon className="export" style={{fill: "green"}}></CheckIcon></div> : <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('conflict_yes')}</span><ErrorOutlineIcon className="export" style={{fill: "red"}}></ErrorOutlineIcon></div> }
        }
      },
    },
    {
      name: "measures",
      label: t('table_header_measured'),
      options: {
        filter: true,
        sort: false,
        display: false,
        empty: true,
        customBodyRender: (value) => { return value.length > 0 ? "Yes" : "No" },
        filterOptions: {
          names: [0, 1],
          renderValue: value => { return parseInt(value) ? <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_yes')}</span><CheckIcon className="export" style={{fill: "green"}}/></div> : <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_no')}</span><ClearIcon className="export" style={{fill: "red"}}/></div> }
        },
        customFilterListOptions: {
          render: value => { return parseInt(value) ? <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_yes')}</span><CheckIcon className="export" style={{fill: "green"}}/></div> : <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_no')}</span><ClearIcon className="export" style={{fill: "red"}}/></div> }
        }
      }
    },
    {
      name: "measures.images",
      label: t('table_header_images'),
      options: {
        filter: false,
        sort: false,
        download: false,
        empty: true,  
        display: props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Images") ? true : 'excluded',
      //   sortCompare: (order) =>
      // ({ data: item1 }, { data: item2 }) => {
      //     return (order === 'asc' ? (getNumberOfImages(item1) >= getNumberOfImages(item2) ? 1 : -1) : (getNumberOfImages(item1)  < getNumberOfImages(item2) ? 1 :-1));
      // },           
       customBodyRenderLite: (dataIndex) => {
          return getNumberOfImages(data[dataIndex]["measures"]) > 0 ? (
            <IconButton color="primary" aria-label="add an alarm" onClick={() => { setCurrentArticle({ ...data[dataIndex] }); getmeasures({ ...data[dataIndex] }); }}>
              <Badge badgeContent={getNumberOfImages(data[dataIndex]["measures"])} color="error">
                <PhotoLibraryIcon />
              </Badge>
            </IconButton>
          ) : <RemoveIcon />;
        }
      }
    },
    {
      name: "measures",
      label: t('table_header_last_measure'),
      options: {
        filter: props.portalSettings && props.portalSettings.article_filters && props.portalSettings.article_filters.includes("Last measure"),
        filterType: "custom",
        sort: false,
        empty: false,
        display: props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Last measure") ? true : 'excluded',
        // sortCompare: (order) =>
        //   ({ data: item1 }, { data: item2 }) => {
        //     if (item1.length === 0)
        //       return 1
        //     else if (item2.length === 0)
        //       return -1
        //     else
        //       return (order === 'asc' ? (item1[item1.length - 1].timeStamp >= item2[item2.length - 1].timeStamp ? 1 : -1) : (item1[item1.length - 1].timeStamp < item2[item2.length - 1].timeStamp ? 1 :-1));
        //   },
        customBodyRender: (value) => {
          return value.length > 0 ? formatTimestamp(value[value.length - 1].timeStamp, props.userSettings ? props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT, t('locale')) : "";
        },
        customFilterListOptions: {
          render: dates => {
            if(dates[0] && !dates[1])  //only start date entered by user
            {
              if(isValid(dates[0]))
                return t("filter_start_date") + ": " + format(dates[0], "dd-MM-yyyy");
            }
            if(!dates[0] && dates[1])  //only start date entered by user
            {
              if(isValid(dates[1]))
                return t("filter_end_date") + ": " + format(dates[1], "dd-MM-yyyy");
            }
            if(dates[0] && dates[1])  //only start date entered by user
            {
              if(isValid(dates[0]) && isValid(dates[1]))
                return [t("filter_start_date") + ": " + format(dates[0], "dd-MM-yyyy"), t("filter_end_date") + ": " + format(dates[1], "dd-MM-yyyy")];
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(value, filters) {
            let currentDate = new Date(reformatDate(value, props.userSettings ? props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT, t('locale')))
            if(filters[0] && !filters[1])  //only start date entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[0]))
                return !isBefore(filters[0], currentDate);
            }
            if(!filters[0] && filters[1])  //only end date entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[1]))
                return !isBefore(currentDate, filters[1]);
            }
            if(filters[0] && filters[1])  //both dates entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[0]) && isValid(filters[1]))
                return !(isBefore(filters[0], currentDate) && isBefore(currentDate, filters[1]));
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>{t("table_header_last_measure")}</FormLabel>
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
        
                     <DatePicker
                        label={t("filter_from")}
                        value={filterList[index][0] === undefined ? null: filterList[index][0]}
                        onChange={(value) => {
                          filterList[index][0] = value;
                          if(isValid(filterList[index][0])){
                            filterList[index][0].setHours(0,0,0,0);
                          }
                          onChange(filterList[index], index, column)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} style = {{maxWidth: 200}}/>
                        )}
                        inputFormat="dd-MM-yyyy"
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                    <DatePicker
                        label={t("filter_to")}
                        value={filterList[index][1] === undefined ? null: filterList[index][1]}
                        onChange={(value) => {
                          filterList[index][1] = value;
                          if(isValid(filterList[index][1])) {
                            filterList[index][1].setHours(23,59,59,59);
                          }

                          onChange(filterList[index], index, column)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} style = {{maxWidth: 250}}/>
                        )}
                        inputFormat="dd-MM-yyyy"
                    /> 
                  </LocalizationProvider>
                </FormGroup>
              </div>
          )
        }
      }
    },
    {
      name: "updatedAt",
      label: t('table_header_modified'),
      options: {
        filter: props.portalSettings && props.portalSettings.article_filters && props.portalSettings.article_filters.includes("Last modified"),
        filterType: "custom",
        sort: true,
        sortDirection: 'asc',
        empty: false,
        display: props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("Last modified") ? true : 'excluded',
        customBodyRender: (value) => {
          return value ? formatTimestamp(value,props.userSettings ? props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT,t('locale')) : "";
        },
        customFilterListOptions: {
          render: dates => {
            if(dates[0] && !dates[1])  //only start date entered by user
            {
              if(isValid(dates[0]))
                return t("filter_start_date_modified") + ": " + format(dates[0], "dd-MM-yyyy");
            }
            if(!dates[0] && dates[1])  //only start date entered by user
            {
              if(isValid(dates[1]))
                return t("filter_end_date_modified") + ": " + format(dates[1], "dd-MM-yyyy");
            }
            if(dates[0] && dates[1])  //only start date entered by user
            {
              if(isValid(dates[0]) && isValid(dates[1]))
                return [t("filter_start_date_modified") + ": " + format(dates[0], "dd-MM-yyyy"), t("filter_end_date_modified") + ": " + format(dates[1], "dd-MM-yyyy")];
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(value, filters) {
            let currentDate = new Date(reformatDate(value, props.userSettings ? props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT, t('locale')))
            if(filters[0] && !filters[1])  //only start date entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[0]))
                return !isBefore(filters[0], currentDate);
            }
            if(!filters[0] && filters[1])  //only end date entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[1]))
                return !isBefore(currentDate, filters[1]);
            }
            if(filters[0] && filters[1])  //both dates entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[0]) && isValid(filters[1]))
                return !(isBefore(filters[0], currentDate) && isBefore(currentDate, filters[1]));
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>{t("table_header_modified")}</FormLabel>
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                    <DatePicker
                        label={t("filter_from")}
                        value={filterList[index][0] === undefined ? null: filterList[index][0]}
                        onChange={(value) => {
                          filterList[index][0] = value;
                          if(isValid(filterList[index][0])){
                            filterList[index][0].setHours(0,0,0,0);
                          }

                          onChange(filterList[index], index, column)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} style = {{maxWidth: 250}}/>
                        )}
                        inputFormat="dd-MM-yyyy"
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                    <DatePicker
                        label={t("filter_to")}
                        value={filterList[index][1] === undefined ? null: filterList[index][1]}
                        onChange={(value) => {
                          filterList[index][1] = value;
                          if(isValid(filterList[index][1])){
                            filterList[index][1].setHours(23,59,59,59);
                          }
                          onChange(filterList[index], index, column)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} style = {{maxWidth: 250}}/>
                        )}
                        inputFormat="dd-MM-yyyy"
                    />
                  </LocalizationProvider>
                </FormGroup>
              </div>
          )
        }
      }
    },
    {
      name: "",
      label: t('table_header_history'),
      options: {
        filter: false,
        sort: false,
        empty: false,
        display: props.portalSettings && props.portalSettings.article_columns && props.portalSettings.article_columns.includes("History") ? true : 'excluded',
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex]["numMeasures"] > 0 ? (
            <IconButton color="primary" aria-label="add an alarm" onClick={() => { openDetailsDialog({ ...data[dataIndex] }) }}>
              <Badge badgeContent={data[dataIndex]["measures"].length} color="error">
                <HistoryIcon />
              </Badge>
            </IconButton>
          ) : <RemoveIcon />;
        }
      }
    },
    {
      name: "exported",
      label: t('table_header_exported'),
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let entryData = data[dataIndex]
          let measureArray = entryData["measures"]
          let lastMeasure = measureArray[measureArray.length - 1]
          // let lastMeasure = measureArray[0]
          //
          // if (measureArray.length > 1) {
          //   for (let index in measureArray) {
          //     let measure = measureArray[index]
          //     if (measure["timeStamp"] > lastMeasure["timeStamp"]) {
          //       lastMeasure = measure
          //     }
          //   }
          // }
          if (lastMeasure !== undefined && lastMeasure !== null)
            return lastMeasure["exported"] ? <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap',}}>
                  <span>{t('table_text_export_yes')}</span><CheckIcon className="export" style={{fill: "green"}}/></div> :
                <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap',}}>
                  <span>{t('table_text_export_no')}</span><ClearIcon className="export" style={{fill: "red"}}/></div>
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let item1 = obj1.data.length === 0 ? 0 : obj1.data[obj1.data.length - 1]["exported"]
            let item2 = obj2.data.length === 0 ? 0 : obj2.data[obj2.data.length - 1]["exported"]

            return (order === 'asc' ? (item1 >= item2 ? 1 : -1) : (item1 < item2 ? 1 : -1));
          }
        },
        filterOptions: {
          names: ['0', '1'],
          // logic(measure, filterValue) {
          //   if(measure.length !== 0)
          //   {
          //     let lastMeasure = measure[measure.length - 1]
          //     let exported = parseInt(lastMeasure["exported"])
          //     return !(exported === parseInt(filterValue))
          //   }
          //   return false;
          // },
          renderValue: value => { return parseInt(value) ? <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_yes')}</span><CheckIcon className="export" style={{fill: "green"}}/></div> : <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_no')}</span><ClearIcon className="export" style={{fill: "red"}}/></div> }
        },
        customFilterListOptions: {
          render: value => { return parseInt(value) ? <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_yes')}</span><CheckIcon className="export" style={{fill: "green"}}/></div> : <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_no')}</span><ClearIcon className="export" style={{fill: "red"}}/></div> }
        }
      }
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        download: false,
        empty: true,
        display: props.permissionSettings &&  props.permissionLevel >= props.permissionSettings.articles.edit ? true : 'excluded',
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton color="primary" aria-label="add an alarm" onClick={() => { setCurrentArticle({ ...data[dataIndex] }); setOpenEdit(true) }}>
              <EditIcon />
            </IconButton>
          );
        }
      }
    }
  ];

const options = {
  filterType: 'dropdown',
  print: false,
  enableNestedDataAccess: '.',
  selectableRows: 'none',
  download: false,
  jumpToPage: true,
  setFilterChipProps: (colIndex, colName, data) => {
    if(colName === "measures")
    {
      return {
       // variant: "outlined"
      };
    }
    else if(colName === "updatedAt")
    {
      return {

      };
    }
  },
  serverSide: true,
  rowsPerPageOptions: [10,20,50],
  count: totalRows,
  onColumnSortChange: handleColumnSortChange,
  search: true,
  onSearchChange: (value) => setSearchValue(value),
  onChangeRowsPerPage: (newRowsPerPage) => setRowsPerPage(newRowsPerPage),
  onChangePage: (currentPage) => {
    setPage(currentPage+1)   
     setInitialLoading(true)
    setArticlesLoading(true)
  },
  onTableInit: (action, tableState) => {
    setInitialLoading(true)
    setArticlesLoading(true)
    if(history.location.state !== undefined && history.location.state.detail === "fromDashboardConflict")
      setInitialConflictFilter();
    else
      refreshTable();
  },
  onFilterChange: handleFilterChange,

  downloadOptions: {
    separator: (props.csvConfig && props.csvConfig.active !== undefined && props.csvConfig.templates[props.csvConfig.active]) ? props.csvConfig.templates[props.csvConfig.active].delimiter : ';',
    filename: (props.csvConfig && props.csvConfig.active !== undefined && props.csvConfig.templates[props.csvConfig.active]) ? props.csvConfig.templates[props.csvConfig.active].filename : 'export.csv'
  },
  onDownload: (buildHead, buildBody, columns, data) => {
    return buildCSVContent(columns,data);
  },
  textLabels: {
    body: {
      noMatch: articlesLoading ?
        (initialLoading ? <CircularProgress /> : <LinearProgress variant="determinate" value={loadProgress} /> ) :
        t('table_no_data'),
        toolTip: t('table_body_toolTip'),    
      },
      selectedRows: {
        text: t('table_row_selected'),
        delete: t('table_row_delete'),
        deleteAria: t('table_row_deleteAria'),
      }, 
      pagination: {
        next: t('table_pagination_next'),
        previous: t('table_pagination_previous'),
        rowsPerPage: t('table_pagination_rowsPerPage'),
        displayRows: t('table_pagination_displayRows'),
        jumpToPage: t('table_pagination_jumpToPage'),
      },
      toolbar: {
        search: t('table_toolbar_search'),
        downloadCsv: t('table_toolbar_downloadCsv'),
        print: t('table_toolbar_print'),
        viewColumns: t('table_toolbar_viewColumns'),
        filterTable: t('table_toolbar_filterTable'),
      },
      filter: {
        all: t('table_filter_all'),
        title: t('table_filter_title'),
        reset:<Button color="secondary" variant="contained">{t('table_filter_reset')}</Button>,
      },
      viewColumns: {
        title: t('table_viewColumns_title'),
        titleAria: t('table_viewColumns_titleAria'),
      },
    
  },
  customToolbarSelect: (selectedRowsData, displayData, setSelectedRowsFunc) => (
    <CustomToolbarSelect
      selectedRows={selectedRows}
      displayData={displayData}
      setSelectedRows={setSelectedRowsFunc}
      handleDownload={handleDownload}
      onRowsDelete={handleConfirmDelete}
      handleAddToPlan={handleAddToPlan}
      open={confirmDialogOpen}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
    />
  ),
  customToolbar: ({displayData}) => {
    return (
      <CustomToolbar action={handleClick} displayData={displayData} selectedRows={selectedRows} onRowsDelete={handleConfirmDelete} open={confirmDialogOpen}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm} actionRefresh={handleClickRefresh} permissionSettings={props.permissionSettings} permissionLevel={props.permissionLevel} handleDownload={handleDownloadAll}/>
    );
  },

};

  //load table data
  const refreshTable = () => {
    setArticlesLoading(true);
    const queryParams = generateQueryString(filterValues);
    getArticlesByPageAndSize({page: 1, size: rowsPerPage, search: searchValue, sort: sortParam, ...JSON.parse(queryParams)});
  }

  const getArticlesByPageAndSize = useCallback((params) => {
    const getConfig = {headers: httpConfig.headers, params: params}
    axios.get(endpoints.BUNDLES, getConfig)
      .then(response => {
          setInitialLoading(false);
          setData(setConflicts(response.data.data))
          setNumberOfConflicts(getNumberOfConflicts(response.data.data))
          setTotalRows(response.data.totalPages*response.data.pageSize)
          setArticlesLoading(false);
        }
      )
      .catch(error => {
        console.log(error);
        setInitialLoading(false);
        setArticlesLoading(false);
        checkError401(error);
      })
  })

  useEffect(() => {
    getArticlesByPageAndSize({page: page, size: rowsPerPage, search: searchValue, sort: sortParam});
  }, [page, rowsPerPage]);

  useEffect(() => {
    setData([]);
  }, [page, rowsPerPage]);

//Dialogs state handler
const handleSuccessEdit = (flag) => {
  axios.get(endpoints.BUNDLES + "?size=" + rowsPerPage, httpConfig)
    .then(response => {
      setData(response.data.data);
    })
    .catch(error => {
      checkError401(error);
    })
  let notification = 'notification_success_article_update';
  if(flag === 'upload_image')
    notification = 'notification_success_image_add'
  else if(flag === 'delete_image')
    notification = 'notification_success_image_delete'
  enqueueSnackbar(t(notification), {
    variant: 'success',
    autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
  });
}

  const handleSubmitExport = (exportmeasuresOptions, exportAllArticles) => {
    setOpenExport(false);
    setLoading(true);
    const zipConfig = {
      headers: { Authorization: `Bearer ${props.token}`, 'Content-Type': 'multipart/form-data'},
      responseType: 'arraybuffer',
    };

    if(props.csvConfig && props.csvConfig.active !== undefined && props.csvConfig.templates[props.csvConfig.active] && props.csvConfig.templates[props.csvConfig.active].exportImages) {
      //Download images
      let endpoint = endpoints.IMAGE_EXPORT
      if (selectedData.length < data.length) {
        endpoint += '/'
        for (let article of selectedData) {
          if (exportmeasuresOptions === constants.MEASURE_EXPORT_VALUES.NEW) {
            endpoint += article.measures[article.measures.length - 1].id
            endpoint += ','
          } else {
            for (let measure of article.measures) {
              if ((exportmeasuresOptions === constants.MEASURE_EXPORT_VALUES.SELECTED && measure.id === article.pickedMeasureID) || exportmeasuresOptions === constants.MEASURE_EXPORT_VALUES.ALL) {
                endpoint += measure.id
                endpoint += ','
              }
            }
          }
        }
        endpoint = endpoint.slice(0, -1)
      }
      axios.get(endpoint, zipConfig)
        .then(resp => {
          fileDownload(resp.data, 'images.zip');
          saveCsvStringAsFile(buildCSVContent(columns, selectedData, exportmeasuresOptions, exportAllArticles, props, t, httpConfig, false), props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].filename : 'export.csv')
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        })
    }
    else {
      saveCsvStringAsFile(buildCSVContent(columns, selectedData, exportmeasuresOptions, exportAllArticles, props, t, httpConfig, false), props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].filename : 'export.csv')
      setLoading(false);
    }
  }

  const handleError = () => {
    enqueueSnackbar(t('notification_error_general'), {
      variant: 'error',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseAddToPlan = () => {
    setOpenAddToPlan(false);
    if(history?.location?.state?.detail === "fromPlanning")
    {
      history.push({
        pathname: routes.PLANNING,
        state: { detail: "backToPlans"}
      })
    }
  };

  const handleCloseExport = () => {
    setOpenExport(false);
    history.push('/menu/articles');
  };

  const openDetailsDialog = (rowData) => {
    setOpenHistory(true)
    setHistoryArticleData(rowData)
  }
  const showSnackbar = () => {
    if(ok > 0){
    enqueueSnackbar(t('notification_import_rows_ok') + ok, {
      variant: 'success',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });}
    if(updated > 0){
    enqueueSnackbar(t('notification_import_rows_updated') + updated, {
      variant: 'info',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });}
    if(error > 0){
    enqueueSnackbar(t('notification_import_rows_fail') + error, {
      variant: 'error',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });}
  }

  const openImportDialog = () => {
    setImportDialog(true)
  }

  const handleClose = () => {
      setImportDialog(false);
      setOpenHistory(false);
      setHistoryRequest(false);
      history.push('/menu/articles');
  };


  const handleCloseHistory = () => {
    const articleBody = {
      "pickedMeasureID": pickedMeasureID
    };

    axios.patch(endpoints.BUNDLES + '/' + currentArticleID,articleBody,httpConfig)
      .then(response => {
        setImportDialog(false);
        setOpenHistory(false);
        setHistoryRequest(false);
        history.push('/menu/articles');
        refreshTable();
      })
      .catch(error => {
          props.handleError();
      })
  };

  const handleClick = () => {
    openImportDialog();
  }

  const handleClickRefresh = () => {
    refreshTable();
  }

  const handleCloseImages = () => {
    setImagesDialogState({
      "openImages": false,
      "imageSteps": [],
      "loaded": false
    })
  };

  //api calls
  const getmeasures = (article) => {
    setLoading(true)
    let images = []
    axios.get(endpoints.MEASURES + '?articleID=' + article.articleID, httpConfig)
      .then(response => {
        if (response.data.data.length > 0)
          loadImages(response.data.data, 0, images)
        else {
          setLoading(false)
          enqueueSnackbar(t('notification_info_images_not_found_article'), {
            variant: 'info',
            autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
          });
        }
      })

      .catch(error => {
        setLoading(false)
        enqueueSnackbar(t('notification_error_general'), {
          variant: 'error',
          autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
        });
        checkError401(error);
      })
}

  const loadImages = (data, idx, images) => {
    if (data[idx].images.length > 0)
      getImage(data[idx], 0, images, data, idx)
    else {
      if (idx < data.length - 1) {
        loadImages(data, idx + 1, images)
      } else {

        if(images.length > 0)
        {

          setImagesDialogState({
            "openImages": true,
            "imageSteps": images,
            "loaded": true
          })
          setLoading(false)

        }
        else{
          setLoading(false)
          enqueueSnackbar(t('notification_info_images_not_found_article'), {
            variant: 'info',
            autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000

          });
        }
      }
    }
  }

  const getImage = (measure, index, images, data, idx) => {
    let imageNr = measure.images[index].imageNumber
    axios.get(endpoints.IMAGES + '/' + measure.id + '/' + imageNr, httpConfigImages)
      .then(response => {
        let imageData = {
          path: measure.images[index].path,
          label: t('show_images_measure') + measure.id + " (" + measure.timeStamp + ")",
          img: response.data.imagePath,
          imageNumber: imageNr,
          measureID: measure.id
        }
        images.push(imageData);
        if (index < measure.images.length - 1)
          getImage(measure, index + 1, images, data, idx)
        else {
          if (idx < data.length - 1) {
            loadImages(data, idx + 1, images)
          } else {
            setImagesDialogState({
              "openImages": true,
              "imageSteps": images,
              "loaded": true
            })
            setLoading(false)
          }
        }
      })
      .catch(error => {
        console.log(error);
        setLoading(false)
        enqueueSnackbar(t('notification_error_load_images'), {
          variant: 'error',
          autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
        });
        checkError401(error);
      })
  }
  
  //render 
  return (
    <div className="Articles">
      <div className={classes.bread}>
      <Breadcrumbs aria-label="breadcrumb">
      <Link to={routes.DASHBOARD} className={classes.link} onClick={() => {
        props.setSelectedItem(1)
        props.setBold(1)
      }}>
        <HomeIcon className={classes.icon} />
      </Link>
      <Link to={routes.ARTICLES} className={classes.link}>
        {t('component_header_article')}
        </Link>

    </Breadcrumbs>
    { numberOfConflicts > 0 && (<MuiAlert className={classes.button} severity="error">{t('alert_conflicts')}</MuiAlert>)}
    </div>
      <MUIDataTable
        title={""}
        data={data}
        columns={columns}
        options={options}
      />
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        fullWidth={true}
        maxWidth={'xl'}
        open={openHistory}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{t("dialog_article")} {historyArticleData.articleID}</DialogTitle>
        <DialogContent>
          <MeasureHistory data={historyArticleData} token={props.token} request={historyRequest} userSettings={props.userSettings} setPickedMeasureID={setPickedMeasureID} setCurrentArticleID={setCurrentArticleID} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHistory} color="primary" variant="contained">
            {t("button_close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        classes={{ paper: classes.dialogPaper }}
        fullWidth={true}
        maxWidth={'xl'}
        open={(importDialog && !articlesLoading)}
        onClose={handleClose}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="importDialog-title">{t("dialog_import")}</DialogTitle>
        <DialogContent>
          <div className="CSVImporter_FormatPreview__header">
            {( // hide setting if only one line anyway
                <div className={classes.row}>
                <Checkbox
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 38 } }}
                  checked={update}
                  onChange={() => {
                    setUpdate(!update);
                  }}
                />
                <Typography variant="h6" color="secondary">
                  {t("dialog_import_update")}
                </Typography>
                </div>
            )}
          </div>
          <Importer
            chunkSize={50000} // optional, internal parsing chunk size in bytes
            assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
            restartable={true} // optional, lets user choose to upload another file when import is complete
            onStart={({ file, fields, columns, skipHeaders }) => {
              setOK(0);
              setUpdated(0);
              setError(0);
            }}

            processChunk={async (rows, { startIndex }) => {
              // required, receives a list of parsed objects based on defined fields and user column mapping;
              // may be called several times if file is large
              // (if this callback returns a promise, the widget will wait for it before parsing more data)
              // for (row of rows) {
              //   //await myAppMethod(row);
              // }
              promises.push(new Promise((resolve, reject) => {
                const body = buildImportArticlesBody(rows);
                axios.post(endpoints.ARTICLES + "?update=" + update, body, httpConfig)
                  .then(response => {
                    setOK(prevOk => prevOk + response.data.imported)
                    setUpdated(prevUpdated => prevUpdated + response.data.updated)
                    setError(prevError => prevError + response.data.failed)
                    resolve();
                  })
                  .catch(err => {
                    resolve();
                  })
              }))


            }}
            onComplete={({ file, preview, fields, columnFields }) => {

            }}
            onClose={async ({ file, preview, fields, columnFields }) => {
              setLoading(true);
              await Promise.all(promises).then(result => {
                handleOnComplete();
                handleCloseImport();
                setLoading(false);
              });

            }}

          // CSV options passed directly to PapaParse if specified:
          // delimiter={...}
          // newline={...}
          // quoteChar={...}
          // escapeChar={...}
          // comments={...}
          // skipEmptyLines={...}
          // delimitersToGuess={...}
          >
            <ImporterField name="articleID" label={t("table_header_articleID")} />
            <ImporterField name="barcodes" label={t("table_header_barcode")} optional />
            <ImporterField name="description" label={t("table_header_description")} optional/>
            <ImporterField name="handlingUnit" label={t("table_header_hu")} optional/>
            <ImporterField name="itemsPerHU" label={t("table_header_pieces")} optional/>
            {props.customFields && props.customFields.custom_string_1.enabled && <ImporterField name="customString1" label={props.customFields.custom_string_1.name} optional/>}
            {props.customFields && props.customFields.custom_string_2.enabled && <ImporterField name="customString2" label={props.customFields.custom_string_2.name} optional/>}
            {props.customFields && props.customFields.custom_int_1.enabled && <ImporterField name="customInt1" label={props.customFields.custom_int_1.name} optional/>}
            {props.customFields && props.customFields.custom_int_2.enabled && <ImporterField name="customInt2" label={props.customFields.custom_int_2.name} optional/>}
            {props.customFields && props.customFields.custom_float.enabled && <ImporterField name="customFloat" label={props.customFields.custom_float.name} optional/>}
          </Importer>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AddToPlanDialog open={openAddToPlan} handleClose={handleCloseAddToPlan} handleError={handleError} token={props.token} plans={dataPlanned} selectedRows={selectedData} userSettings={props.userSettings} planID={history?.location?.state?.planID}/>
      <EditArticleDialog open={openEdit} handleClose={handleCloseEdit} handleSuccess={handleSuccessEdit} handleError={handleError} token={props.token} article={currentArticle} customFields={props.customFields}/>
      <ExportSettingsDialog selectedDataConflicts={selectedDataConflicts} numberOfConflicts={numberOfConflicts} open={(openExport && !articlesLoading)} handleClose={handleCloseExport} token={props.token} handleSubmit={handleSubmitExport} setCSVConfig={props.setCSVConfig} csvConfig={props.csvConfig}/>
      <Dialog classes={{ paper: classes.dialog }} fullWidth={false} maxWidth={'md'} open={imagesDialogState.openImages} onClose={handleCloseImages}>
      <Typography variant="h5" color="black" gutterBottom>
        {t('show_images_header')}
      </Typography>
      <ShowImagesDialog handleSuccess={handleSuccessEdit} handleError={handleError} handleClose={handleCloseImages} token={props.token} measure={currentArticle} request={imagesRequest} images={imagesDialogState.imageSteps} loaded={imagesDialogState.loaded} location={"articleTable"}/>
    </Dialog>
    </div>
  );
}

export default withAppInsights(Articles);
