/**
 * REST API URLs
 * 
 * @author JHO 
 * Last changed: 17.10.2022
 * Changes: PBI-1119 Added device settings endpoint
 */

let HTTP
let IP
let PORT
let VERSION
const IP_QR = "192.168.0.157"

HTTP = process.env.REACT_APP_API_PROTOCOL
IP = process.env.REACT_APP_API_IP
PORT = process.env.REACT_APP_API_PORT
VERSION = process.env.REACT_APP_API_VERSION

// HTTP = "http"
// IP = "10.7.0.25"
// PORT = ":3100"
// VERSION = "/vosapi/api/v1"


//const URL = `${HTTP}://${IP}${PORT}${VERSION}`
const URL = "https://saas.lnconsult.at/vosapi/api/v1";

export const AUTH = URL + "/auth";
export const MEASURES = URL + "/measures";
export const USERS = URL + "/users";
export const DEVICES = URL + "/devices";
export const DEVICE_SETTINGS = URL + "/parameters/device";
export const RESET = URL + "/auth/reset";
export const ARTICLES = URL + "/articles";
export const BUNDLES = URL + "/bundles";
export const PARAMETERS = URL + "/parameters";
export const PARAMETERS_USER = URL + "/parameters/user";
export const IMAGES = URL + "/images";
export const IMAGE_EXPORT = URL + "/export/images";
export const IMAGES_INC = URL + "/imagesInc";
export const STATISTICS_ARTICLES = URL + "/statistics/articles";
export const STATISTICS_MEASURES = URL + "/statistics/measures";
export const STATISTICS_MEASURES_FROM_TO = URL + "/statistics/measuresFromTo";
export const STATISTICS_DEVICES = URL + "/statistics/devices";
export const STATISTICS_PLANS = URL + "/statistics/plans";
export const SITES = URL + "/sites";
export const SIGNUP = URL + "/signup";
export const PLANS = URL + "/plans";
export const VOSAPP = URL + "/vosapp"
export const VERIFY = HTTP + IP + PORT + VERSION + "/verifyaccount";

export const QR_DATA = {
    protocol: HTTP,
    ip: IP,
    port: PORT,
    version: VERSION
}