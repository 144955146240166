import axios from 'axios';
import * as endpoints from '../../service/endpoints';
import fileDownload from 'js-file-download';

const { BlobServiceClient, ContainerClient } = require("@azure/storage-blob");
const JSZip = require('jszip');

export const connectAndDownloadContainer = async (sasUrl, all, measures) => {
    const containerClient = new ContainerClient(sasUrl);
    const blobs = containerClient.listBlobsFlat();

    let blobNames = [];
    if(all) {
        for await (const blob of blobs) {
            blobNames.push(blob.name);
        }
    } else {
        for (let measure of measures) {
            for(let image of measure['images']) {
                blobNames.push(image.path)
            }
        }
    }

    let start = 0;
    let end = Math.min(1000, blobNames.length);
    let idx = 1;
    while(start < blobNames.length) {
        const blobReadPromises = blobNames.slice(start,end).map(blobName => {
            const blobClient = containerClient.getBlobClient(blobName);
            return blobClient.download(0);
        });

        await downloadBatch(blobReadPromises,blobNames.slice(start,end),idx);
        start = end;
        end = Math.min(end + 1000, blobNames.length);
        idx += 1;
    }
}

export const downloadBatch = async (batchPromises,blobNames,idx) => {
    const blobDownloads = await Promise.all(batchPromises);

    const dataPromises = blobDownloads.map(blob => {
        return blob.blobBody;
    });

    const files = await Promise.all(dataPromises);

    const zip = new JSZip();
    for (let i = 0; i < files.length; i++) {
        zip.file(blobNames[i], files[i]);
    }

    zip.generateAsync({type:"blob"})
        .then(function (content) {
            fileDownload(content, `images_${idx}.zip`);
    });
}
