/**
 * Shows article info in measure table
 *
 * @author MFA
 * Last changed: 22.12.2021
 * Changes: PBI-698 Change Article Info in Measures
 */
import Box from '@material-ui/core/Box';
import {formatTimestamp } from '../../../common/helpers'
import { useTranslation } from "react-i18next";
import ShowImagesDialog from "../images/ShowImagesDialog";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        }
    }
}));

function ArticleInfo (props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box margin={1}>
            <form className={classes.root}>
                <div>
                    <TextField
                        label={t('table_header_articleID')}
                        variant="outlined"
                        readonly
                        style={{ width: 200 }}
                        value={props.measure["article"] !== undefined && props.measure["article"] !== null ? props.measure["article"].articleID : ''}
                    />
                    <TextField
                        label={t('table_header_barcodes')}
                        variant="outlined"
                        readonly
                        style={{ width: 320 }}
                        value={(props.measure !== undefined && props.measure.barcodes !== undefined) ? props.measure.barcodes.map(a => " "+ a.barcode): ''}
                    />
                    <TextField
                        label={t('table_header_description')}
                        variant="outlined"
                        readonly
                        style={{ width: 360 }}
                        value={props.measure["article"] !== undefined && props.measure["article"] !== null ? props.measure["article"].description : ''}
                    />
                    { props.customFields.custom_string_1.enabled && <TextField
                        label={props.customFields.custom_string_1.name}
                        variant="outlined"
                        readonly
                        style={{ width: 320 }}
                        value={props.measure["article"] !== undefined && props.measure["article"] !== null ? props.measure["article"].customString1 : ''}
                    /> }
                    { props.customFields.custom_string_2.enabled && <TextField
                        label={props.customFields.custom_string_2.name}
                        variant="outlined"
                        readonly
                        style={{ width: 320 }}
                        value={props.measure["article"] !== undefined && props.measure["article"] !== null ? props.measure["article"].customString2 : ''}
                    /> }
                </div>
                <div>
                    <TextField
                        label={t('table_header_hu')}
                        variant="outlined"
                        readonly
                        style={{ width: 200 }}
                        value={props.measure["article"] !== undefined && props.measure["article"] !== null ? props.measure["bundle"].handlingUnit : ''}
                    />
                    <TextField
                        label={t('table_header_pieces')}
                        variant="outlined"
                        readonly
                        style={{ width: 320 }}
                        value={props.measure["article"] !== undefined && props.measure["article"] !== null ? props.measure["bundle"].itemsPerHU : ''}
                    />
                    <TextField
                        label={t('table_header_last_measure')}
                        variant="outlined"
                        readonly
                        style={{ width: 360 }}
                        value={props.measure["article"] !== undefined && props.measure["article"] !== null ? formatTimestamp(props.measure.timeStamp,props.userSettings ? props.userSettings.date_format : 'datetime_long',t('locale')) : ''}
                    />
                    { props.customFields.custom_int_1.enabled && <TextField
                        label={props.customFields.custom_int_1.name}
                        variant="outlined"
                        readonly
                        value={props.measure["article"] !== undefined && props.measure["article"] !== null ? props.measure["article"].customInt1 : ''}
                    /> }
                    { props.customFields.custom_int_2.enabled && <TextField
                        label={props.customFields.custom_int_2.name}
                        variant="outlined"
                        readonly
                        value={props.measure["article"] !== undefined && props.measure["article"] !== null ? props.measure["article"].customInt2 : ''}
                    /> }
                    { props.customFields.custom_float.enabled && <TextField
                        label={props.customFields.custom_float.name}
                        variant="outlined"
                        readonly
                        value={props.measure["article"] !== undefined && props.measure["article"] !== null ? props.measure["article"].customFloat : ''}
                    /> }
                </div>
            </form>
            <ShowImagesDialog handleSuccess={props.handleSuccess} handleError={props.handleError} handleClose={props.handleClose}
                              token={props.token} request={props.imageRequest} images={props.images} loaded={props.loaded}
                              location={"measureTableArticleInfo"}/>
        </Box>
    );

}

export default ArticleInfo;