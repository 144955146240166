/**
 * Form for edit article dialog
 *
 * @author MFA
 * Last changed: 23.03.2022
 * Changes: PBI-825 Planning
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import ImageIcon from '@material-ui/icons/Image';
import axios from 'axios';
import * as endpoints from '../../../service/endpoints';
import { useTranslation } from 'react-i18next';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {formatTimestamp} from "../../../common/helpers";
import * as constants from "../../../common/constants";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 400,
    minWidth: 200,
  },
  root: {
    padding: theme.spacing(2),
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '& .MuiButtonBase-root': {
      marginLeft: theme.spacing(2),
    },
  },
  input: {
    display: 'none',
  },
  buttons : {
    position: 'absolute',
    right: theme.spacing(3),
    bottom: theme.spacing(3),
  },
  button: {
    display: 'inline'
  }
}));

const AddToPlanForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedName, setSelectedName] = useState(props?.planID?.name);

  const httpConfig = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  const handleSubmit = e => {
    e.preventDefault();

    let currentPlan = null
    for(let plan of props.plans)
    {
      if(plan.name === selectedName)
      {
        currentPlan = plan
      }
    }

    let newArticles = currentPlan?.articles.slice()

    for(let article of props?.selectedRows)
    {
      let found = false
      for(let existingArticle of currentPlan?.articles)
      {
        if(article._id === existingArticle._id)
        {
          found = true
        }
      }
      if(!found)
      {
        newArticles?.push(article)
      }
    }

    const planBody = {
      "articles": newArticles,
    };

    axios.patch(endpoints.PLANS + "/" + currentPlan?._id, planBody, httpConfig)
      .then(response => {
        enqueueSnackbar(t('notification_success_edit_plan'), {
          variant: 'success',
          autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
        });
      })
      .catch(error => {
        enqueueSnackbar(t('notification_error_edit_plan'), {
          variant: 'error',
          autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time * 1000 : constants.NOTIFICATION_TIME_DEFAULT * 1000
        })
      })
    props.handleClose();
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <div>
        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel id="plans-label">Plans</InputLabel>
          <Select
            id="plans-select"
            value={selectedName}
            label="Plans"
            onChange={e => setSelectedName(e.target.value)}
          >
            {props.plans.map((option) => (
              <MenuItem value={option?.name}>
                {option?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.buttons}>
        <div className={classes.button} >
          <Button type="submit" variant="contained" color="secondary" textColor="white" startIcon={<SaveIcon />}>
            {t('button_update')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddToPlanForm;