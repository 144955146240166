/**
 * Total measures chart component
 * 
 * @author JHO 
 * Last changed: 17.10.2022
 * Changes: PBI-1119 Alignment with other cards
*/

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { green } from '@material-ui/core/colors';
import { mdiTapeMeasure } from '@mdi/js'; 
import Icon from '@mdi/react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as routes from '../../../routing/routes';
import Divider from "@material-ui/core/Divider";
  
const useStyles = makeStyles((theme) => ({
  avatar:
    {
      marginTop: "-18px",
      marginLeft: "18px",
      position:"absolute",
      background: "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));",
      boxShadow: "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
      height: 70,
      width: 70,
      cursor:"pointer",
      "&:hover": {
        background: 'linear-gradient(195deg, rgb(73, 180, 241), rgba(26, 163, 200))',
      }     
  },
  cards: {
    //height: '100%',
    boxShadow: "rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "0.75rem"
  },
  container: {
    position: "relative"
  },
  divider: {
    flexShrink: "0",
    borderTop: "0px solid rgba(0, 0, 0, 0.12)",
    borderRight: "0px solid rgba(0, 0, 0, 0.12)",
    borderLeft: "0px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "transparent",
    height: "0.0625rem",
    margin: "1rem 0px",
    borderBottom: "none",
    opacity: "0.35",
    backgroundImage: "linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0)) !important"
  }
}));
  
function TotalMeasures(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const handleMeasuresClick = () => {
      props.setSelectedItem(2)
      props.setBold(2)
      history.push(routes.MEASURES)
  }

  return (
    <div className={classes.container}>
      <Tooltip title={t('dashboard_show_measures')}>
        <Avatar
          className={classes.avatar}
          onClick={handleMeasuresClick}
        >
          <Icon path={mdiTapeMeasure} size={2}/>
        </Avatar>
      </Tooltip>
      <Card
        className={classes.cards}
      >
        <CardContent>
          <Grid
            container
            spacing={3}
            style={{ justifyContent: 'space-between' }}
          >
            <Grid item>
            </Grid>
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              >
                {t('dashboard_total_measures')}
              </Typography>
              <Typography
                color="textPrimary"
                align='right'
                variant="h3"
              >{props.data.total_measures}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider}>

          </Divider>
          <Box
            style={{
              marginLeft: "15px",
              pt: 2,
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            {/*<ArrowUpwardIcon style={{ color: green[900] }} />*/}
            <Typography
              style={{
                color: green[900],
                mr: 1,
                marginRight: "4px"
              }}
              variant="body1"
            >
              +{props.data.last_24h_measures}
            </Typography>
            <Typography
              color="textSecondary"
              variant="caption"
            >
              {t('dashboard_in_24h')}
            </Typography>
          </Box>
          <Box
             style={{
               pt: 2,
               display: 'flex',
               alignItems: 'center',
             }}
           >
             <Typography
               color="textSecondary"
               variant="body1"
            
             >
              <span>&#8203;</span>
             </Typography>
           </Box>
        </CardContent>
      </Card>
    </div>

  );
}
  
export default TotalMeasures;