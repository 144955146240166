/**
 * Login component
 * 
 * @author JHO 
 * Last changed: 23.12.2021
 * Changes: PBI-674 Added portal table column settings
 */
 import './Login.css';
 import React, { useState, useEffect } from 'react';
 import axios from 'axios';
 import PropTypes from 'prop-types';
 import Avatar from '@material-ui/core/Avatar';
 import Typography from '@material-ui/core/Typography';
 import Button from '@material-ui/core/Button';
 import CssBaseline from '@material-ui/core/CssBaseline';
 import TextField from '@material-ui/core/TextField';
 import Box from '@material-ui/core/Box';
 import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
 import { useTranslation } from "react-i18next";
 import { useSnackbar } from 'notistack';
 import { makeStyles, useTheme } from '@material-ui/core/styles';
 import Container from '@material-ui/core/Container';
 import Paper from '@material-ui/core/Paper';
 import * as endpoints from '../../service/endpoints';
 import { Link } from "react-router-dom";
 import * as routes from '../../routing/routes';
 import { checkError401 } from '../../common/helpers';
 import { faIgloo } from '@fortawesome/free-solid-svg-icons';
 import logo from '../../common/logo_volumio.png';
 import Grid from '@material-ui/core/Grid';
 import i18next from "i18next";
 import useMediaQuery from '@material-ui/core/useMediaQuery';
 import withAppInsights from './../../common/appInsights'
 
 const useStyles = makeStyles((theme) => ({
   paper: {
     marginTop: '16vh',
     marginBottom: '12vh',
     display: 'flex',
     flexDirection: 'column',
     alignItems: 'center',
     padding: theme.spacing(4)
   },
   avatar: {
     margin: theme.spacing(1),
     backgroundColor: theme.palette.secondary.main,
   },
   form: {
     width: '100%',
     marginTop: theme.spacing(1),
   },
   submit: {
     margin: theme.spacing(3, 0, 2),
     fontWeight: 'bold',
     fontSize: 14
   },
   lower: {
     height: '5vh',
     overflow: 'auto',
   },
   info: {
     marginTop: theme.spacing(1),
     fontFamily: 'Roboto'
   },
   background: {
     backgroundColor: '#ded5cc',
     minHeight: '80vh',
     overflow: 'auto',
     marginBottom: theme.spacing(3),
     backgroundImage: "-webkit-linear-gradient(117deg, #ded5cc 50%, #ffffff 50%)"
   },
   outer: {
     minHeight: '100%'
   },
   box: {
     display: 'inline',
     marginLeft: '50%'
   },
   logo: {
     marginBottom: theme.spacing(3),
     marginTop: theme.spacing(3),
     width: '40%',
     maxWidth: '430px',
   },
   text: {
     align: 'center',
     marginLeft: '50%',
     fontFamily: 'Roboto'
   },
   language: {
     fontFamily: 'Roboto Light',
     marginTop: theme.spacing(4),
     marginBottom: theme.spacing(4),
     margin: theme.spacing(2),
     marginLeft: '15%'
   },
   languageRight: {
     fontFamily: 'Roboto Light'
   },
   footer: {
     marginBottom: theme.spacing(1)
   },
   divider: {
     marginLeft: theme.spacing(12),
     marginRight: '25%'
   }
 }));
 
 function Login(props) {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [submitted, setSubmitted] = useState(false);
   const [error, setError] = useState(false);
 
   const theme = useTheme();
   const classes = useStyles();
   const matches = useMediaQuery(theme.breakpoints.down('sm'));
 
   const { t } = useTranslation();
   const { enqueueSnackbar } = useSnackbar();
 
   useEffect(() => {
     i18next.changeLanguage((window.localStorage.getItem("language") || "EN").toLowerCase());
   }, []);
 
   const setEnglish = () => {
     i18next.changeLanguage("en");
     window.localStorage.setItem("language", "EN");
   };
 
   const setGerman = () => {
     i18next.changeLanguage("de");
     window.localStorage.setItem("language", "DE");
   };
 
   const handleLogin = (event) => {
     event.preventDefault();
     setSubmitted(true)
     postAuthentication();
   };
 
   const postAuthentication = () => {
     if (email !== "" && password !== "") {
       const authBody = {
         "email": email,
         "password": password
       };
 
       axios.post(endpoints.AUTH, authBody)
         .then(response => {
           props.setUserId(response.data.userId);
 
           // save data to locale browser localStorage
           window.localStorage.setItem("token", response.data.accessToken);
           window.localStorage.setItem("userId", response.data.userId);
 
           let userId = response.data.userId;
           const config = {
             headers: { Authorization: `Bearer ${response.data.accessToken}` }
           };
 
           //Get users
           axios.get(endpoints.USERS + `/${response.data.userId}`, config)
             .then(user => {
               props.setPermissionLevel(user.data.permissionLevel);
               props.setUser(user.data);
 
               // save data to locale browser localStorage
               window.localStorage.setItem("user", JSON.stringify(user.data));
               window.localStorage.setItem("permissionLevel", user.data.permissionLevel);
               enqueueSnackbar(t('notification_success_authentication') + user.data.firstName + ' ' + user.data.lastName + "!", {
                 variant: 'info'
               })
               props.setToken(response.data.accessToken);
             })
             .catch(err => {
               setError(false)
               enqueueSnackbar(t('notification_error_authentication'), {
                 variant: 'error'
               });
               checkError401(err);
               props.setToken(response.data.accessToken);
             })
 
           //Get sites
           axios.get(endpoints.SITES, config)
             .then(response => {
               props.setSites(response.data.data);
               // save data to locale browser localStorage
               window.localStorage.setItem("sites", JSON.stringify(response.data.data));
             })
             .catch(err => {
 
             })
           axios.get(endpoints.PARAMETERS+ '/' + userId, config)
             .then(response => {
               try {
                 if (response.data !== undefined) {
                   props.setUserSettings(response.data)
                   window.localStorage.setItem("userSettings", JSON.stringify(response.data));
                 }
                 else {
                   axios.post(endpoints.PARAMETERS + '/' + userId, {}, config)
                     .then(response => {
 
                     })
                     .catch(err => {
                       console.log(err);
                     })
                 }
               }
               catch (err) {
                 console.log(err);
               }
             })
             .catch(error => {
               console.log(error);
               checkError401(error);
             })
 
           //Get CSV configs & user settins
           axios.get(endpoints.PARAMETERS, config)
             .then(response => {
               try {
                 if (response.data[`${userId}`] !== undefined) {
                   props.setUserSettings(response.data[`${userId}`])
                   window.localStorage.setItem("userSettings", JSON.stringify(response.data[`${userId}`]));
                 }
                 else {
                   axios.post(endpoints.PARAMETERS_USER + '/' + userId, {}, config)
                     .then(response => {
 
                     })
                     .catch(err => {
                       console.log(err);
                     })
                 }
 
                 if (response.data['csv_export'] === undefined || response.data['custom_fields'] === undefined || response.data['permissions'] === undefined) {
                   enqueueSnackbar(t('notification_error_parameters'), {
                     variant: 'error'
                   });
                   props.setWarning(true);
                   window.localStorage.setItem("warning", true);
                 }
                 else {
                   props.setWarning(false);
                   window.localStorage.setItem("warning", false);
                 }
 
                 props.setCSVConfig(response.data['csv_export'])
                 props.setCustomFields(response.data['custom_fields'])
                 props.setPermissionSettings(response.data['permissions'])
                 props.setPortalSettings(response.data['portal'])
                 props.setAppSettings(response.data['vosapp'])
                 // save data to locale browser localStorage
                 window.localStorage.setItem("csvConfig", JSON.stringify(response.data['csv_export']));
                 window.localStorage.setItem("customFields", JSON.stringify(response.data['custom_fields']));
                 window.localStorage.setItem("permissions", JSON.stringify(response.data['permissions']));
                 window.localStorage.setItem("vosapp", JSON.stringify(response.data['vosapp']));
                 window.localStorage.setItem("portalSettings", JSON.stringify(response.data['portal']));
 
               }
               catch (err) {
                 console.log(err);
               }
             })
             .catch(error => {
               console.log(error);
               checkError401(error);
             })
         })
         .catch(error => {
           setError(false)
           if(error.response && error.response.status === 420)
           {
             enqueueSnackbar(t('notification_error_authentication_inactive'), {
               variant: 'error'
             })
           }
           else
           {
             enqueueSnackbar(t('notification_error_authentication'), {
               variant: 'error'
             })
           }
         })
     }
   }
 
   const handleClose = (event, reason) => {
     setError(false)
   };
 
   return (
     <div className={classes.outer}>
       <div className={classes.background}>
         <Grid container direction="row" justifyContent="right" alignItems="center" className={classes.grid} spacing={12}>
           <Grid item xs={5} hidden={matches}>
             <div className={classes.box}>
               <img src={logo} className={classes.logo} alt="logo" />
               <Typography variant="h5" component="h5" className={classes.text}>
                 {t('login_page')}
               </Typography>
             </div>
           </Grid>
           <Grid item xs={(matches ? 12 : 5)}>
             <Container component="main" maxWidth="xs">
               <CssBaseline />
               <Paper className={classes.paper} elevation={6}>
                 <Avatar className={classes.avatar}>
                   <LockOutlinedIcon />
                 </Avatar>
                 <Typography component="h1" variant="h5" >
                   {t('login_header')}
                 </Typography>
                 <form className={classes.form} noValidate>
                   <TextField
                     variant="outlined"
                     margin="normal"
                     required
                     fullWidth
                     id="email"
                     label={t('login_email')}
                     name="email"
                     autoComplete="email"
                     autoFocus
                     onChange={e => setEmail(e.target.value)}
                     error={email === "" && submitted}
                     helperText={email === "" && submitted ? 'Email field empty!' : ' '}
                   />
                   <TextField
                     variant="outlined"
                     margin="normal"
                     required
                     fullWidth
                     name="password"
                     label={t('input_password')}
                     type="password"
                     id="password"
                     autoComplete="current-password"
                     onChange={e => setPassword(e.target.value)}
                     error={password === "" && submitted}
                     helperText={password === "" && submitted ? 'Password field empty!' : ' '}
                   />
 
                 <Button
                   type="submit"
                   fullWidth
                   variant="contained"
                   color="primary"
                   className={classes.submit}
                   onClick={handleLogin}
                 >
                   {t('button_sign_in')}
                 </Button>
 
                 <Link to={routes.FORGOT}>{t('login_forgot_pw')}</Link>
 
                 {/*
                  <Link to={routes.SIGNUP}>
                   <Button
                     fullWidth
                     variant="contained"
                     color="secondary"
                     className={classes.submit}
                   >
                     {t('login_signup')}
                   </Button>
                 </Link>*/}
 
               </form>
               </Paper>
             </Container>
           </Grid>
         </Grid>
         <Box mt={8}>
         </Box>
       </div>
       <div>
         <div>
           <Typography component="body2" variant="body2" className={classes.language}>
             <Link href="#" onClick={setEnglish}>
               English
             </Link>
           </Typography>
           <Typography component="body2" variant="body2" color="inherit" className={classes.languageRight}>
             <Link href="#" onClick={setGerman}>
               Deutsch
             </Link>
           </Typography>
         </div>
         <div className={classes.info}>
           <Typography component="body2" variant="body2" color="inherit" className={classes.language}>
             © 2021 LNConsult | Headquarters: Kalsdorfer Straße 12, 8072 Fernitz/Graz, Austria, T: +43 5 9809 100, E: office@LNConsult.at
           </Typography>
         </div>
       </div>
     </div>
   );
 }
 
 
 export default withAppInsights(Login);