/**
 * Forgot Password component
 *
 * @author LHO
 * Last changed: 16.08.2021
 * Changes: Multilanguage support
 */
import "./ForgotPassword.css";
import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import * as endpoints from "../../service/endpoints";
import { withTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import * as constants from "../../common/constants";

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const ForgotPassword = (props) => {
  const { classes, t } = props;

  const [email, setEmail] = useState("");
  const [showEmailField, isShowEmailField] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [messageFromServer, setMessageFromServer] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleReset = (event) => {
    event.preventDefault();
    setSubmitted(true);
    getReset();
  };

  const handleSuccessReset = () => {
    enqueueSnackbar(t("notification_success_reset_email"), {
      variant: "success",
      autoHideDuration: constants.NOTIFICATION_TIME_DEFAULT * 1000,
    });
  };

  const handleErrorReset = () => {
    enqueueSnackbar(t("notification_error_email_not_exist"), {
      variant: "error",
      autoHideDuration: constants.NOTIFICATION_TIME_DEFAULT * 1000,
    });
  };

  const getReset = () => {
    if (email !== "") {
      const authBody = {
        email: email,
      };

      axios
        .post(endpoints.RESET, authBody)
        .then((response) => {
          if (response.data.message === "Success") {
            setMessageFromServer("reset email sent");
            handleSuccessReset();
            isShowEmailField(false);
          }
        })
        .catch((error) => {
          setError(true);
          handleErrorReset();
        });
    }
  };

  const handleClose = (event, reason) => {
    setError(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("login_email")}?
        </Typography>
        <form className={classes.form} noValidate>
          {showEmailField && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("login_email")}
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              error={email === "" && submitted}
              helperText={
                email === "" && submitted ? t("error_email_empty") : " "
              }
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleReset}
          >
            {t("button_request_pw")}
          </Button>
        </form>
      </div>
      {messageFromServer === "reset email sent" && (
        <div>
          <h3>{t("notification_success_reset_email")}</h3>
        </div>
      )}
      <Box mt={8} />
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert severity="error">{t("notification_error_reset")}</MuiAlert>
      </Snackbar>
    </Container>
  );
};

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(
  withStyles(useStyles, { withTheme: true })(ForgotPassword)
);
