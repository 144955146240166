/**
 * Device Registrations chart component
 * 
 * @author JHO 
 * Last changed: 12.10.2022
 * Changes: PBI-1119 Initial implementation
*/
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { green, red } from '@material-ui/core/colors';
import { mdiTabletCellphone } from '@mdi/js'; 
import Icon from '@mdi/react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as routes from '../../../routing/routes';
import Divider from "@material-ui/core/Divider";
  
const useStyles = makeStyles((theme) => ({
  requests:
  {
    marginTop: "-18px",
    marginLeft: "18px",
    position:"absolute",
    background: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
    boxShadow: "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    height: 70,
    width: 70,
    cursor:"pointer",
    "&:hover": {
      background: "linear-gradient(195deg, rgb(236, 94, 122), rgb(216, 64, 96));",
    }
  },
  noRequests:
  {
    marginTop: "-18px",
    marginLeft: "18px",
    position:"absolute",
    background: "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));",
    boxShadow: "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    height: 70,
    width: 70,
    cursor:"pointer",
    "&:hover": {
      background: "linear-gradient(195deg, rgb(102, 203, 106), rgb(67, 187, 71));",
    }
  },
  avatar:
    {
      marginTop: "-18px",
      marginLeft: "18px",
      position:"absolute",
      background: "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));",
      boxShadow: "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
      height: 70,
      width: 70,
      cursor:"pointer",
      "&:hover": {
        background: "linear-gradient(195deg, rgb(102, 203, 106), rgb(67, 187, 71));",
      }
    },
  cards: {
    height: '100%',
    boxShadow: "rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "0.75rem"
  },
  container: {
    position: "relative"
  },
  divider: {
    flexShrink: "0",
    borderTop: "0px solid rgba(0, 0, 0, 0.12)",
    borderRight: "0px solid rgba(0, 0, 0, 0.12)",
    borderLeft: "0px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "transparent",
    height: "0.0625rem",
    margin: "1rem 0px",
    borderBottom: "none",
    opacity: "0.35",
    backgroundImage: "linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0)) !important"
  }
}));

 function DeviceRegistrations(props) { 
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const handleDevicesClick = () => {
    if(props.permission >= 3) {
      props.setSelectedItem(6)
      props.setBold(6)
      history.push(routes.DEVICES)
    }
  }

   return (
     <div className={classes.container}>
  
      {props.data.open_requests > 0 && (
       <Tooltip title={t('dashboard_show_devices')}>
         <Avatar
           className={classes.requests}
           onClick={handleDevicesClick}
         >
           <Icon path={mdiTabletCellphone} size={1.5}/>
         </Avatar>
        </Tooltip>)}
        {props.data.open_requests === 0 && (
       <Tooltip title={t('dashboard_show_devices')}>
         <Avatar
           className={classes.noRequests}
           onClick={handleDevicesClick}
         >
           <Icon path={mdiTabletCellphone} size={1.5}/>
         </Avatar>
        </Tooltip>)}
        
        <Card
         className={classes.cards}
        >
         <CardContent>
           <Grid
             container
             spacing={3}
             style={{ justifyContent: 'space-between' }}
           >
             <Grid item>

             </Grid>
             <Grid item>
               <Typography
                 color="textSecondary"
                 gutterBottom
                 variant="h6"
               >
                 {t('dashboard_registered_devices')}
               </Typography>
               <Typography
                 color="textPrimary"
                 align='right'
                 variant="h3"
               >
                 { props.data.registered_devices }
               </Typography>
             </Grid>

           </Grid>
           <Divider className={classes.divider}>

           </Divider>
           <Box
             style={{
               pt: 2,
               display: 'flex',
               alignItems: 'center'
             }}
           >
             {/*<ArrowUpwardIcon style={{ color: green[900] }} />*/}
             <Typography
               style={{
                 marginLeft: "15px",
                 color: red[900],
                 mr: 1,
                 marginRight: "4px"
               }}
               variant="body1"
             >
               { props.data.open_requests }
             </Typography>
             <Typography
               color="textSecondary"
               variant="caption"
             >
               Open device registration requests
             </Typography>
           </Box>
           <Box
             style={{
               pt: 2,
               display: 'flex',
               alignItems: 'center'
             }}
           >
             {/*<ArrowUpwardIcon style={{ color: green[900] }} />*/}
             <Typography
               style={{
                 marginLeft: "15px",
                 color: green[900],
                 mr: 1,
                 marginRight: "4px"
               }}
               variant="body1"
             >
               { props.data.allowed_devices }
             </Typography>
             <Typography
               color="textSecondary"
               variant="caption"
             >
               Devices allowed with current licence
             </Typography>
           </Box>
         </CardContent>
       </Card>
     </div>

  );
}
  
export default DeviceRegistrations;