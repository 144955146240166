/**
 * Error404 component
 * 
 * @author JHO 
 * Last changed: 12.10.2021
 * Changes: Error404 page
 */
 import { useTranslation } from "react-i18next";
 import error404 from '../../common/error404.svg';
 
 function Error404() {
   const { t } = useTranslation();
 
   return (
       <header className="Menu-header">
       <img src={error404} className="Menu-logo" alt="logo" />
       <p className="Menu-text">
         {t('error_404')}
       </p>
     </header>
   );
 }
 
 export default Error404;