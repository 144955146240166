/**
 * Show images dialog
 * 
 * @author JHO 
 * Last changed: 13.09.2021
 * Changes: PBI-468 Image path copy to clipboard
 */

import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import axios from "axios";
import * as endpoints from "../../../service/endpoints";
 
const useStyles = makeStyles((theme) => ({
    rootElement: {
      maxWidth: 800,
      flexGrow: 1,
      alignItems: 'center'
    },
    dialog: {
        minHeight: '30vh',
        maxHeight: '90vh',
        padding: theme.spacing(2),
        alignItems: 'center'
     },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: 0,
      //backgroundColor: theme.palette.background.default,
    },
    img: {
      display: 'block',
      overflow: 'hidden',
      maxHeight: 600,
      width: 'auto'
    },
    copy: {
        marginLeft: theme.spacing(2)
    },
    root: {
        backgroundColor: 'white',
    }
}));

const ShowImagesDialog = (props) => {
    //usages
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    //state
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = props.images.length;

    //handlers
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.images[activeStep].path)
    }

    const config = {
        headers: { Authorization: `Bearer ${props.token}` }
    };

    const deleteImage = (e) => {
        let measureID = props.images[activeStep].measureID;
        axios.delete(endpoints.IMAGES + '/' + measureID + '/' + props.images[activeStep].imageNumber, config)
            .then(response => {
                props.handleSuccess("delete_image");
            })
            .catch(error => {
                props.handleError();
            })
            setActiveStep(0)
            props.handleClose()
    }

    return (
        <div>
            <div className={classes.rootElement}>
            { props.loaded && ( <div>
                <Paper square elevation={0} className={classes.header}>
                    <Typography variant="subtitle1"><b>{props.images !== undefined && props.images.length > (activeStep) ? props.images[activeStep].path : ''}</b></Typography>
                      <Button className={classes.copy} variant="contained" size="small" onClick={copyToClipboard}>
                        <b>Copy</b>
                      </Button>
                    {props.location !== 'measureTableArticleInfo' && (
                        <Button className={classes.copy} variant="contained" size="small" onClick={(e) => { if(window.confirm(t('delete_image_text'))) deleteImage(e) } }>
                            <b> {t('button_delete')}</b>
                        </Button>
                    )}
                </Paper>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    style={
                        {//TODO: resize view depending on image size
                        }
                    }
                        >
                        {props.images.map((step, index) => (
                                <div key={step.label}>
                                    {Math.abs(activeStep - index) <= 2 ? (
                                        <img className={classes.img} src={step.img} alt={step.label} />
                                    ) : null}
                                </div>
                            ))}
                    }
                </SwipeableViews>
                <MobileStepper
                    className={classes.root}
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {t('show_images_next')}
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                    }
                    backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        {t('show_images_back')}
                    </Button>
                    }
                />
                </div> )}
            </div>
        </div>
    );
};

export default ShowImagesDialog;