/**
 * Articles table custom toolbar
 * 
 * @author JHO 
 * Last changed: 12.10.2021
 * Changes: PBI-601 Parameters error handling
 */
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import ConfirmDialog from "../../confirm/ConfirmDialog";

const defaultToolbarStyles = {
  iconButton: {
  },
};

class CustomToolbar extends React.Component {

  handleClickDownload = () => {
    this.props.handleDownload();
  };

  handleClickDelete = () => {
    this.props.onRowsDelete(this.props.selectedRows);
  };


  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <React.Fragment>
        { (this.props.permissionSettings && this.props.permissionLevel >= this.props.permissionSettings.import) &&
          <Tooltip title={t("dialog_import")}>
            <IconButton className={classes.iconButton} onClick={this.props.action}>
              <AddIcon className={classes.deleteIcon} />
            </IconButton>
          </Tooltip>
        }
        { (this.props.permissionSettings && this.props.permissionLevel >= this.props.permissionSettings.articles.delete) &&
          <Tooltip title={"Delete"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickDelete}>
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        }
        <ConfirmDialog open={this.props.open} handleCancel={this.props.handleCancel} handleConfirm={this.props.handleConfirm} selectedRows={this.props.selectedRows} />
        <Tooltip title={t("dialog_refresh")}>
          <IconButton className={classes.iconButton} onClick={this.props.actionRefresh}>
            <RefreshIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar));