/**
 * Web portal routes
 * 
 * @author JHO 
 * Last changed: 10.08.2021
 * Changes: Added users
 */
export const MAIN = "/"
export const LOGIN = "/auth";
export const RESET = "/reset";
export const FORGOT = "/forgotpwd";
export const SIGNUP = "/signup";
export const MENU = "/menu";
export const DASHBOARD = "/menu/dashboard";
export const MEASURES = "/menu/measures";
export const ARTICLES = "/menu/articles";
export const SETTINGS = "/menu/settings";
export const INFO = "/menu/info";
export const ACCOUNT = "/menu/account";
export const USERS = "/menu/users";
export const DEVICES = "/menu/devices";
export const PLANNING = "/menu/planning";
export const VERIFY = "/verify";
