/**
 * Form for add plan dialog
 * 
 * @author JHO 
 * Last changed: 19.01.2021
 * Changes: Initial implementation
 */

 import React, {useEffect, useState} from 'react';
import * as constants from '../../../common/constants';
import { useSnackbar } from 'notistack';
 import { makeStyles } from '@material-ui/core';
 import TextField from '@material-ui/core/TextField';
 import Button from '@material-ui/core/Button';
 import SaveIcon from '@material-ui/icons/Save';
 import axios from 'axios';
 import * as endpoints from '../../../service/endpoints';
 import { useTranslation } from 'react-i18next';
import {checkError401, formatTimestamp} from "../../../common/helpers";
import DateFnsUtils from '@date-io/date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import locale from 'date-fns/locale/en-US'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import AddIcon from "@material-ui/icons/Add";
import {useHistory} from "react-router-dom";
import * as routes from "../../../routing/routes";
 
 const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
   root: {
     padding: theme.spacing(2),
 
     '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
     },
     '& .MuiButtonBase-root': {
       marginLeft: theme.spacing(2),
     },
   },
    buttons : {
        position: 'absolute',
        right: theme.spacing(3),
        bottom: theme.spacing(3),
    },
   button: {
     display: 'inline'
   }
 }));
 
 const EditPlanForm = (props) => {
   const classes = useStyles();
   const { t } = useTranslation();
   const history = useHistory();
   const { enqueueSnackbar } = useSnackbar();
   const [dateFrom, setDateFrom] = useState(props?.plan?.dateFrom);
   const [dateTo, setDateTo] = useState(props?.plan?.dateTo);
   const [shift, setShift] = useState(props?.plan?.shift);
   const [area, setArea] = useState(props?.plan?.area);
   const [assignedTo, setAssignedTo] = useState(props?.plan?.assignedTo);
   const [priority, setPriority] = useState(props?.plan?.priority);
   const [note, setNote] = useState(props?.plan?.notes)
   const [name, setName] = useState(props?.plan?.name)

   const httpConfig = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

   const handleDateChangeFrom = (date) => {
    setDateFrom(date);
  };

  const handleDateChangeTo = (date) => {
    setDateTo(date);
  };

   const handleAddArticles = () => {
     history.push({
       pathname: routes.ARTICLES,
       state: { detail: "fromPlanning", planID: props.plan}
     })
   };

  const handleSubmit = e => {
    e.preventDefault();

    const planBody = {
      "name": name,
      "dateFrom": formatTimestamp(dateFrom,"export",""),
      "dateTo": formatTimestamp(dateTo,"export",""),
      "shift": shift,
      "area": area,
      "client": "LNC",
      "assignedTo": assignedTo,
      "priority": priority,
      "notes": note,
      "articles": [],
      "measures": [],
      "numberOfSKUs": 0,
      "measuredSKUs": 0
    };

    axios.patch(endpoints.PLANS + "/" + props?.plan?._id, planBody, httpConfig)
    .then(response => {
      enqueueSnackbar(t('notification_success_edit_plan'), {
        variant: 'success',
        autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
      });
    })
    .catch(error => {
      enqueueSnackbar(t('notification_error_edit_plan'), {
        variant: 'error',
        autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time * 1000 : constants.NOTIFICATION_TIME_DEFAULT * 1000
      })
    })
    props.handleClose();
  };

  if (locale && locale.options) {
    locale.options.weekStartsOn = 1
  }

   return (
     <form className={classes.root} onSubmit={handleSubmit}>
         <div>
       <TextField
         label={"Name"}
         value={name}
         variant="outlined"
         required
         type="text"
         onChange={e => setName(e.target.value)}
       />
       </div>
         <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
         <div>
         <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date From"
          value={dateFrom}
          onChange={handleDateChangeFrom}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
       <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date To"
          value={dateTo}
          onChange={handleDateChangeTo}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        </div>
       </MuiPickersUtilsProvider>
       <div>
       <FormControl className={classes.formControl} variant="outlined">
        <InputLabel id="assigned-label">Assigned to</InputLabel>
        <Select
          defaultValue="true"
          renderValue={()=>{return assignedTo.userName === undefined ? assignedTo[0]?.userName : assignedTo.userName}}
          labelId="assigned-label"
          id="assigned-select"
          value={assignedTo}
          label="Assigned to"
          onChange={e => setAssignedTo(e.target.value)}
        >
          {props.users.map((option) => (
            option?.permissionLevel <= props.permissionLevel && (
            <MenuItem value={option}>
              {option?.userName}
            </MenuItem>
          )))}
        </Select>
      </FormControl>
       </div>
       <div>
       <TextField
         label={"Notes"}
         value={note}
         variant="outlined"
         type="text"
         onChange={e => setNote(e.target.value)}
       />
       </div>
       <div className={classes.buttons}>
         <div className={classes.button}>
           <Button type="submit" variant="contained" color="secondary" textColor="white" startIcon={<SaveIcon />}>
             {t("button_update")}
           </Button>
         </div>
         <div className={classes.button}>
           <Button onClick={handleAddArticles} variant="contained" color="secondary" textColor="white" startIcon={<AddIcon/>}>
             {t('button_add_articles')}
           </Button>
         </div>
       </div>
     </form>
   );
 };
 
 export default EditPlanForm;