/**
 * Measures table custom toolbar
 * 
 * @author JHO 
 * Last changed: 12.01.2021
 * Changes: PBI-703 Add export option to measure table
 */
 import React from "react";
 import IconButton from "@material-ui/core/IconButton";
 import Tooltip from "@material-ui/core/Tooltip";
 import RefreshIcon from '@material-ui/icons/Refresh';
 import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
 import Button from '@material-ui/core/Button';
 import AddIcon from "@material-ui/icons/Add";
 import SearchIcon from '@material-ui/icons/Search';
 import { withStyles } from "@material-ui/core/styles";
 import { withTranslation } from 'react-i18next';
 
 const defaultToolbarStyles = {
   iconButton: {
   },
 };
 
 class CustomToolbar extends React.Component {
   render() {
     const { classes } = this.props;
     const { t } = this.props;
     return (
       <React.Fragment>
           { (this.props.show && (this.props.permissionLevel >= this.props.permissionSettings.planning.create)) && (<div>
           <Button style={{backgroundColor: "#ef7d00", color: "white",marginRight: "2dp"}} aria-label="add an alarm" onClick={() => {this.props.handleCreateClick()}} endIcon={<AddIcon/>}>{t("new_plan")}</Button>
           </div>)
            }
            {/*<Button style={{backgroundColor: "#ef7d00", color: "white",marginRight: "10px"}} aria-label="add an alarm" onClick={() => {}} endIcon={<SearchIcon/>}>Search</Button>*/}
       </React.Fragment>
     );
   }
 
 }
 
 export default withTranslation()(withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar));