/**
 * Form for add user dialog
 * 
 * @author JHO 
 * Last changed: 02.11.2021
 * Changes: BUG-630 Password check
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import axios from 'axios';
import * as endpoints from '../../../service/endpoints';
import { useTranslation } from 'react-i18next';
import { checkRequirements } from '../../../common/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),

    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '300px',
    },
    '& .MuiButtonBase-root': {
      margin: theme.spacing(2),
    },
    '& .MuiAutocomplete-popupIndicator': {
      position: 'relative',
      top: '-15px',
      left: '20px'
    },
    '& .MuiAutocomplete-clearIndicator': {
      position: 'relative',
      top: '-15px',
      left: '45px'
    }
  },
  info: { 
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2)
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: '200px'
  }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddUserForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pin, setPIN] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [permissionLevel, setPermissionLevel] = useState(1);
  const [userSites, setUserSites] = useState([]);
  const [siteIDs, setSiteIDs] = useState([]);

  const config = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  const permissionLevels = require('../../../common/permissions.json')

  const handleSubmit = e => {
    e.preventDefault();
    if(password === confirmPassword) {
        const userBody = {
            "firstName": firstName,
            "lastName": lastName,
            "userName": userName,
            "email": email,
            "password": password,
            "permissionLevel": permissionLevel,
            "active": true,
            "sites": siteIDs
        };
        if(pin !== '')
          userBody['pin'] = pin
      
        axios.post(endpoints.USERS,userBody,config)
         .then(response => {
            props.handleSuccess();
         })
         .catch(error => {
          props.handleError();
         })
    }
    props.handleClose();
  };

  const handleChange = (event, value) => {
    setUserSites(value.map((site) => {return { "name": site.name }}));
    setSiteIDs(value.map((site) => {return site._id}));
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        label={t('table_header_firstname')}
        variant="outlined"
        required
        value={firstName}
        onChange={e => setFirstName(e.target.value)}
      />
      <TextField
        label={t('table_header_lastname')}
        variant="outlined"
        required
        value={lastName}
        onChange={e => setLastName(e.target.value)}
      />
      <TextField
        label={t('table_header_username')}
        variant="outlined"
        required
        value={userName}
        onChange={e => setUserName(e.target.value)}
      />
      <TextField
        label={t('table_header_email')}
        variant="outlined"
        type="email"
        required
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <TextField
        label={t('input_password')}
        variant="outlined"
        type="password"
        required
        value={password}
        onChange={e => setPassword(e.target.value)}
        error={!checkRequirements(password)}
        helperText={!checkRequirements(password) ? t('error_password_requirements') : ''}
      />
      <TextField
        label={t('input_confirm_password')}
        variant="outlined"
        type="password"
        required
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
        error={confirmPassword !== password}
        helperText={confirmPassword !== password ? t('error_password_not_matching') : ''}
      />
      <Paper elevation={6} className={classes.info} >
        <InfoIcon/>
        <p class="MuiFormHelperText-root">
          {t('password_header')}
          <ul>
            <li>{t('password_line1')}</li>
            <li>{t('password_line2')}</li>
            <li>{t('password_line3')}</li>
            <li>{t('password_line4')}</li> 
          </ul>
          {t('password_footer')}
          <br></br>
          {t('password_length')}
        </p>
        <p>
        </p>
      </Paper>
      <TextField
        inputProps={{
          maxLength: 4,
          pattern: "[0-9]{4}",
        }}
        label={t('table_header_pin')}
        variant="outlined"
        type="text"
        value={pin}
        onChange={e => setPIN(e.target.value)}
      />
      <TextField
          select
          label={t('table_header_permission')}
          value={permissionLevel}
          onChange={e => setPermissionLevel(e.target.value)}
          variant="outlined"
        >
          {permissionLevels.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          className={classes.formControl}
          multiple
          options={props.sites}
          onChange={handleChange}
          disableCloseOnSelect
          value={userSites}
          getOptionLabel={(option) => option.name}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={"Sites"} placeholder={"Sites"} />
          )}
        />
      <div>
        <Button type="submit" variant="contained" color="secondary" textColor="white" startIcon={<AddIcon />}>
        {t('button_add')}
        </Button>
      </div>
    </form>
  );
};

export default AddUserForm;