/**
 * Settings WebPortal category component
 * 
 * @author JHO 
 * Last changed: 03.01.2022
 * Changes: PBI-658 Added portal table filter settings
 */

 import React from 'react';
 import { useTranslation } from "react-i18next";
 import { useSnackbar } from 'notistack';
 import Typography from '@material-ui/core/Typography';
 import Accordion from '@material-ui/core/Accordion';
 import AccordionSummary from '@material-ui/core/AccordionSummary';
 import AccordionDetails from '@material-ui/core/AccordionDetails';
 import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
 import InputLabel from '@material-ui/core/InputLabel';
 import MenuItem from '@material-ui/core/MenuItem';
 import FormControl from '@material-ui/core/FormControl';
 import Select from '@material-ui/core/Select';
 import SaveIcon from '@material-ui/icons/Save';
 import Button from '@material-ui/core/Button';
 import TextField from '@material-ui/core/TextField';
 import Autocomplete from '@material-ui/lab/Autocomplete';
 import Checkbox from '@material-ui/core/Checkbox';
 import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
 import CheckBoxIcon from '@material-ui/icons/CheckBox';
 import axios from 'axios';
 import * as endpoints from '../../../service/endpoints';
 import { makeStyles } from '@material-ui/core/styles';
 import * as constants from '../../../common/constants';

 const useStyles = makeStyles((theme) => ({
   header: {
     float: 'left',
     color: "black",
     textStyle: "bold",
     padding: theme.spacing(2)
   },
   page: {
     minHeight: '0',
     boxShadow: "5px 5px 30px -12px rgba(0,0,0,0.3)",
     "&:hover": {
       boxShadow: "0 10px 50px -12px rgba(0,0,0,0.3)"
     }
   },
   formControl: {
     minWidth: 250,
     marginRight: theme.spacing(2)
   },
   select: {
     height: 54
   },
   button: {
     float: 'right',
     marginTop: theme.spacing(2),
     marginRight: theme.spacing(2),
     marginBottom: theme.spacing(2)
   },
   top: {
       overflow: "hidden"
   }
 }));

 const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
 const checkedIcon = <CheckBoxIcon fontSize="small" />;
 
 function CategoryWebPortal(props) {
   const { t } = useTranslation();
   const { enqueueSnackbar } = useSnackbar();
   const classes = useStyles();
 
   const httpConfig = {
     headers: { Authorization: `Bearer ${props.token}` }
   };
 
   const [dateFormat, setDateFormat] = React.useState(props.userSettings ?  props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT);
   const [lengthUnit, setLengthUnit] = React.useState(props.userSettings ?  props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT);
   const [volumeUnit, setVolumeUnit] = React.useState(props.userSettings ?  props.userSettings.volume_unit : constants.VOLUME_UNIT_DEFAULT);
   const [weightUnit, setWeightUnit] = React.useState(props.userSettings ?  props.userSettings.weight_unit : constants.WEIGHT_UNIT_DEFAULT);
   const [decimalPlaces, setDecimalPlaces] = React.useState(props.userSettings ?  props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT);
   const [notificationTime, setNotificationTime] = React.useState(props.userSettings ?  props.userSettings.notification_time : constants.NOTIFICATION_TIME_DEFAULT);
   const [measureColumns, setMeasureColumns] = React.useState(props.portalSettings && props.portalSettings.measure_columns ? props.portalSettings.measure_columns : constants.MEASURE_COLUMNS_LIST);
   const [articleColumns, setArticleColumns] = React.useState(props.portalSettings && props.portalSettings.article_columns? props.portalSettings.article_columns : constants.ARTICLE_COLUMNS_LIST);
   const [measureFilters, setMeasureFilters] = React.useState(props.portalSettings && props.portalSettings.measure_filters ? props.portalSettings.measure_filters : constants.MEASURE_TABLE_FILTERS);
   const [articleFilters, setArticleFilters] = React.useState(props.portalSettings && props.portalSettings.article_filters ? props.portalSettings.article_filters : constants.ARTICLE_TABLE_FILTERS);
   const [userFilters, setUserFilters] = React.useState(props.portalSettings && props.portalSettings.user_filters ? props.portalSettings.user_filters : constants.USER_TABLE_FILTERS);
 
   //select handlers
   const handleDateformatChange = (event) => {
     setDateFormat(event.target.value);
   }
   const handleLengthUnitChange = (event) => {
     setLengthUnit(event.target.value);
   }
   const handleVolumeUnitChange = (event) => {
     setVolumeUnit(event.target.value);
   }
   const handleWeightUnitChange = (event) => {
     setWeightUnit(event.target.value);
   }
   const handleDecimalPlacesChange = (event) => {
    setDecimalPlaces(event.target.value);
  }
  const handleNotificationTimeChange = (event) => {
    setNotificationTime(event.target.value);
  }

  const handleChangeArticles = (event, value) => {
    setArticleColumns(value);
  };

  const handleChangeMeasures = (event, value) => {
    setMeasureColumns(value);
  };

  const handleChangeArticlesFilters = (event, value) => {
    setArticleFilters(value);
  };

  const handleChangeMeasuresFilters = (event, value) => {
    setMeasureFilters(value);
  };

  const handleChangeUserFilters = (event, value) => {
    setUserFilters(value);
  };


 
   //notifications
   const handleSuccess = () => {
     enqueueSnackbar(t('notification_success_update_user_settings'), { 
       variant: 'success',
       autoHideDuration: notificationTime*1000,
     });
   }
 
   const handleError = () => {
     enqueueSnackbar(t('notification_error_update_user_settings'), { 
       variant: 'error',
       autoHideDuration: notificationTime*1000,
     });
   }
 
   const onSave = e => {
     e.preventDefault();
     //update parameters
     let userSettingsBody = {
       "date_format": dateFormat,
       "length_unit": lengthUnit,
       "volume_unit": volumeUnit,
       "weight_unit": weightUnit,
       "decimal_places": decimalPlaces,
       "notification_time": notificationTime
     }
    
     let portalSettingsBody = {
       "measure_columns": measureColumns,
       "article_columns": articleColumns,
       "measure_filters": measureFilters,
       "article_filters": articleFilters,
       "user_filters": userFilters
     }
 
     axios.patch(endpoints.PARAMETERS_USER + '/' + props.userId,userSettingsBody,httpConfig)
       .then(response => {
         props.setUserSettings(userSettingsBody);
         window.localStorage.setItem("userSettings", JSON.stringify(userSettingsBody))
         axios.patch(endpoints.PARAMETERS,{"portal":portalSettingsBody},httpConfig)
          .then(response => {
            props.setPortalSettings(portalSettingsBody);
            window.localStorage.setItem("portalSettings", JSON.stringify(portalSettingsBody))
            handleSuccess();
          })
          .catch(error => {
            handleError();
          })
       })
       .catch(error => {
         handleError();
       })

    
   }
 
   return (
   <div className={classes.page} >
     <form className={classes.root} onSubmit={onSave}>
        <div className={classes.top}>
          <Typography className={classes.header} variant="h5" color="black" gutterBottom>
            {t('settings_web_portal')}
          </Typography>
          <Button type="submit" className={classes.button} variant="contained" color="secondary" textColor="white" startIcon={<SaveIcon />}>
                            {t('button_update')}
          </Button>
        </div>
       <Accordion defaultExpanded="true">
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
           >
             <Typography className={classes.heading}>{t('settings_date_format')}</Typography>
           </AccordionSummary>
           <AccordionDetails>
           <div>

           <FormControl className={classes.formControl} variant="outlined">
             <InputLabel id="datetime">{t('measure_date_format')}</InputLabel>
             <Select
               id="datetime"
               label={t('measure_date_format')}
               value={dateFormat}
               onChange={handleDateformatChange}
             >
               <MenuItem value={'datetime_long'}>{t('datetime_long')}</MenuItem>
               <MenuItem value={'datetime_middel'}>{t('datetime_middel')}</MenuItem>
               <MenuItem value={'datetime_short'}>{t('datetime_short')}</MenuItem>
               <MenuItem value={'datetime_auto'}>{t('datetime_auto')}</MenuItem>
               <MenuItem value={'date_only'}>{t('date_only')}</MenuItem>
             </Select>
           </FormControl>

           </div>
           </AccordionDetails>
         </Accordion>
         <Accordion defaultExpanded="true">
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
           >
             <Typography className={classes.heading}>{t('settings_measure_units')}</Typography>
           </AccordionSummary>
           <AccordionDetails>
           <div>
           <FormControl className={classes.formControl} variant="outlined">
             <InputLabel id="length">{t('measure_unit_length')}</InputLabel>
             <Select
               id="length"
               label={t('measure_unit_length')}
               value={lengthUnit}
               onChange={handleLengthUnitChange}
               className={classes.select}
             >
               <MenuItem value={'mm'}>mm</MenuItem>
               <MenuItem value={'cm'}>cm</MenuItem>
               <MenuItem value={'dm'}>dm</MenuItem>
               <MenuItem value={'m'}>m</MenuItem>
               <MenuItem value={'inches'}>inches</MenuItem>
             </Select>
           </FormControl>
           <FormControl className={classes.formControl} variant="outlined">
             <InputLabel id="volume">{t('measure_unit_volume')}</InputLabel>
             <Select
               id="volume"
               label={t('measure_unit_volume')}
               value={volumeUnit}
               onChange={handleVolumeUnitChange}
               className={classes.select}
             >
               <MenuItem value={'mm'}>mm<sup>3</sup></MenuItem>
               <MenuItem value={'cm'}>cm<sup>3</sup></MenuItem>
               <MenuItem value={'dm'}>dm<sup>3</sup></MenuItem>
               <MenuItem value={'m'}>m<sup>3</sup></MenuItem>
             </Select>
           </FormControl>
           <FormControl className={classes.formControl} variant="outlined">
             <InputLabel id="weight">{t('measure_unit_weight')}</InputLabel>
             <Select
               id="weight"
               label={t('measure_unit_weight')}
               value={weightUnit}
               onChange={handleWeightUnitChange}
               className={classes.select}
             >
               <MenuItem value={'g'}>g</MenuItem>
               <MenuItem value={'kg'}>kg</MenuItem>
             </Select>
           </FormControl>
           </div>
           </AccordionDetails>
         </Accordion>

         <Accordion defaultExpanded="true">
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
           >
             <Typography className={classes.heading}>{t('settings_decimal_places')}</Typography>
           </AccordionSummary>
           <AccordionDetails>
           <div>
           <TextField
              label="Number"
              type="number"
              required
              InputProps={{ inputProps: { min: constants.DECIMAL_PLACES_MIN, max: constants.DECIMAL_PLACES_MAX } }}
              onChange={handleDecimalPlacesChange}
              value={decimalPlaces}
              variant="outlined"
            />
           </div>
           </AccordionDetails>
         </Accordion>

         <Accordion defaultExpanded="true">
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
           >
             <Typography className={classes.heading}>{t('settings_notification_time')}</Typography>
           </AccordionSummary>
           <AccordionDetails>
           <div>
           <TextField
              label={t('settings_seconds')}
              type="number"
              required
              InputProps={{ inputProps: { min: constants.NOTIFICATION_TIME_MIN, max: constants.NOTIFICATION_TIME_MAX } }}
              onChange={handleNotificationTimeChange}
              value={notificationTime}
              variant="outlined"
            />
           </div>
           </AccordionDetails>
         </Accordion>

         <Accordion defaultExpanded="true">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={classes.heading}>{t('settings_portal_columns')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.settings}>
          <Autocomplete
            className={classes.formControl}
            multiple
            options={constants.MEASURE_COLUMNS_LIST}
            onChange={handleChangeMeasures}
            disableCloseOnSelect
            value={measureColumns}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            style={{ width: 1000 }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={t('settings_measure_columns')} placeholder={t('settings_measure_columns')} />
            )}
          />
          <Autocomplete
            className={classes.formControl}
            multiple
            options={constants.ARTICLE_COLUMNS_LIST}
            onChange={handleChangeArticles}
            disableCloseOnSelect
            value={articleColumns}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            style={{ width: 1000 }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={t('settings_article_columns')} placeholder={t('settings_article_columns')} />
            )}
          />
       
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded="true">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={classes.heading}>{t('settings_portal_filters')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.settings}>
          <Autocomplete
            className={classes.formControl}
            multiple
            options={constants.MEASURE_TABLE_FILTERS}
            onChange={handleChangeMeasuresFilters}
            disableCloseOnSelect
            value={measureFilters}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            style={{ width: 1000 }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={t('settings_measure_columns')} placeholder={t('settings_add')} />
            )}
          />
          <Autocomplete
            className={classes.formControl}
            multiple
            options={constants.ARTICLE_TABLE_FILTERS}
            onChange={handleChangeArticlesFilters}
            disableCloseOnSelect
            value={articleFilters}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            style={{ width: 1000 }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={t('settings_article_columns')} placeholder={t('settings_add')} />
            )}
          />
        
      { (props.permissionLevel >= 3) &&
       (<Autocomplete
            className={classes.formControl}
            multiple
            options={constants.USER_TABLE_FILTERS}
            onChange={handleChangeUserFilters}
            disableCloseOnSelect
            value={userFilters}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            style={{ width: 1000 }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={t('settings_user_table')} placeholder={t('settings_add')} />
            )}
          />)
          }
          </AccordionDetails>
        </Accordion>
         </form>
   </div>
   );
 }
 
 export default CategoryWebPortal;