/**
 * Users component
 * 
 * @author JHO 
 * Last changed: 03.01.2021
 * Changes: PBI-658 Added table filter settings
 */

import './Users.css';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import * as endpoints from '../../service/endpoints';
import * as routes from '../../routing/routes';
import * as constants from '../../common/constants';
import React, { useState, useEffect } from 'react';
import {checkError401} from '../../common/helpers';
import TableToolbar from "./TableToolbar";
import AddUserDialog from './add/AddUserDialog';
import EditUserDialog from './edit/EditUserDialog';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import withAppInsights from './../../common/appInsights';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ConfirmDialog from "../confirm/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  bread: {
    marginBottom: theme.spacing(1)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline'
    }     
  }
}));

const permissionLevels = require('../../common/permissions.json')
const pageSize = 200;

function Users(props) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [user, setUser] = useState({});
  const [usersLoading, setUsersLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [rowsToDelete, setRowsToDelete] = useState([])
  const classes = useStyles();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // API header
  const httpConfig = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  useEffect(() => {
    window.sessionStorage.setItem("lastRoute", routes.USERS);
  });

  const handleConfirmDelete = () => {
    setConfirmDialogOpen(true)
  }

  const handleCloseConfirm = () => {
    setConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    handleCloseConfirm()
  }

  const handleConfirm = (selectedRows) => {
    deleteUsers(selectedRows, 0)
    handleCloseConfirm()
  }

  //Dialogs state handler
  const handleSuccessEdit = () => {
    axios.get(endpoints.USERS + "?size=" + pageSize,httpConfig)
        .then(response => {
          setData(response.data.data);
        })
        .catch(error => {
          checkError401(error);
        })
    enqueueSnackbar(t('notification_success_user_update'), { 
      variant: 'success',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const handleSuccessAdd = () => {
    axios.get(endpoints.USERS,httpConfig)
        .then(response => {
          setData(response.data.data);
        })
        .catch(error => {
          checkError401(error);
        })
    enqueueSnackbar(t('notification_success_user_add'), { 
      variant: 'success',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const handleError = () => {
    enqueueSnackbar(t('notification_error_general'), { 
      variant: 'error',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    axios.get(endpoints.USERS + "?size=" + pageSize,httpConfig)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.log(error);
        checkError401(error);
      })
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    axios.get(endpoints.USERS + "?size=" + pageSize,httpConfig)
        .then(response => {
          setData(response.data.data);
        })
        .catch(error => {
          checkError401(error);
        })
};

//api calls
const deleteUsers = (rows, idx) => {
  axios.delete(endpoints.USERS + '/' + data[rows[idx].index]._id,httpConfig)
        .then(response => {
          if(idx < (rows.length -1)) {
            deleteUsers(rows, idx + 1);
          }
          else {
            axios.get(endpoints.USERS + "?size=" + pageSize,httpConfig)
              .then(response => {
                setData(response.data.data);
              })
              .catch(error => {
                handleError();
                checkError401(error);
              })
              enqueueSnackbar(t('notification_success_user_delete'), { 
                variant: 'info',
                autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
              });
            }
        })
        .catch(error => {
          handleError();
        })
}

// Data table
const options = {
  filterType: 'checkbox',
  print: false,
  rowsPerPageOptions: [10,20,50],
  onTableInit: (action, tableState) => {
    setUsersLoading(true)
    axios.get(endpoints.USERS + "?size=" + pageSize,httpConfig)
      .then(response => {
        setData(response.data.data);
        setUsersLoading(false)
      })
      .catch(error => {
        setUsersLoading(false)
        checkError401(error);
      })
  },
  customToolbar: () => {
    return (
      <TableToolbar handleClick={handleOpen} />
    );
  },
  onRowsDelete: (rowsDeleted) => {
    setRowsToDelete(rowsDeleted.data)
    handleConfirmDelete()
  },
  textLabels: {
    body: {
        noMatch: usersLoading ?
        <CircularProgress /> :
            t('table_no_data'),
            toolTip: t('table_body_toolTip'),    
          },
          selectedRows: {
            text: t('table_row_selected'),
            delete: t('table_row_delete'),
            deleteAria: t('table_row_deleteAria'),
          }, 
          pagination: {
            next: t('table_pagination_next'),
            previous: t('table_pagination_previous'),
            rowsPerPage: t('table_pagination_rowsPerPage'),
            displayRows: t('table_pagination_displayRows'),
            jumpToPage: t('table_pagination_jumpToPage'),
          },
          toolbar: {
            search: t('table_toolbar_search'),
            downloadCsv: t('table_toolbar_downloadCsv'),
            print: t('table_toolbar_print'),
            viewColumns: t('table_toolbar_viewColumns'),
            filterTable: t('table_toolbar_filterTable'),
          },
          filter: {
            all: t('table_filter_all'),
            title: t('table_filter_title'),
            reset: t('table_filter_reset'),
          },
          viewColumns: {
            title: t('table_viewColumns_title'),
            titleAria: t('table_viewColumns_titleAria'),
          },
    
},
};

const columns = [
{
  name: "_id",
  label: t('table_header_id'),
  options: {
    filter: false,
    sort: true,
  }
},
{
  name: "firstName",
  label: t('table_header_firstname'),
  options: {
    filter: false,
    sort: true,
  }
},
{
  name: "lastName",
  label: t('table_header_lastname'),
  options: {
    filter: false,
    sort: true,
  }
},
{
  name: "userName",
  label: t('table_header_username'),
  options: {
    filter: false,
    sort: true,
  }
},
{
  name: "email",
  label: t('table_header_email'),
  options: {
    filter: false,
    sort: true,
  }
},
{
  name: "pin",
  label: t('table_header_pin'),
  options: {
    filter: false,
    sort: true,
  }
},
{
  name: "permissionLevel",
  label: t('table_header_permission'),
  options: {
    filter: props.portalSettings && props.portalSettings.user_filters && props.portalSettings.user_filters.includes("Permission level"),
    sort: true,
    customBodyRender: (value) => {
      return permissionLevels.find(temp => temp.value === value).label
    }
  }
},
  {
    name: "sites",
    label: t('table_header_site'),
    options: {
      filter: false,
      sort: true,
      customBodyRenderLite: (dataIndex) => {
        let value = data[dataIndex]["sites"];
        if (value) {
          return value.map((item) => {
            return <text>{item.name}<br></br></text>;
          });
        }
        return "";
      }
    }
  },
  {
    name: "active",
    label: t('table_header_active'),
    options: {
      filter: props.portalSettings && props.portalSettings.user_filters && props.portalSettings.user_filters.includes("Active"),
      sort: true,
      empty: true,
      customBodyRenderLite: (dataIndex) => {
        return (
          <Checkbox
            checked={data[dataIndex].active === 1}
            onChange={(e) => { 
              let items = [...data];
              let item = {...data[dataIndex]};
              delete item["password"];
              e.target.checked ? item.active = 1 : item.active = 0;
              //update user
              axios.patch(endpoints.USERS + '/' + item._id,item,httpConfig)
                .then(response => {
                  if(e.target.checked) {
                    enqueueSnackbar(t('notification_success_user_activate'), { 
                      variant: 'info',
                      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
                    });
                  }
                  else {
                    enqueueSnackbar(t('notification_success_user_deactivate'), { 
                      variant: 'info',
                      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
                    });
                  }
                })
                .catch(error => {
                  enqueueSnackbar(t('notification_error_general'), { 
                    variant: 'error',
                    autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
                  });
                })

                items[dataIndex] = item;
                setData(items)
              }}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          );
        }
      }
    },
    {
    name: "edit",
    label: t('table_header_edit'),
    options: {
      filter: false,
      sort: true,
      empty: true,
      customBodyRenderLite: (dataIndex) => {
        return (
          <IconButton color="primary" aria-label="add an alarm" onClick={() => {setUser({...data[dataIndex]}); setOpenEdit(true)}}>
            <EditIcon />
          </IconButton>
          );
        }
      }
    }
];

return (
<div className="Users">
    <Breadcrumbs aria-label="breadcrumb" className={classes.bread}>
      <Link to={routes.DASHBOARD} className={classes.link} onClick={() => {
        props.setSelectedItem(1)
        props.setBold(1)
      }}>
        <HomeIcon className={classes.icon} />
      </Link>
      <Link to={routes.USERS} className={classes.link}>
      {t('component_header_users')}
      </Link>
    </Breadcrumbs>
    <MUIDataTable
      title={""}
      data={data}
      columns={columns}
      options={options}
    />
    <AddUserDialog open={open} handleClose={handleClose} handleSuccess={handleSuccessAdd} handleError={handleError} token={props.token} sites={props.sites}/>
    <EditUserDialog open={openEdit} handleClose={handleCloseEdit} handleSuccess={handleSuccessEdit} handleError={handleError}  token={props.token} user={user} sites={props.sites}/>
    <ConfirmDialog open={confirmDialogOpen} handleCancel={handleCancel} handleConfirm={handleConfirm} selectedRows={rowsToDelete}/>
  </div>
  );
}

export default withAppInsights(Users);
