/**
 * Custom select toolbar for measure table
 * 
 * @author JHO 
 * Last changed: 12.01.2022
 * Changes: PBI-703 Added download option
 */

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import ConfirmDialog from "../../confirm/ConfirmDialog";

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {

  handleClickDelete = () => {
    this.props.onRowsDelete(this.props.selectedRows);
  };

  handleClickDownload = () => {
    this.props.handleDownload(this.props.selectedRows.data);
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.iconContainer}>
         { (this.props.permissionSettings && this.props.permissionLevel >= this.props.permissionSettings.export) &&
          <Tooltip title={t("tooltip_export")}>
            <IconButton className={classes.iconButton} onClick={this.handleClickDownload}>
              <CloudDownloadIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        }
        { this.props.permissionLevel >= this.props.permissionSettings.measures.delete &&
          <Tooltip title={t('tooltip_delete')}>
            <IconButton className={classes.iconButton} onClick={this.handleClickDelete}>
              <DeleteIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        }
        <ConfirmDialog open={this.props.open} handleCancel={this.props.handleCancel} handleConfirm={this.props.handleConfirm} selectedRows={this.props.selectedRows}/>
      </div>
    );
  }
}

export default withTranslation()(withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect));
