/**
 * Constants used accross components
 * 
 * @author JHO 
 * Last changed: 06.10.2022
 * Changes: PBI-1079 Added device lists
 */

 export const VERSION = "v1.0.0";

  export const HID_DEVICES_LIST = [
    'Cubetape',
    'Caliper',
  ];

  export const SPP_DEVICES_LIST = [
    'Multiscan',
    'Scale'
  ];

 
 export const MANDATORY_FIELDS_LIST = [
    'Articlenumber',
    'Barcode',
    'Description',
    'Custom String 1',
    'Custom String 2',
    'Custom Int 1',
    'Custom Int 2',
    'Custom Float'
  ];

  export const INPUT_FIELDS_LIST = [
    'Articlenumber',
    'Barcode',
    'Description',
    'Handling Unit',
    'Custom Fields'
  ];

 export const MEASURE_FIELDS_LIST = [
  'Barcode',
  'Bundle ID',
  'Length',
  'Width',
  'Height',
  'Weight',
  'Volume',
  'Site',
  'User',
  'Timestamp',
  'Image path',
  'Last modified'
];

export const MEASURE_COLUMNS_LIST = [
    'Measure ID',
    'Article ID',
    'Handling unit',
    'Items per HU',
    'Barcode',
    'Bundle ID',
    'Length',
    'Width',
    'Height',
    'Weight',
    'Volume',
    'Site',
    'User',
    'Measure date',
    'Last modified',
    'Exported',
    'Images'
  ];

export const ARTICLE_COLUMNS_LIST = [
    'Article ID',
    'Barcodes',
    'Description',
    'Handling unit',
    'Items per HU',
    'Custom String 1',
    'Custom String 2',
    'Custom Int 1',
    'Custom Int 2',
    'Custom Float',
    'Source',
    'Images',
    'Last measure',
    'Last modified',
    'History'
  ];

export const FIELDS_LIST = [
    'Measure ID',
    'Article ID',
    'Description',
    'Handling unit',
    'Items per HU',
    'Custom String 1',
    'Custom String 2',
    'Custom Int 1',
    'Custom Int 2',
    'Custom Float',
    'Barcode',
    'Bundle ID',
    'Length',
    'Width',
    'Height',
    'Weight',
    'Volume',
    'Site',
    'User',
    'Timestamp',
    'Last modified',
    'Image path'
  ];

export const FIELDS_MAP = {
    'Measure ID': 'Measures.id',
    'Article ID': 'articleID',
    'Description': 'description',
    'Handling unit': 'handlingUnit',
    'Items per HU': 'itemsPerHU',
    'Custom String 1': 'customString1',
    'Custom String 2': 'customString2',
    'Custom Int 1': 'customInt1',
    'Custom Int 2': 'customInt2',
    'Custom Float': 'customFloat',
    'Barcode': 'Measures.barcode',
    'Bundle ID': 'Measures.bundleID',
    'Length': 'Measures.length',
    'Width': 'Measures.width',
    'Height': 'Measures.height',
    'Weight': 'Measures.weight',
    'Volume': 'Measures.volume',
    'Site': 'Measures.SiteId',
    'User': 'Measures.UserId',
    'Timestamp': 'Measures.timeStamp',
    'Last modified': 'Measures.updatedAt',
    'Image path': 'Measures.images'
  };

  export const LANG_MAP = {
    'Measure ID': 'export_header_id',
    'Article ID': 'export_header_articleID',
    'Description': 'export_header_description',
    'Handling unit': 'export_header_hu',
    'Items per HU': 'export_header_pieces',
    'Custom String 1': 'customString1',
    'Custom String 2': 'customString2',
    'Custom Int 1': 'customInt1',
    'Custom Int 2': 'customInt2',
    'Custom Float': 'customFloat',
    'Barcode': 'export_header_barcode',
    'Bundle ID': 'export_header_bundleID',
    'Length': 'export_header_length',
    'Width': 'export_header_width',
    'Height': 'export_header_height',
    'Weight': 'export_header_weight',
    'Volume': 'export_header_volume',
    'Site': 'export_header_site',
    'User': 'export_header_user',
    'Timestamp': 'export_header_timestamp',
    'Last modified': 'export_header_modified',
    'Image path': 'export_header_images'
  };

export const EXPORT_FIELDS = [
    {
        "key": "Measures.id",
        "header": "Measure ID",
        "lang_key": "export_header_id"
    },
    {
        "key": "articleID",
        "header": "Article ID",
        "lang_key": "export_header_articleID"
    },
    {
        "key": "description",
        "header": "Description",
        "lang_key": "export_header_description"
    },
    {
        "key": "handlingUnit",
        "header": "Handling Unit",
        "lang_key": "export_header_hu"
    },
    {
        "key": "itemsPerHU",
        "header": "Items per HU",
        "lang_key": "export_header_pieces"
    },
    {
        "key": "customString1",
        "header": "Custom String 1",
        "lang_key": "customString1"
    },
    {
        "key": "customString2",
        "header": "Custom String 2",
        "lang_key": "customString2"
    },
    {
        "key": "customInt1",
        "header": "Custom Int 1",
        "lang_key": "customInt1"
    },
    {
        "key": "customInt2",
        "header": "Custom Int 2",
        "lang_key": "customInt2"
    },
    {
        "key": "customFloat",
        "header": "Custom Float",
        "lang_key": "customFloat"
    },
    {
        "key": "Measures.barcode",
        "header": "Barcode",
        "lang_key": "export_header_barcode"
    },
    {
        "key": "Measures.bundleID",
        "header": "Bundle ID",
        "lang_key": "export_header_bundleID"
    },
    {
        "key": "Measures.length",
        "header": "Length",
        "lang_key": "export_header_length"
    },
    {
        "key": "Measures.width",
        "header": "Width",
        "lang_key": "export_header_width"
    },
    {
        "key": "Measures.height",
        "header": "Height",
        "lang_key": "export_header_height"
    },
    {
        "key": "Measures.weight",
        "header": "Weight",
        "lang_key": "export_header_weight"
    },
    {
        "key": "Measures.volume",
        "header": "Volume",
        "lang_key": "export_header_volume"
    },
    {
        "key": "Measures.SiteId",
        "header": "Site",
        "lang_key": "export_header_site"
    },
    {
        "key": "Measures.timeStamp",
        "header": "Timestamp",
        "lang_key": "export_header_timestamp"
    },
    {
        "key": "Measures.updatedAt",
        "header": "Last modified",
        "lang_key": "export_header_modified"
    },
    {
        "key": "Measures.UserId",
        "header": "User",
        "lang_key": "export_header_user"
    },
    {
        "key": "Measures.images",
        "header": "Image path",
        "lang_key": "export_header_images"
    }
]

export const ARTICLE_EXPORT_OPTIONS = [
    {
        "key": "only_measured_articles",
        "value": false
    },
    {
        "key": "all_articles",
        "value": true
    }
];

export const MEASURE_EXPORT_OPTIONS = [
    {
        "key": "only_selected_measure",
        "value": 1
    },
    {
        "key": "only_newest_measure",
        "value": 2
    },
    {
        "key": "all_measures",
        "value": 3
    }
];

export const MEASURE_EXPORT_VALUES = {
    "SELECTED": 1,
    "NEW": 2,
    "ALL": 3
}

export const MEASURE_TABLE_FILTERS = [
    'Site',
    'User',
    'Measure date',
    'Last modified',
    'Exported',
    'Images'
];

export const ARTICLE_TABLE_FILTERS = [
    'Handling unit',
    'Source',
    'Last measure',
    'Last modified'
];

export const USER_TABLE_FILTERS = [
    'Permission level',
    'Active'
];

//defaults
export const LENGTH_UNIT_DEFAULT = "mm";
export const WEIGHT_UNIT_DEFAULT = "g";
export const VOLUME_UNIT_DEFAULT = "cm";
export const DATE_FORMAT_DEFAULT = "datetime_short";
export const DECIMAL_PLACES_DEFAULT = 2;
export const DECIMAL_PLACES_MIN = 0;
export const DECIMAL_PLACES_MAX = 10;
export const NOTIFICATION_TIME_DEFAULT = 5; // in seconds
export const NOTIFICATION_TIME_MIN = 1; // in seconds
export const NOTIFICATION_TIME_MAX = 30; // in seconds
export const DELIMITER_DEFAULT = ";";
export const HEADER_DEFAULT = true;
export const EXPORT_IMAGES_DEFAULT = true;
export const IMAGES_NUM_DEFAULT = 1;
export const RETENTION_DAYS_DEFAULT = 30;
export const CHECK_EAN_DEFAULT = false;
export const IMAGE_TAG_ENABLED_DEFAULT = false;
export const SKIP_IMAGES = false;
export const ROTATE_IMAGES = false;
export const STORAGE_SPACE = 10000;
export const IMAGE_TAG_SIZE_DEFAULT = "3";
export const IMAGE_TAG_COLOR_DEFAULT = "#000000";
export const IMAGE_TAG_BACKGROUND_COLOR_DEFAULT = "TRANSPARENT";
export const IMAGE_TAG_POSITION_DEFAULT = "Bottom Left";
export const IMAGE_TAG_VALUE_DEFAULT = "Articlenumber";