/**
 * Measure history component for article
 * 
 * @author JHO 
 * Last changed: 10.01.2022
 * Changes: PBI-712 History entries delete option
 */
import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { convertLength, convertVolume, convertWeight, formatTimestamp, checkError401} from '../../../common/helpers'
import * as endpoints from '../../../service/endpoints';
import { useTranslation } from "react-i18next";
import * as constants from '../../../common/constants';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    alert: {
       display: "inline-flex"
    }
}));

function MeasureHistory(props) {
    //usages
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    //state
    const [data, setData] = useState(props.data);
    const [request, setRequest] = useState(props.request);
    const [measuresExist, setMeasuresExist] = useState(false);
    const [history, setHistory] = useState([]);
    const [pickedMeasureID, setPickedMeasureID] = useState(props.data.pickedMeasureID);

    const config = {
        headers: { Authorization: `Bearer ${props.token}` }
      };

    useEffect(() => {
        props.setCurrentArticleID(data._id)
        if(!request) {
            getHistory();
        }
    });

    const getHistory = () => {
        axios.get(endpoints.MEASURES + '?bundle=' + data._id,config)
            .then(response => {
                if(response.data.data.length > 0)
                    setMeasuresExist(true)
                if(response.data.data.length === 1 && pickedMeasureID == null)
                {
                    setPickedMeasureID(response.data.data[0]._id)
                    props.setPickedMeasureID(response.data.data[0]._id)
                }

                setHistory(response.data.data);
                setRequest(true);
            })
            .catch(error => {
                checkError401(error);
            })
    }

    //Change handlers
    const handleChange = (event,id) => {
        if(event.target.checked) {
            setPickedMeasureID(id)
            props.setPickedMeasureID(id)
        }
    };

    const handleDeleteClick = (measureID) => {
        if(measureID === pickedMeasureID)
        {
            setPickedMeasureID(null)
            props.setPickedMeasureID(null)
        }
        deleteMeasuresById(measureID);
    };

    //API calls
    const deleteMeasuresById = (measureID) => {
        axios.delete(endpoints.MEASURES + '/' + measureID,config)
              .then(response => {
                getHistory();
                handleDeleteSuccess();
              })
              .catch(error => {
                handleError();
              })
    };

    //notifications
    const handleDeleteSuccess = () => {
        enqueueSnackbar(t('notification_success_measure_delete'), {
            variant: 'info',
            autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
        });
    };

    const handleError = () => {
        enqueueSnackbar(t('notification_error_general'), {
          variant: 'error'
        });
    };

    return (
        <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">
            {t('measure_history')}
            </Typography>
            {pickedMeasureID == null && (<MuiAlert className={classes.alert} severity="error">{t('remove_conflict')}</MuiAlert>)}
            <Table size="small" aria-label="purchases">
                {!measuresExist && (<caption>{t('table_no_data')}</caption>)}
                <TableHead>
                    <TableRow>
                        <TableCell align={"left"}>{t('table_header_id')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_timestamp')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_length')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_width')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_height')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_weight')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_volume')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_site')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_user')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_bundleID')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_barcode')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_exported')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_selected')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_delete')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {history.map((measure) => (
                    <TableRow key={measure.id}>
                        <TableCell>{measure.id}</TableCell>
                        <TableCell component="th" scope="row">
                        {formatTimestamp(measure.timeStamp,props.userSettings ? props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT,t('locale'))}
                        </TableCell>
                        <TableCell>{convertLength(measure.length,props.userSettings ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings ? (props.userSettings.length_unit ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT) : constants.LENGTH_UNIT_DEFAULT)}</TableCell>
                        <TableCell>{convertLength(measure.width,props.userSettings ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings ? (props.userSettings.length_unit ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT) : constants.LENGTH_UNIT_DEFAULT)}</TableCell>
                        <TableCell>{convertLength(measure.height,props.userSettings ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings ? (props.userSettings.length_unit ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT) : constants.LENGTH_UNIT_DEFAULT)}</TableCell>
                        <TableCell>{convertWeight(measure.weight,props.userSettings ? props.userSettings.weight_unit : constants.WEIGHT_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings ? (props.userSettings.weight_unit ? props.userSettings.weight_unit : constants.WEIGHT_UNIT_DEFAULT) : constants.WEIGHT_UNIT_DEFAULT)}</TableCell>
                        <TableCell>{convertVolume(measure.volume,props.userSettings ? props.userSettings.volume_unit : constants.VOLUME_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT ) + ' ' + (props.userSettings ? (props.userSettings.volume_unit ? props.userSettings.volume_unit : constants.VOLUME_UNIT_DEFAULT) : constants.VOLUME_UNIT_DEFAULT + "3")}</TableCell>
                        <TableCell>{measure.site ? measure.site.name : ""}</TableCell>
                        <TableCell>{measure.user ? measure.user.userName : ""}</TableCell>
                        <TableCell>{measure.bundleID}</TableCell>
                        <TableCell>{measure.barcode ? measure.barcode.barcode : ""}</TableCell>
                        <TableCell>{measure.exported ? t('table_text_export_yes') : t('table_text_export_no')}</TableCell>
                        <TableCell><Checkbox checked={pickedMeasureID === measure.id} onChange={(e) => handleChange(e,measure.id)}/></TableCell>
                        <TableCell><IconButton aria-label="delete" onClick={() => handleDeleteClick(measure.id)}><DeleteIcon /></IconButton></TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
}

export default MeasureHistory;