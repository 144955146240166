/**
 * Edit user dialog
 * 
 * @author JHO 
 * Last changed: 11.08.2021
 * Changes: initial implementation
 */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import EditUserForm from './EditUserForm';

const EditUserDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <EditUserForm sites={props.sites} handleClose={props.handleClose} handleSuccess={props.handleSuccess} handleError={props.handleError} token={props.token} user={props.user}/>
    </Dialog>
  );
};

export default EditUserDialog;