/**
 * Helper functions
 *
 * @author JHO
 * Last changed: 21.09.2022
 * Changes: PBI-1081 Added check and edit MAC helper function
 */
import moment from "moment/min/moment-with-locales";
import * as routes from "../routing/routes";

function decimalPlaces(value, dp) {
  return +parseFloat(value).toFixed(dp);
}

function addCommas(nStr) {
  nStr += "";
  var x = nStr.split(".");
  var x1 = x[0];
  var x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}

export function convertLength(value, unit, decimal, exporting = false) {
  var temp = 0;
  switch (unit) {
    case "mm":
      temp = value;
      break;
    case "cm":
      temp = value / 10.0;
      break;
    case "dm":
      temp = value / 100.0;
      break;
    case "m":
      temp = value / 1000.0;
      break;
    case "inches":
      temp = value / 25.4;
      break;
    default:
      temp = value;
  }

  return exporting
    ? decimalPlaces(temp, decimal).toString()
    : addCommas(decimalPlaces(temp, decimal).toString());
}

export function convertVolume(value, unit, decimal, exporting = false) {
  var temp = 0;
  switch (unit) {
    case "mm":
      temp = value;
      break;
    case "cm":
      temp = value / 1000.0;
      break;
    case "dm":
      temp = value / 1000000.0;
      break;
    case "m":
      temp = value / 1000000000.0;
      break;
    default:
      temp = value;
  }

  return exporting
    ? decimalPlaces(temp, decimal).toString()
    : addCommas(decimalPlaces(temp, decimal).toString());
}

export function convertWeight(value, unit, decimal, exporting = false) {
  var temp = 0;
  switch (unit) {
    case "g":
      temp = value;
      break;
    case "kg":
      temp = value / 1000.0;
      break;
    default:
      temp = value;
  }

  return exporting
    ? decimalPlaces(temp, decimal).toString()
    : addCommas(decimalPlaces(temp, decimal).toString());
}

export function formatTimestamp(value, format, locale) {
  switch (format) {
    case "datetime_long":
      return locale === "de"
        ? moment(value).locale(locale).format("dddd, D MMMM, YYYY HH:mm:ss")
        : moment(value).locale(locale).format("dddd, MMMM D, YYYY HH:mm:ss");
    case "datetime_middel":
      return locale === "de"
        ? moment(value).locale(locale).format("dd, DD.MM.YYYY HH:mm:ss")
        : moment(value).locale(locale).format("ddd, DD/MM/YYYY HH:mm:ss");
    case "datetime_short":
      return locale === "de"
        ? moment(value).locale(locale).format("DD.MM.YYYY HH:mm:ss")
        : moment(value).locale(locale).format("DD/MM/YYYY HH:mm:ss");
    case "date_only":
      return locale === "de"
        ? moment(value).locale(locale).format("DD.MM.YYYY")
        : moment(value).locale(locale).format("DD/MM/YYYY");
    case "datetime_auto":
      return moment(value).locale(locale).format("DD-MM-YYYY HH:mm:ss");
    case "export":
      return moment(value).locale(locale).format("YYYY-MM-DD HH:mm:ss");
    default:
      return value;
  }
}

export function reformatDate(date, format, locale) {
  if (locale === "de" && format === "datetime_long") {
    let dateArray = date.split(" ");
    if (dateArray.length > 1) {
      let day = dateArray[1];
      let month = dateArray[2];
      month = month.substring(0, month.length - 1);
      let year = dateArray[3];
      let hours = dateArray[4];
      switch (month) {
        case "Januar":
          month = "01";
          break;
        case "Februar":
          month = "02";
          break;
        case "März":
          month = "03";
          break;
        case "April":
          month = "04";
          break;
        case "Mai":
          month = "05";
          break;
        case "Juni":
          month = "06";
          break;
        case "Juli":
          month = "07";
          break;
        case "August":
          month = "08";
          break;
        case "September":
          month = "09";
          break;
        case "Oktober":
          month = "10";
          break;
        case "November":
          month = "11";
          break;
        case "Dezember":
          month = "12";
          break;
        default:
      }
      return year + "-" + month + "-" + day + " " + hours;
    }
  }
  let indexSlash = date.indexOf("/");
  let indexDot = date.indexOf(".");
  let indexMinus = date.indexOf("-");

  let idx =
    indexSlash > -1
      ? indexSlash
      : indexDot > -1
      ? indexDot
      : indexMinus > -1
      ? indexMinus
      : null;
  let symbol =
    indexSlash > -1 ? "/" : indexDot > -1 ? "." : indexMinus > -1 ? "-" : null;

  if (idx) {
    let day = date.substring(idx - 2, idx);
    let month = date.substring(idx + 1, idx + 3);
    return (
      date.substring(0, idx - 2) +
      month +
      symbol +
      day +
      date.substring(idx + 3, date.length)
    );
  } else {
    return date;
  }
}

export function checkError401(error) {
  if (error.response !== undefined && error.response.status === 401) {
    localStorage.removeItem("token");
    window.location.href = routes.LOGIN;
  }
}

export function checkRequirements(password) {
  var minMaxLength = /^[\s\S]{10,}$/,
    upper = /[A-Z]/,
    lower = /[a-z]/,
    number = /[0-9]/,
    special = /[^A-Za-z0-9]/,
    count = 0;

  if (minMaxLength.test(password)) {
    // Only need 3 out of 4 of these to match
    if (upper.test(password)) count++;
    if (lower.test(password)) count++;
    if (number.test(password)) count++;
    if (special.test(password)) count++;
  }

  return count >= 3;
}

export function checkAndEditMACInput(mac) {
  let re = /[0-9A-Fa-f:]/;
  if (!re.test(mac.charAt(mac.length - 1))) {
    mac = mac.substring(0, mac.length - 1);
  }
  let value = mac.replaceAll(":", "");
  if (value.length > 12) {
    mac = mac.substring(0, mac.length - 1);
  }
  if (
    !(mac.charAt(mac.length - 1) === ":") &&
    (value.length === 2 ||
      value.length === 4 ||
      value.length === 6 ||
      value.length === 8 ||
      value.length === 10)
  ) {
    mac = mac + ":";
  }
  return mac;
}

export function formatDailyMeasuresCount(dailyMeasures, thisweek) {
  let dailyCounts = [];
  // console.log("Format")
  // console.log(dailyMeasures)
  for (let i = 0; i < 7; i++) {
    let dateString;
    if (thisweek)
      dateString = moment().subtract(i, "days").format("YYYY-MM-DD");
    else
      dateString = moment()
        .subtract(i + 7, "days")
        .format("YYYY-MM-DD");

    if (
      dailyMeasures.length > 0 &&
      dailyMeasures[0]._id !== undefined &&
      dailyMeasures.findIndex((el) => el._id.day === dateString) > -1
    ) {
      //console.log(dateString)
      dailyCounts.push(
        dailyMeasures.find((el) => el._id.day === dateString).count
      );
    } else dailyCounts.push(0);
  }
  // console.log(dailyCounts)
  return dailyCounts.reverse();
}

export function formatDailyMeasuresCountSpan(dailyMeasures, thisweek, days, lastDate) {
  let dailyCounts = [];
  if (days === 0) {
    days = 1;
  }
  for (let i = 0; i < Math.round(days); i++) {
    let dateString;
    if (thisweek)
      dateString = moment(lastDate).subtract(i, "days").format("YYYY-MM-DD");
    else
      dateString = moment(lastDate)
        .subtract(i + Math.round(days), "days")
        .format("YYYY-MM-DD");

    if (
      dailyMeasures.length > 0 &&
      dailyMeasures[0]._id !== undefined &&
      dailyMeasures.findIndex((el) => el._id.day === dateString) > -1
    ) {
      //console.log(dateString)
      dailyCounts.push(
        dailyMeasures.find((el) => el._id.day === dateString).count
      );
    } else dailyCounts.push(0);
  }
  // console.log(dailyCounts)
  return dailyCounts.reverse();
}

export function formatDailyMeasuresDate() {
  let dateStrings = [];
  for (let i = 0; i < 7; i++) {
    dateStrings.push(moment().subtract(i, "days").format("DD-MM"));
  }
  return dateStrings.reverse();
}

export function formatDailyMeasuresDateSpan(days, lastDate) {
  let dateStrings = [];
  if (days === 0) {
    days = 1;
  }
  for (let i = 0; i < Math.round(days); i++) {
    dateStrings.push(moment(lastDate).subtract(i, "days").format("DD-MM"));
  }
  return dateStrings.reverse();
}

export function getNumberOfConflicts(data) {
  let conflicts = 0;
  for (let article of data) {
    if (article.pickedMeasureID == null && article.measures.length > 1)
      conflicts += 1;
  }
  return conflicts;
}

export function setConflicts(data) {
  for (let article of data) {
    if (
      (article.pickedMeasureID === undefined ||
        article.pickedMeasureID == null) &&
      article.measures.length > 1
    )
      article.conflict = true;
    else article.conflict = false;
  }
  //console.log(data)
  return data;
}
