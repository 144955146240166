/**
 * Settings others category component
 * 
 * @author JHO 
 * Last changed: 18.08.2021
 * Changes: initial implementation
 */

import { useTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    header: {
      color: "black",
      textStyle: "bold",
      padding: theme.spacing(2)
    },
    page: {
      minHeight: '0',
      boxShadow: "5px 5px 30px -12px rgba(0,0,0,0.3)",
      "&:hover": {
        boxShadow: "0 10px 50px -12px rgba(0,0,0,0.3)"
      }
    }
}));
 
function CategoryOthers() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Typography className={classes.header} variant="h5" color="black" gutterBottom>
        {t('settings_others')}  
      </Typography>
    </div>
  );
}

export default CategoryOthers;