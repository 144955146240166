/**
 * Measures table custom toolbar
 * 
 * @author JHO 
 * Last changed: 12.01.2021
 * Changes: PBI-703 Add export option to measure table
 */
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from '@material-ui/icons/Refresh';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';

const defaultToolbarStyles = {
  iconButton: {
  },
};

class CustomToolbar extends React.Component {

  handleClickDownload = () => {
    this.props.handleDownload();
  };

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <React.Fragment>
        { //(this.props.permissionSettings && this.props.permissionLevel >= this.props.permissionSettings.import) &&
        /*<Tooltip title={t("dialog_import")}>
          <IconButton className={classes.iconButton} onClick={this.props.action}>
            <AddIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>*/
        }
        <Tooltip title={t("dialog_refresh")}>
          <IconButton className={classes.iconButton} onClick={this.props.actionRefresh}>
            <RefreshIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
        { (this.props.permissionSettings && this.props.permissionLevel >= this.props.permissionSettings.export) &&
          <Tooltip title={t("tooltip_export")}>
            <IconButton className={classes.iconButton} onClick={this.handleClickDownload}>
              <CloudDownloadIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        }
      </React.Fragment>
    );
  }

}

export default withTranslation()(withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar));