/**
 * Forgot Password component
 * 
 * @author LHO 
 * Last changed: 16.08.2021
 * Changes: Multilanguage support
 */
 import './VerifyAccount.css';
 import React from 'react';
 import axios from 'axios';
 import PropTypes from 'prop-types';
 import Avatar from '@material-ui/core/Avatar';
 import Typography from '@material-ui/core/Typography';
 import Button from '@material-ui/core/Button';
 import CssBaseline from '@material-ui/core/CssBaseline';
 import TextField from '@material-ui/core/TextField';
 import Box from '@material-ui/core/Box';
 import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'; 
 import { withStyles } from '@material-ui/core/styles';
 import VerifiedUserIcon from '@material-ui/icons//VerifiedUser';
 import Container from '@material-ui/core/Container';
 import Snackbar from '@material-ui/core/Snackbar';
 import MuiAlert from '@material-ui/lab/Alert';
 import * as endpoints from '../../service/endpoints';
 import InfoIcon from '@material-ui/icons/Info';
 import { withTranslation } from 'react-i18next';
 import { withRouter } from 'react-router-dom';


const useStyles = theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  });  

class VerifyAccount extends React.Component {
    constructor(props) {
      console.log("VerifyAccount");
      super(props);
  
      this.state = {
        email: "",
        token: "",
        submitted: false,
        error: false,
        messageFromServer: "",
        
      };
  
      this.handleReset = this.handleReset.bind(this);
      this.setEmail = this.setEmail.bind(this);
      //this.setToken = this.setToken.bind(this); // wird im postVerfiy aus url geholt
     
      this.postVerify = this.postVerify.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }
  
    handleReset(event) {
      //event.preventDefault();
      //this.setState({submitted: true});
      this.postVerify();
    };
  
    setEmail(event) {
      this.setState({email: event.target.value});
    };

    setToken(event) {
      this.setState({token: event.target.value});
    };
  
    postVerify() {
/*
      var data = JSON.stringify({
        "active": 1
      });
      
      var config = {
        method: 'patch',
        url: 'http://localhost:3600/api/v1/users/628e2bbb09f34103dfcbd32f',
        headers: { 
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MjIwOTgwZmUwM2NiN2EzMjA2ZDY4YzQiLCJlbWFpbCI6InBhdHJpY2sud2FnZW1hbm5AbG5jb25zdWx0LmF0IiwicGVybWlzc2lvbkxldmVsIjozLCJwcm92aWRlciI6ImVtYWlsIiwibmFtZSI6IlBhdHJpY2sgV2EiLCJ0ZW5hbnQiOiI2MjIwOTgwZmUwM2NiN2EzMjA2ZDY4YzQiLCJyZWZyZXNoS2V5Ijoia0NDLy9ZVC93ajNPUEYrSUxhb1Zkdz09IiwiaWF0IjoxNjUzNDg0NDE1LCJleHAiOjE2NTM1MTMyMTV9.oJbs-urDjRbJh_31e3pFHFfP9vLAroxo0chDU4PvN4w', 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
      */
      const query = new URLSearchParams(this.props.location.search);
      const authBody = 
      {
        "active": 1
      };
      const config = {
        headers: { "Authorization": "Bearer " + query.get('token'),
        'Content-Type': 'application/json'
      }
      };
      axios.patch(endpoints.USERS + "/" + query.get('id'), authBody, config)
          .then(response => {
            window.location.href = "auth";
            /*if(response.statusText === "Verified"){
              this.setState({messageFromServer: 'reset email sent',});
              console.log("DONE!");
              console.log(this.state.messageFromServer);
            }*/
          })
          .catch(error => {
            this.setState({error: true})
          })
      
    };
  
    handleClose = (event, reason) => {
      this.setState({error: false})
    };
  
    render() {
      const { classes } = this.props;
      const { messageFromServer } = this.state;
      const { t } = this.props;

      return (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <VerifiedUserIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('verify_account')}
          </Typography>
          <form className={classes.form} noValidate>
            <Button
              //type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.postVerify}
            >
              {t('button_verify_account')}
            </Button>
          </form>
        </div>
        {messageFromServer === 'reset email sent' && (
          <div>
            <h3>{t('notification_success_create_user')}</h3>
          </div>
        )}
        <Box mt={8}>
        </Box>
        <Snackbar open={this.state.error} autoHideDuration={6000} onClose={this.postVerify}>
          <MuiAlert severity="error">{t('notification_error_create_user')}</MuiAlert>
        </Snackbar>
      </Container>
      );
    }
  }
  
  VerifyAccount.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }
  
  export default withTranslation()(withRouter((withStyles(useStyles, { withTheme: true })(VerifyAccount))));
