/**
 * Planning component
 * 
 * @author JHO 
 * Last changed: 18.01.2022
 * Changes: Initial draft
 */
import './Planning.css';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import * as endpoints from '../../service/endpoints';
import * as constants from '../../common/constants';
import { checkError401, formatTimestamp } from '../../common/helpers'
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import * as routes from '../../routing/routes';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import HomeIcon from '@material-ui/icons/Home';
import CheckIcon from '@material-ui/icons/Check';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomToolbar from "./CustomToolbar";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from '@material-ui/icons/Done';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AddPlanDialog from './add/AddPlanDialog';
import { useSnackbar } from 'notistack';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, useHistory
} from "react-router-dom";
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import EditPlanDialog from "./edit/EditPlanDialog";
import SubmitPlanDialog from "./submit/SubmitPlanDialog";
import CustomToolbarSelect from "./CustomToolbarSelect";
import SKUInfo from "./SKUInfo/SKUInfo";
import SKUInfoDialog from "./SKUInfo/SKUInfo";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MeasureHistory from "../articles/measureHistory/MeasureHistory";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AddIcon from "@material-ui/icons/Add";
import StatusInfo from "./StatusInfo/StatusInfo";
import withAppInsights from './../../common/appInsights'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  bread: {
    marginBottom: theme.spacing(1)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline'
    }     
  },
  status: {
    display: "inline",
    marginRight: theme.spacing(1),
    verticalAlign: 'middle'
  },
  tab: {
    fontWeight: "bold",
  },
  cell: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  },
  dialogPaper: {
    minHeight: '10vh',
    maxHeight: '90vh',
  },
}));

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} style={{color: "green"}} thickness="6.0"/>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textPrimary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function TabPanel(props) {
  const { t } = useTranslation();
  const { children, value, index, ...other } = props;
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const httpConfig = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  const handleConfirmDelete = (selectedRows) => {
    setConfirmDialogOpen(true)
  }

  const handleCloseConfirm = () => {
    setConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    handleCloseConfirm()
  }

  const handleConfirm = (selectedRows) => {
    props.handleDelete(selectedRows)
    handleCloseConfirm()
  }

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
       filter: true,
       sort: true,
      }
     },
    {
     name: "dateFrom",
     label: t('planning_start_date'),
     options: {
      filter: true,
      sort: true,
      customBodyRenderLite: (dataIndex) => {
        return formatTimestamp(props.data[dataIndex]["dateFrom"],props.userSettings && props.userSettings.date_format ? props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT,t('locale'))
      }
     }
    },
    {
     name: "dateTo",
     label: t('planning_end_date'),
     options: {
      filter: true,
      sort: true,
      customBodyRenderLite: (dataIndex) => {
        return formatTimestamp(props.data[dataIndex]["dateTo"],props.userSettings && props.userSettings.date_format ? props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT,t('locale'))
      }
     }
    },
    {
     name: "client",
     label: t('planning_client'),
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "shift",
     label: t('planning_shift'),
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "area",
      label: t('planning_area'),
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "assignedTo.userName",
      label: t('planning_assigned_to'),
      options: {
       filter: true,
       sort: true,
       customBodyRenderLite: (dataIndex) => {
         if(props.data[dataIndex]["assignedTo"].length > 0)
         {
           return props.data[dataIndex]["assignedTo"][0]["userName"];
         }
       }
      }
     },
     {
      name: "priority",
      label: t('planning_priority'),
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "numberOfSKUs",
      label: t('planning_number_sku'),
      options: {
       filter: true,
       sort: true,
        setCellProps: () => {
          return {
            className: classes.cell
          }
        },
      }
     },
     {
      name: "progress",
      label: t('planning_status'),
      options: {
       filter: true,
       sort: true,
       customBodyRenderLite: (dataIndex) => {
         let value = 0
         if(props.data[dataIndex].measuredSKUs > 0 && props.data[dataIndex].numberOfSKUs > 0)
         {
           value = (props.data[dataIndex].measuredSKUs / props.data[dataIndex].numberOfSKUs) * 100
         }
         return ( index > 0 ? ((index === 1) ?
            <CircularProgressWithLabel value={value}/>
            :
            <div className={classes.status}>
              <CheckIcon className={classes.status}>

              </CheckIcon>
              <p className={classes.status}>
              {props.data[dataIndex].status}
              </p>
            </div>
            )
            :
            <div className={classes.status}>
              <ScheduleIcon className={classes.status}>

              </ScheduleIcon>
              <p className={classes.status}>{props.data[dataIndex].status}</p>
            </div>
           );
         },
        setCellProps: () => {
          return {
            className: classes.cell
          }
        }
      }
     },
     {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        empty: true,
        display: index < 2,
        customBodyRenderLite: (dataIndex) => {
          return (
            (props.permissionLevel >= props.permissionSettings.planning.edit) &&
            (<Tooltip title="Edit">
            <IconButton color="primary" aria-label="add an alarm" onClick={() => {
              props.setCurrentPlanIndex(dataIndex)
              props.handleOpenEdit()
            }}>
              <EditIcon />
            </IconButton>
            </Tooltip>)
            );
          }
        }
      },
      {
       name: "",
       label: "",
       options: {
         filter: false,
         sort: false,
         download: false,
         viewColumns: false,
         empty: true,
         display: index < 1,
         customBodyRenderLite: (dataIndex) => {
           return (
            <Tooltip title="Submit">
             <IconButton color="primary" aria-label="add an alarm" onClick={() => {
               props.setCurrentPlanIndex(dataIndex)
               props.handleSubmit(dataIndex)}}>
               <AssignmentTurnedInIcon />
             </IconButton>
             </Tooltip>
             );
           }
         }
       },
       
      {
       name: "",
       label: "",
       options: {
         filter: false,
         sort: false,
         download: false,
         viewColumns: false,
         empty: true,
         display: index === 2,
         customBodyRenderLite: (dataIndex) => {
           return (
            <Tooltip title="Export to CSV">
             <IconButton color="primary" aria-label="add an alarm" onClick={() => {}}>
               <CloudDownloadIcon />
             </IconButton>
             </Tooltip>
             );
           }
         }
       },
       {
        name: "",
        label: "",
        options: {
          filter: false,
          sort: false,
          download: false,
          viewColumns: false,
          empty: true,
          display: index === 1,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Tooltip title="Set completed">
              <IconButton color="primary" aria-label="add an alarm" onClick={() => {
                props.handleComplete(dataIndex)
              }}>
                <DoneIcon />
              </IconButton>
              </Tooltip>
              );
            }
          }
        },
   ];

   const options = {
     filterType: 'checkbox',
     print: false,
     download: false,
     search: false,
     viewColumns: false,
     filter: false,
     customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
       <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} onRowsDelete={handleConfirmDelete} open={confirmDialogOpen} handleCancel={handleCancel} handleConfirm={handleConfirm}/>
     ),
     customToolbar: ({displayData}) => {
       return (
         <CustomToolbar show={index === 0} handleCreateClick={props.handleOpenAdd} permissionLevel={props.permissionLevel} permissionSettings={props.permissionSettings}/>
       );
     },
     onTableInit: (action, tableState) => {

    },
     onCellClick: (colData, cellMeta) => {
       if(cellMeta.colIndex === 8)
       {
         props.setOpenSKUInfo(true)
         props.setDataSKUInfo(props.data[cellMeta.rowIndex])
       }
       else if(cellMeta.colIndex === 9)
       {
         props.setOpenStatusInfo(true)
         props.setDataStatusInfo(props.data[cellMeta.rowIndex])
       }
     },
   };

   const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper className={classes.root}>
        <MUIDataTable
        title={""}
        data={props.data}
        columns={columns}
        options={options}
        />   
        </Paper> 
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
 
 function Planning(props) {
   const { t } = useTranslation();
   const classes = useStyles();
   const theme = useTheme();
   const [value, setValue] = React.useState(0);
   const [openAddPlanDialog, setOpenAddPlanDialog] = React.useState(false);
   const [openEditPlanDialog, setOpenEditPlanDialog] = React.useState(false);
   const [openSubmitPlanDialog, setOpenSubmitPlanDialog] = React.useState(false);
   const [users, setUsers] = useState([]);
   const [dataPlanned, setDataPlanned] = useState([]);
   const [dataInProgress, setDataInProgress] = useState([]);
   const [dataCompleted, setDataCompleted] = useState([]);
   const [currentData, setCurrentData] = useState([]);
   const { enqueueSnackbar } = useSnackbar();
   const [openSKUInfo, setOpenSKUInfo] = useState(false);
   const [openStatusInfo, setOpenStatusInfo] = useState(false);
   const [dataSKUInfo, setDataSKUInfo] = useState([]);
   const [dataStatusInfo, setDataStatusInfo] = useState([]);
   const history = useHistory();
   const [redirectState, setRedirectState] = useState(history?.location?.state?.detail);

   useEffect(() => {
     window.sessionStorage.setItem("lastRoute", routes.PLANNING);
   });


   useEffect(() => {
     getOpenPlans();
     getOngoingPlans();
     getCompletedPlans();
   }, []);

   useEffect(() => {
     getOpenPlans();
     getOngoingPlans();
     getCompletedPlans();
   }, [redirectState]);

   const httpConfig = {
     headers: { Authorization: `Bearer ${props.token}` }
   };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleCloseAdd = () => {
    setOpenAddPlanDialog(false);
    getOpenPlans()
    getOngoingPlans()
    getCompletedPlans()
  };

   const handleCloseEdit = () => {
     setOpenEditPlanDialog(false);
     getOpenPlans()
     getOngoingPlans()
     getCompletedPlans()
   };

   const handleCloseSubmit = () => {
     setOpenSubmitPlanDialog(false);
     getOpenPlans()
     getOngoingPlans()
     getCompletedPlans()
   };

   const handleCloseSKUInfo = () => {
     setOpenSKUInfo(false);
     getOpenPlans()
     getOngoingPlans()
     getCompletedPlans()
   };

   const handleCloseStatusInfo = () => {
     setOpenStatusInfo(false);
     getOpenPlans()
     getOngoingPlans()
     getCompletedPlans()
   };

  const handleOpenAdd = () => {
    setOpenAddPlanDialog(true);
    getUsers()
  };

   const handleAddArticles = () => {
     history.push({
       pathname: routes.ARTICLES,
       state: { detail: "fromPlanning", planID: dataSKUInfo}
     })
   };

  const getUsers = () => {
    axios.get(endpoints.USERS,httpConfig)
      .then(response => {
        setUsers(response.data.data);
      })
      .catch(error => {
        checkError401(error);
      })
  }

  const handleOpenEdit = () => {
    setOpenEditPlanDialog(true)
    getUsers()
  }

  const setCurrentOpenPlan = (dataIndex) => {
    setCurrentData(dataPlanned[dataIndex])
  }

   const setCurrentProgressPlan = (dataIndex) => {
     setCurrentData(dataInProgress[dataIndex])
   }

   const setCurrentCompletedPlan = (dataIndex) => {
     setCurrentData(dataCompleted[dataIndex])
   }

   const getOpenPlans = () => {
     axios.get(endpoints.PLANS + "?status=Created",httpConfig)
       .then(response => {
         setDataPlanned(response.data.data)
       })
       .catch(error => {
         console.log(error);
         //checkError401(error);
       })

   }

   const getOngoingPlans = () => {
     let data = [];
     axios.get(endpoints.PLANS + "?status=Released",httpConfig)
       .then(response => {
         data.push(...response.data.data);
         axios.get(endpoints.PLANS + "?status=In Progress",httpConfig)
           .then(response2 => {
             data.push(...response2.data.data);
             axios.get(endpoints.PLANS + "?status=Finished",httpConfig)
               .then(response3 => {
                 data.push(...response3.data.data);
                 setDataInProgress(data);
               })
               .catch(error => {
                 console.log(error);
                 //checkError401(error);
               })
           })
           .catch(error => {
             console.log(error);
             //checkError401(error);
           })
       })
       .catch(error => {
         console.log(error);
         //checkError401(error);
       })
   }

   const getCompletedPlans = () => {
     axios.get(endpoints.PLANS + "?status=Closed",httpConfig)
       .then(response => {
         setDataCompleted(response.data.data)
       })
       .catch(error => {
         console.log(error);
         //checkError401(error);
       })
   }

   const handleDeleteOpenPlans = (selectedRows) => {
     const indexesToDelete = selectedRows.data.map((row, k) => row.dataIndex);
     let temp = [];
     let deleteData = [];
     for (let i = 0; i < dataPlanned.length; i++) {
       if (!indexesToDelete.includes(i)) {
         temp.push(dataPlanned[i]);
       }
       else {
         deleteData.push(dataPlanned[i]);
       }
     }
     setDataPlanned(temp);
     if (deleteData.length > 0)
       deletePlansById(deleteData, 0);
   }

   const handleDeleteProgressPlans = (selectedRows) => {
     const indexesToDelete = selectedRows.data.map((row, k) => row.dataIndex);
     let temp = [];
     let deleteData = [];
     for (let i = 0; i < dataInProgress.length; i++) {
       if (!indexesToDelete.includes(i)) {
         temp.push(dataInProgress[i]);
       }
       else {
         deleteData.push(dataInProgress[i]);
       }
     }
     setDataInProgress(temp);
     if (deleteData.length > 0)
       deletePlansById(deleteData, 0);
   }

   const handleDeleteCompletePlans = (selectedRows) => {
     const indexesToDelete = selectedRows.data.map((row, k) => row.dataIndex);
     let temp = [];
     let deleteData = [];
     for (let i = 0; i < dataCompleted.length; i++) {
       if (!indexesToDelete.includes(i)) {
         temp.push(dataCompleted[i]);
       }
       else {
         deleteData.push(dataCompleted[i]);
       }
     }
     setDataCompleted(temp);
     if (deleteData.length > 0)
       deletePlansById(deleteData, 0);
   }

   const deletePlansById = (data,idx) => {
     axios.delete(endpoints.PLANS + '/' + data[idx]._id,httpConfig)
       .then(response => {
         if(idx < (data.length -1)) {
           deletePlansById(data, idx + 1);
         }
         else {
           getOpenPlans()
           getOngoingPlans()
           getCompletedPlans()
           enqueueSnackbar(t('notification_success_plan_delete'), {
             variant: 'info',
             autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
           });
         }
       })
       .catch(error => {
         enqueueSnackbar(t('notification_error_general'), {
           variant: 'error',
           autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
         });
       })
   }

   const handleSubmit = (dataIndex) => {
     setOpenSubmitPlanDialog(true)
     const plan = dataPlanned[dataIndex]

     // if(plan.numberOfSKUs === 0)
     // {
     //   setOpenSubmitPlanDialog(true)
     //   alert(t('planning_no_skus_selected'))
     //   return
     // }

     // const body = {
     //   "status": "Released"
     // };
     //
     // axios.patch(endpoints.PLANS + "/" + plan?._id, body, httpConfig)
     //   .then(response => {
     //     getOpenPlans()
     //     getOngoingPlans()
     //     getCompletedPlans()
     //     enqueueSnackbar(t('notification_success_submit_plan'), {
     //       variant: 'success',
     //       autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
     //     });
     //   })
     //   .catch(error => {
     //     enqueueSnackbar(t('notification_error_submit_plan'), {
     //       variant: 'error',
     //       autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time * 1000 : constants.NOTIFICATION_TIME_DEFAULT * 1000
     //     })
     //   })
   }

   const handleComplete = (dataIndex) => {
     const plan = dataInProgress[dataIndex]
     const body = {
       "status": "Closed"
     };

     axios.patch(endpoints.PLANS + "/" + plan?._id, body, httpConfig)
       .then(response => {
         getOpenPlans()
         getOngoingPlans()
         getCompletedPlans()
         enqueueSnackbar(t('notification_success_complete_plan'), {
           variant: 'success',
           autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
         });
       })
       .catch(error => {
         enqueueSnackbar(t('notification_error_complete_plan'), {
           variant: 'error',
           autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time * 1000 : constants.NOTIFICATION_TIME_DEFAULT * 1000
         })
       })
   }

   return (
    <div className="Planning">
      <Breadcrumbs aria-label="breadcrumb" className={classes.bread}>
        <Link to={routes.DASHBOARD} className={classes.link} onClick={() => {
          props.setSelectedItem(1)
          props.setBold(1)
        }}>
          <HomeIcon className={classes.icon} />
        </Link>
        <Link to={routes.USERS} className={classes.link}>
        {t('component_header_planning')}
        </Link>
      </Breadcrumbs>
      <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="primary"
        centered
      >
        <Tab label={t("planning_planned")} className={classes.tab} />
        <Tab label={t("planning_in_progress")} className={classes.tab} />
        <Tab label={t("planning_completed")} className={classes.tab} />
      </Tabs>
    
    <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
      <TabPanel setDataSKUInfo={setDataSKUInfo} setDataStatusInfo={setDataStatusInfo} setOpenStatusInfo={setOpenStatusInfo} setOpenSKUInfo={setOpenSKUInfo} handleSubmit={handleSubmit} data={dataPlanned} handleDelete={handleDeleteOpenPlans} token={props.token} userSettings={props.userSettings} value={value} index={0} handleOpenAdd={handleOpenAdd} handleOpenEdit={handleOpenEdit} setCurrentPlanIndex={setCurrentOpenPlan} permissionLevel={props.permissionLevel} permissionSettings={props.permissionSettings}>
      </TabPanel>
      <TabPanel setDataSKUInfo={setDataSKUInfo} setDataStatusInfo={setDataStatusInfo} setOpenStatusInfo={setOpenStatusInfo} setOpenSKUInfo={setOpenSKUInfo} handleComplete={handleComplete} data={dataInProgress} handleDelete={handleDeleteProgressPlans} token={props.token} userSettings={props.userSettings} value={value} index={1} handleOpenAdd={handleOpenAdd} handleOpenEdit={handleOpenEdit} setCurrentPlanIndex={setCurrentProgressPlan} permissionLevel={props.permissionLevel} permissionSettings={props.permissionSettings}>
      </TabPanel>
      <TabPanel setDataSKUInfo={setDataSKUInfo} setDataStatusInfo={setDataStatusInfo} setOpenStatusInfo={setOpenStatusInfo} setOpenSKUInfo={setOpenSKUInfo} data={dataCompleted} handleDelete={handleDeleteCompletePlans} token={props.token} userSettings={props.userSettings} value={value} index={2} handleOpenAdd={handleOpenAdd} handleOpenEdit={handleOpenEdit} setCurrentPlanIndex={setCurrentCompletedPlan} permissionLevel={props.permissionLevel} permissionSettings={props.permissionSettings}>
      </TabPanel>
      </SwipeableViews>
      </Paper>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        fullWidth={true}
        maxWidth={'xl'}
        open={openSKUInfo}
        onClose={handleCloseSKUInfo}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Plan {dataSKUInfo?.name}</DialogTitle>
        <DialogContent>
          <SKUInfo userSettings={props.userSettings} token={props.token} data={dataSKUInfo}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddArticles} color="primary" variant="contained" startIcon={<AddIcon/>}>
            {t('button_add_articles')}
          </Button>
          <Button onClick={handleCloseSKUInfo} color="primary" variant="contained">
            {t("button_close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        fullWidth={true}
        maxWidth={'xl'}
        open={openStatusInfo}
        onClose={handleCloseStatusInfo}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Plan {dataStatusInfo?.name}</DialogTitle>
        <DialogContent>
          <StatusInfo userSettings={props.userSettings} data={dataStatusInfo}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStatusInfo} color="primary" variant="contained">
            {t("button_close")}
          </Button>
        </DialogActions>
      </Dialog>
      <SubmitPlanDialog userSettings={props.userSettings} users={users} token={props.token} open={openSubmitPlanDialog} handleClose={handleCloseSubmit} plan={currentData}/>
      <EditPlanDialog permissionLevel={props.permissionLevel} userSettings={props.userSettings} users={users} token={props.token} open={openEditPlanDialog} handleClose={handleCloseEdit} plan={currentData}/>
      <AddPlanDialog permissionLevel={props.permissionLevel} userSettings={props.userSettings} users={users} token={props.token} open={openAddPlanDialog} handleClose={handleCloseAdd}/>
    </div>
   );
 }
 
 export default withAppInsights(Planning);