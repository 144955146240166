/**
 * Form for edit user dialog
 * 
 * @author JHO 
 * Last changed: 18.10.2021
 * Changes: PBI-417 Added sites input
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import * as endpoints from '../../../service/endpoints';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),

    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '300px',
    },
    '& .MuiButtonBase-root': {
      margin: theme.spacing(2),
    },
    '& .MuiAutocomplete-popupIndicator': {
      position: 'relative',
      top: '-15px',
      left: '20px'
    },
    '& .MuiAutocomplete-clearIndicator': {
      position: 'relative',
      top: '-15px',
      left: '45px'
    }
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditUserForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState(props.user.firstName);
  const [lastName, setLastName] = useState(props.user.lastName);
  const [userName, setUserName] = useState(props.user.userName);
  const [email, setEmail] = useState(props.user.email);
  const [pin, setPIN] = useState(props.user.pin);
  const [permissionLevel, setPermissionLevel] = useState(props.user.permissionLevel);
  const [userSites, setUserSites] = useState(props.user.sites);
  const [siteIDs, setSiteIDs] = useState(props.user.sites.map((site) => { return site._id }));
  const [deleteSites, setDeleteSites] = useState([]);

  const config = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  const permissionLevels = require('../../../common/permissions.json')

  const handleSubmit = e => {
    e.preventDefault();
    const userBody = {
      "firstName": firstName,
      "lastName": lastName,
      "userName": userName,
      "email": email,
      "permissionLevel": permissionLevel,
      "active": props.user.active,
      "sites": siteIDs
    };
    if(pin !== '')
      userBody['pin'] = pin
    axios.patch(endpoints.USERS + '/' + props.user._id, userBody, config)
      .then(response => {
        props.handleSuccess();
      })
      .catch(error => {
        props.handleError();
        console.log(error);
      })
    props.handleClose();
  };

  const handleDelete = (site) => {
    setUserSites((sites) => sites.filter((item) => item.name !== site.name));
    setSiteIDs((sites) => sites.filter((item) => item !== site._id));
  }

  const handleChange = (event, value) => {
    setUserSites(value.map((site) => { return { "name": site.name } }));
    setSiteIDs(value.map((site) => { return site._id }));
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        label={t('table_header_firstname')}
        variant="outlined"
        required
        value={firstName}
        onChange={e => setFirstName(e.target.value)}
      />
      <TextField
        label={t('table_header_lastname')}
        variant="outlined"
        required
        value={lastName}
        onChange={e => setLastName(e.target.value)}
      />
      <TextField
        label={t('table_header_username')}
        variant="outlined"
        required
        value={userName}
        onChange={e => setUserName(e.target.value)}
      />
      <TextField
        label={t('table_header_email')}
        variant="outlined"
        type="email"
        required
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <TextField
        inputProps={{
          maxLength: 4,
          pattern: "[0-9]{4}",
        }}
        label={t('table_header_pin')}
        variant="outlined"
        type="text"
        value={pin}
        onChange={e => setPIN(e.target.value)}
      />
      <TextField
        select
        label={t('table_header_permission')}
        value={permissionLevel}
        onChange={e => setPermissionLevel(e.target.value)}
        variant="outlined"
      >
        {permissionLevels.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <Autocomplete
        className={classes.formControl}
        multiple
        options={props.sites}
        onChange={handleChange}
        disableCloseOnSelect
        value={userSites}
        getOptionLabel={(option) => option.name}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={userSites.some(item => item.name === option.name)}
            />
            {option.name}
          </React.Fragment>
        )}
        renderTags={(values) =>
          values.map((value) => (
            <Chip
              key={value.name}
              label={value.name}
              onDelete={() => {
                handleDelete(value);
              }}
            />
          ))}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={"Sites"} placeholder={"Sites"} />
        )}
      />
      <div>
        <Button type="submit" variant="contained" color="secondary" textColor="white" startIcon={<SaveIcon />}>
          {t('button_update')}
        </Button>
      </div>
    </form>
  );
};

export default EditUserForm;