/**
 * Form for edit user dialog
 * 
 * @author JHO 
 * Last changed: 06.10.2022
 * Changes: PBI-1079 device settings form
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useTranslation } from 'react-i18next';
import * as constants from '../../../common/constants';

const useStyles = makeStyles(theme => ({
  textInput: {
    marginRight: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }, 
  enableSwitch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  multiSelect: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  header: {
    float: 'left',
    color: "black",
    textStyle: "bold",
    padding: theme.spacing(2)
  },
  button: {
    float: 'right',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  top: {
    overflow: "hidden"
  },
  section: {
    width: '95%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sppContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "50px"
  }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const EditDeviceForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hidEnabled, setHidEnabled] = React.useState(props.deviceSettings && props.deviceSettings.devices.hid.enabled ? props.deviceSettings.devices.hid.enabled : false);
  const [hidDevices, setHidDevices] = React.useState(props.deviceSettings && Array.isArray(props.deviceSettings.devices.hid.used_devices) ? props.deviceSettings.devices.hid.used_devices : []);
  const [sppEnabledMultiscan, setSppEnabledMultiscan] = React.useState(props.deviceSettings && props.deviceSettings.devices.spp.multiscan_spp.enabled ? props.deviceSettings.devices.spp.multiscan_spp.enabled : false);
  const [sppNameMultiscan, setSppNameMultiscan] = React.useState(props.deviceSettings && props.deviceSettings.devices.spp.multiscan_spp.name ? props.deviceSettings.devices.spp.multiscan_spp.name : "");
  const [sppEnabledScale, setSppEnabledScale] = React.useState(props.deviceSettings && props.deviceSettings.devices.spp.scale_spp.enabled ? props.deviceSettings.devices.spp.scale_spp.enabled : false);
  const [sppNameScale, setSppNameScale] = React.useState(props.deviceSettings && props.deviceSettings.devices.spp.scale_spp.name ? props.deviceSettings.devices.spp.scale_spp.name : "");
  const [texpress, setTexpress] = React.useState(props.deviceSettings && props.deviceSettings.devices.texpress ? props.deviceSettings.devices.texpress : false);

  const handleSubmit = e => {
    e.preventDefault();
    let deviceSettingsBody = {
      "devices": {
          "hid": {
              "enabled": hidEnabled,
              "used_devices": hidDevices
          },
          "spp" : {
            "multiscan_spp": {
              "enabled": sppEnabledMultiscan,
              "name": sppNameMultiscan,
            },
            "scale_spp": {
              "enabled": sppEnabledScale,
              "name": sppNameScale,

            }
          },
          "texpress": texpress,
      }
    }
    props.handleClose(deviceSettingsBody);
  };

  const handleChangeHIDEnabled = (event, value) => {
    setHidEnabled(value);
  };

  const handleChangeHIDDevices = (event, value) => {
    setHidDevices(value);
  };

  const handleChangeSPPEnabledMultiscan = (event, value) => {
    setSppEnabledMultiscan(value);
  };

  const handleChangeSPPEnabledScale = (event, value) => {
    setSppEnabledScale(value);
  };

  const handleChangeSPPNameMultiscan = (event) => {
    setSppNameMultiscan(event.target.value);
  };

  const handleChangeSPPNameScale = (event) => {
    setSppNameScale(event.target.value);
  };

  const handleChangeTexpress = (event, value) => {
    setTexpress(value);
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
          <div className={classes.top}>
          <Typography className={classes.header} variant="h5" color="black" gutterBottom>
            {t('device_settings_title')}
          </Typography>
        </div>
        <Accordion className={classes.section} defaultExpanded="true">
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
           >
             <Typography className={classes.heading}>{t('device_settings_hid')}</Typography>
           </AccordionSummary>
           <AccordionDetails>
           <div className={classes.enableSwitch}>
              <FormControlLabel
                  control={
                    <Switch
                      checked={hidEnabled}
                      onChange={handleChangeHIDEnabled}
                      color="secondary"
                    />
                  }
                  labelPlacement="right"
                  label={""}
                />
            </div>
            <Autocomplete
            className={classes.formControl}
            multiple
            disabled={!hidEnabled}
            options={constants.HID_DEVICES_LIST}
            onChange={handleChangeHIDDevices}
            disableCloseOnSelect
            value={hidDevices}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            style={{ width: 1000 }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={t('device_settings_label')} placeholder={t('device_settings_label')} />
            )}
          />
           </AccordionDetails>
         </Accordion>
        <Accordion className={classes.section} defaultExpanded="true">
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
           >
             <Typography className={classes.heading}>{t('device_settings_spp')}</Typography>
           </AccordionSummary>
           <AccordionDetails>
             <div className={classes.sppContainer}>
               <div className={classes.enableSwitch}>
                 <FormControlLabel
                   control={
                     <Switch
                       checked={sppEnabledMultiscan}
                       onChange={handleChangeSPPEnabledMultiscan}
                       color="secondary"
                     />
                   }
                   labelPlacement="right"
                   label={""}
                 />
                 <TextField disabled={!sppEnabledMultiscan} required label={t('device_settings_spp_multiscan_name')} variant="outlined"
                            value={sppNameMultiscan} onChange={handleChangeSPPNameMultiscan}
                 />
               </div>
               <div className={classes.enableSwitch}>
                 <FormControlLabel
                   control={
                     <Switch
                       checked={sppEnabledScale}
                       onChange={handleChangeSPPEnabledScale}
                       color="secondary"
                     />
                   }
                   labelPlacement="right"
                   label={""}
                 />
                 <TextField disabled={!sppEnabledScale} required label={t('device_settings_spp_scale_name')} variant="outlined"
                            value={sppNameScale} onChange={handleChangeSPPNameScale}
                 />
               </div>
             </div>
           </AccordionDetails>
         </Accordion>
        <Accordion className={classes.section} defaultExpanded="true">
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
           >
             <Typography className={classes.heading}>{t('device_settings_texpress')}</Typography>
           </AccordionSummary>
           <AccordionDetails>
           <div className={classes.enableSwitch}>
           <FormControlLabel
                  control={
                    <Switch
                      checked={texpress}
                      onChange={handleChangeTexpress}
                      color="secondary"
                    />
                  }
                  labelPlacement="right"
                  label={""}
    
                />
           </div>
           </AccordionDetails>
         </Accordion>
         <Button type="submit" className={classes.button} variant="contained" color="secondary" textColor="white" startIcon={<SaveIcon />}>
            {t('button_update')}
         </Button>
    </form>
  );
};

export default EditDeviceForm;