/**
 * Edit measure dialog
 * 
 * @author JHO 
 * Last changed: 18.08.2021
 * Changes: initial implementation
 */

 import React from 'react';
 import Dialog from '@material-ui/core/Dialog';
 import EditMeasureForm from './EditMeasureForm';
 import Typography from '@material-ui/core/Typography';
 import { makeStyles } from '@material-ui/core/styles';
 import { useTranslation } from "react-i18next";
 
 //size of measure history dialog
const useStyles = makeStyles((theme) => ({
    dialog: {
        minHeight: '50vh',
        maxHeight: '70vh',
        padding: theme.spacing(2)
     },
}));

 const EditMeasureDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Dialog classes={{ paper: classes.dialog }} fullWidth={true} maxWidth={'md'} open={props.open} onClose={props.handleClose}>
        <Typography variant="h5" color="black" gutterBottom>
                        {t('edit_measure_header')}
        </Typography>
        <EditMeasureForm handleClose={props.handleClose} handleSuccess={props.handleSuccess} handleError={props.handleError} token={props.token} measure={props.measure}/>
        </Dialog>
    );
 };
 
 export default EditMeasureDialog;