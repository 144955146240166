/**
 *
 */
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from "react-i18next";
import * as constants from '../../../common/constants';
import {convertLength, convertVolume, convertWeight, formatTimestamp} from "../../../common/helpers";

function StatusInfo(props) {
    const { t } = useTranslation();
    const [measures, setMeasures] = useState(props.data?.measures);
    const [measuresExist, setMeasuresExist] = useState(props.data?.measures?.length > 0);

    return (
      <Box margin={1}>
        <Typography variant="h6" gutterBottom component="div">
          {t('measure_history')}
        </Typography>
        <Table size="small" aria-label="purchases">
          {!measuresExist && (<caption>{t('table_no_data')}</caption>)}
          <TableHead>
            <TableRow>
              <TableCell align={"left"}>{t('table_header_id')}</TableCell>
              <TableCell align={"left"}>{t('table_header_timestamp')}</TableCell>
              <TableCell align={"left"}>{t('table_header_length')}</TableCell>
              <TableCell align={"left"}>{t('table_header_width')}</TableCell>
              <TableCell align={"left"}>{t('table_header_height')}</TableCell>
              <TableCell align={"left"}>{t('table_header_weight')}</TableCell>
              <TableCell align={"left"}>{t('table_header_volume')}</TableCell>
              <TableCell align={"left"}>{t('table_header_site')}</TableCell>
              <TableCell align={"left"}>{t('table_header_user')}</TableCell>
              <TableCell align={"left"}>{t('table_header_bundleID')}</TableCell>
              <TableCell align={"left"}>{t('table_header_barcode')}</TableCell>
              <TableCell align={"left"}>{t('table_header_exported')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {measures?.map((measure) => (
              <TableRow key={measure?._id}>
                <TableCell>{measure?._id}</TableCell>
                <TableCell component="th" scope="row">
                  {formatTimestamp(measure?.timeStamp,props.userSettings ? props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT,t('locale'))}
                </TableCell>
                <TableCell>{convertLength(measure?.length,props.userSettings ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings ? (props.userSettings.length_unit ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT) : constants.LENGTH_UNIT_DEFAULT)}</TableCell>
                <TableCell>{convertLength(measure?.width,props.userSettings ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings ? (props.userSettings.length_unit ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT) : constants.LENGTH_UNIT_DEFAULT)}</TableCell>
                <TableCell>{convertLength(measure?.height,props.userSettings ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings ? (props.userSettings.length_unit ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT) : constants.LENGTH_UNIT_DEFAULT)}</TableCell>
                <TableCell>{convertWeight(measure?.weight,props.userSettings ? props.userSettings.weight_unit : constants.WEIGHT_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings ? (props.userSettings.weight_unit ? props.userSettings.weight_unit : constants.WEIGHT_UNIT_DEFAULT) : constants.WEIGHT_UNIT_DEFAULT)}</TableCell>
                <TableCell>{convertVolume(measure?.volume,props.userSettings ? props.userSettings.volume_unit : constants.VOLUME_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT ) + ' ' + (props.userSettings ? (props.userSettings.volume_unit ? props.userSettings.volume_unit : constants.VOLUME_UNIT_DEFAULT) : constants.VOLUME_UNIT_DEFAULT + "3")}</TableCell>
                <TableCell>{measure.site ? measure.site.name : ""}</TableCell>
                <TableCell>{measure.user ? measure.user.userName : ""}</TableCell>
                <TableCell>{measure.bundleID}</TableCell>
                <TableCell>{measure.barcode}</TableCell>
                <TableCell>{measure.exported ? t('table_text_export_yes') : t('table_text_export_no')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
}

export default StatusInfo;