/**
 * Edit user dialog
 * 
 * @author JHO 
 * Last changed: 11.08.2021
 * Changes: initial implementation
 */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import EditDeviceForm from './EditDeviceForm';

const EditDeviceDialog = (props) => {
  return (
    <Dialog fullWidth={true} maxWidth={'xl'} open={props.open} onClose={props.handleClose}>
      <EditDeviceForm deviceSettings={props.deviceSettings} handleClose={props.handleClose} handleSuccess={props.handleSuccess} handleError={props.handleError} token={props.token} user={props.user}/>
    </Dialog>
  );
};

export default EditDeviceDialog;