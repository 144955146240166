/**
 * Form for edit article dialog
 * 
 * @author JHO 
 * Last changed: 11.11.2021
 * Changes: BUG-652 Upload multiple images
 */

 import React, { useState } from 'react';
 import { makeStyles } from '@material-ui/core';
 import TextField from '@material-ui/core/TextField';
 import Button from '@material-ui/core/Button';
 import SaveIcon from '@material-ui/icons/Save';
 import ImageIcon from '@material-ui/icons/Image';
 import axios from 'axios';
 import * as endpoints from '../../../service/endpoints';
 import { useTranslation } from 'react-i18next';
 
 const useStyles = makeStyles(theme => ({
   root: {
     padding: theme.spacing(2),
     '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
     '& .MuiButtonBase-root': {
       marginLeft: theme.spacing(2),
     },
   },
    input: {
        display: 'none',
    },
    buttons : {
      position: 'absolute',
      right: theme.spacing(3),
      bottom: theme.spacing(3),
    },
    button: {
      display: 'inline'
    }
 }));

 const EditArticleForm = (props) => {
   const classes = useStyles();
   const { t } = useTranslation();
   const [articleID, setArticleID] = useState(props.article.articleID);
   const [barcodes, setBarcodes] = useState(props.article.barcodes);
   const [description, setDescription] = useState(props.article.article[0].description);
   const [handlingUnit, setHandlingUnit] = useState(props.article.handlingUnit);
   const [itemsPerHU, setItemsPerHU] = useState(props.article.itemsPerHU);
   const [customString1, setCustomString1] = useState(props.article.article[0].customString1);
   const [customString2, setCustomString2] = useState(props.article.article[0].customString2);
   const [customInt1, setCustomInt1] = useState(props.article.article[0].customInt1);
   const [customInt2, setCustomInt2] = useState(props.article.article[0].customInt2);
   const [customFloat, setCustomFloat] = useState(props.article.article[0].customFloat);

   const config = {
     headers: { Authorization: `Bearer ${props.token}` }
   };

   const imageConfig = {
     headers: {
         Authorization: `Bearer ${props.token}`,
         'Content-Type': 'multipart/form-data'
     }
   };

     const httpConfigImages = {
         headers: { Authorization: `Bearer ${props.token}` },
         responseType: 'arraybuffer'
     };

    const uploadImageFile = (files,idx) => {
        let lastMeasureIndex = props.article.measures.length - 1;
        let lastMeasure = props.article.measures[lastMeasureIndex].id;
        let formData = new FormData();
        let nextImageIndex = props.article.measures[lastMeasureIndex].images.length + 1 + idx;
        formData.append("image", files[idx]);
        axios.post(endpoints.IMAGES_INC + '/' + lastMeasure + '/' + nextImageIndex, formData, imageConfig)
            .then(response => {
                if(idx < (files.length-1))
                  uploadImageFile(files,idx+1)
                else
                  props.handleSuccess("upload_image");
            })
            .catch(error => {
                props.handleError();
            })
        props.handleClose();
    }
 
   const handleSubmit = e => {
     e.preventDefault();
     const articleBody = {
       "articleID": articleID,
       "description": description,     
       "customString1": props.customFields.custom_string_1.enabled ? customString1 : null,
       "customString2": props.customFields.custom_string_2.enabled ? customString2 : null,
       "customInt1": props.customFields.custom_int_1.enabled ? customInt1 : null,
       "customInt2": props.customFields.custom_int_2.enabled ? customInt2 : null,
       "customFloat": props.customFields.custom_float.enabled ? customFloat : null,
     };

     const bundleBody = {
      "handlingUnit": handlingUnit,
      "itemsPerHU": itemsPerHU,
      "barcodes": barcodes,
     };

     axios.patch(endpoints.ARTICLES + '/' + props.article.articleID,articleBody,config)
     .then(response => {
      axios.patch(endpoints.BUNDLES + '/' + props.article._id,bundleBody,config)
     .then(response => {
         props.handleSuccess();
     })
     })
     .catch(error => {
         props.handleError();
     })
     props.handleClose();
   };
 
   return (
     <form className={classes.root} onSubmit={handleSubmit}>
         <div>
       <TextField
         label={t('table_header_articleID')}
         variant="outlined"
         disabled
         value={articleID}
       />
       <TextField
         label={t('table_header_description')}
         variant="outlined"
         value={description}
         onChange={e => setDescription(e.target.value)}
       />
        <TextField
         label={t('table_header_hu')}
         variant="outlined"
         value={handlingUnit}
         onChange={e => setHandlingUnit(e.target.value)}
       />
       <TextField
         label={t('table_header_pieces')}
         variant="outlined"
         type="number"
         value={itemsPerHU}
         InputProps={{ inputProps: { min: 1} }}
         onChange={e => setItemsPerHU(e.target.value)}
       />
       </div>
       <div>
       { props.customFields.custom_string_1.enabled && <TextField
         label={(props.customFields.custom_string_1 && props.customFields.custom_string_1.name !== "") ? props.customFields.custom_string_1.name :  "Custom String 1"}
         variant="outlined"
         value={customString1}
         onChange={e => setCustomString1(e.target.value)}
       /> }
       { props.customFields.custom_string_2.enabled && <TextField
         label={(props.customFields.custom_string_2 && props.customFields.custom_string_2.name !== "") ? props.customFields.custom_string_2.name :  "Custom String 2"}
         variant="outlined"
         value={customString2}
         onChange={e => setCustomString2(e.target.value)}
       /> }
      { props.customFields.custom_int_1.enabled && <TextField
         label={(props.customFields.custom_int_1 && props.customFields.custom_int_1.name !== "") ? props.customFields.custom_int_1.name :  "Custom Int 1"}
         variant="outlined"
         type="number"
         value={customInt1}
         onChange={e => setCustomInt1(e.target.value)}
       /> }
       { props.customFields.custom_int_2.enabled && <TextField
         label={(props.customFields.custom_int_2 && props.customFields.custom_int_2.name !== "") ? props.customFields.custom_int_2.name :  "Custom Int 2"}
         variant="outlined"
         type="number"
         value={customInt2}
         onChange={e => setCustomInt2(e.target.value)}
       /> }
       { props.customFields.custom_float.enabled && <TextField
         label={(props.customFields.custom_float && props.customFields.custom_float.name !== "") ? props.customFields.custom_float.name :  "Custom Float"}
         variant="outlined"
         type="number"
         value={customFloat}
         onChange={e => setCustomFloat(e.target.value)}
       /> }
       </div>

       <div className={classes.buttons}>
         <div className={classes.button} >
         <Button type="submit" variant="contained" color="secondary" textColor="white" startIcon={<SaveIcon />}>
           {t('button_update')}
         </Button>
         </div>
           { props.article.measures.length > 0 &&
               <div className={classes.button}>
                   <input accept="image/jpeg, image/png" className={classes.input} id="contained-button-file" multiple type="file"
                          onChange={(e) => uploadImageFile(e.target.files,0)}/>
                       <label htmlFor="contained-button-file">
                           <Button type= "submit" variant="contained" color="secondary" textColor="white" component="span" startIcon={<ImageIcon />}>
                                {t('button_add_image')}
                           </Button>
                       </label>
                </div>
           }
       </div>
     </form>
   );
 };
 
 export default EditArticleForm;