/**
 * Form for submit plan
 *
 * @author MFA
 * Last changed: 19.05.2021
 * Changes: Initial implementation
 */

import * as constants from '../../../common/constants';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import * as endpoints from '../../../service/endpoints';
import { useTranslation } from 'react-i18next';
import AddIcon from "@material-ui/icons/Add";
import {useHistory} from "react-router-dom";
import * as routes from "../../../routing/routes";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    '& .MuiButtonBase-root': {
      marginLeft: theme.spacing(2),
    },
  },
  buttons : {
    alignContent: 'center',
    right: theme.spacing(3),
    bottom: theme.spacing(3),
  },
  button: {
    display: 'flex',
    justifyContent: 'center'
  },
  text: {
    fontSize: '15pt',
    textAlign: 'center'
  }
}));

const SubmitPlanForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const httpConfig = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  const handleAddArticles = () => {
    history.push({
      pathname: routes.ARTICLES,
      state: { detail: "fromPlanning", planID: props.plan}
    })
  };

  const handleSubmit = e => {
    e.preventDefault();

    const body = {
      "status": "Released"
    };

    axios.patch(endpoints.PLANS + "/" + props?.plan?._id, body, httpConfig)
      .then(response => {
        enqueueSnackbar(t('notification_success_submit_plan'), {
          variant: 'success',
          autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
        });
      })
      .catch(error => {
        enqueueSnackbar(t('notification_error_submit_plan'), {
          variant: 'error',
          autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time * 1000 : constants.NOTIFICATION_TIME_DEFAULT * 1000
        })
      })
    props.handleClose();
  };

  if(props.plan.numberOfSKUs !== 0)
  {
    return (
      <form className={classes.root} onSubmit={handleSubmit}>
        <p className={classes.text}>{t('delete_text_1')}</p>
          <div className={classes.button}>
            <Button type="submit" variant="contained" color="secondary" textColor="white">
              {t("table_text_export_yes")}
            </Button>
          </div>
      </form>
    )
  }
  else
  {
    return (
      <div className={classes.root}>
        <p className={classes.text}>{t("planning_no_skus_selected")}</p>
          <div className={classes.button}>
            <Button onClick={handleAddArticles} variant="contained" color="secondary" textColor="white" startIcon={<AddIcon/>}>
              {t('button_add_articles')}
            </Button>
          </div>
      </div>
    )
  }

}

export default SubmitPlanForm;