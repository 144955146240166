/**
 * Dashboard component
 * 
 * @author JHO 
 * Last changed: 17.10.2022
 * Changes: PBI-1119 Added device card
 */
import './Dashboard.css';
import * as routes from '../../routing/routes';
import React, {useEffect, useCallback, useState} from 'react';
import { Box, Container, Grid, Card, CardContent} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DailyMeasures from './charts/DailyMeasures';
import { useTranslation } from "react-i18next";
import TotalMeasures from './charts/TotalMeasures'
import TotalArticles from './charts/TotalArticles'
import DeviceRegistrations from './charts/DeviceRegistrations'
import MeasuredProgress from './charts/MeasuredProgress'
import Planning from './charts/Planning'
import illustration from '../../common/start_trans.gif';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import HistoryIcon from '@material-ui/icons/History';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import * as endpoints from '../../service/endpoints';
import {checkError401, getNumberOfConflicts, setConflicts} from '../../common/helpers';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Conflicts from "./charts/Conflicts";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MeasureHistory from "../articles/measureHistory/MeasureHistory";
import DialogActions from "@material-ui/core/DialogActions";
import withAppInsights from './../../common/appInsights'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '6px'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
    statsInner: {
    },
    welcome: {
      height: '100%',
      boxShadow: "rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderRadius: "0.75rem"
    },
    root: {
      flexGrow: 1,
      marginLeft: "60px",
      marginRight: "0px",
      marginTop: "60px",
      minHeight: '90%',
      minWidth: '70%',
      py: 3
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    daily: {
      height: '50vh',
      width: '92vw'
    },
    container: {
      marginLeft: '0px',
    },
    subtitle: {
      marginBottom: theme.spacing(4)
    },
    title: {
      marginBottom: theme.spacing(2)
    },
    optionBtn: {
      float: 'left',
      marginLeft: theme.spacing(3)
    }
  }));

function Dashboard(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();
    const [init, setInit] = React.useState(true);
    const [openExportHistory, setOpenExportHistory] = useState(false);

    const httpConfig = {
      headers: { Authorization: `Bearer ${props.token}` }
    };

    //data
    const data = {
      "total_measures": 0,
      "last_24h_measures": 0,
      "total_articles": 0,
      "last_24h_articles": 0,
      "measured_articles": 0,
      "conflicts": 0,
      "daily_measures_thisweek": [0,0,0,0,0,0,0],
      "daily_measures_lastweek": [0,0,0,0,0,0,0],
      "dates": ["Mi.","Do.","Fr.","Sa.","So.","Mo.","Di."],
      "allowed_devices": 0,
      "registered_devices": 0,
      "open_requests": 0,
      "plans_in_progress": 0,
      "plans": []
    }

    const [measureStatistics, setMeasureStatistics] = React.useState(data);
    const [articleStatistics, setArticleStatistics] = React.useState(data);
    const [deviceStatistics, setDeviceStatistics] = React.useState(data);
    const [plansStatistics, setPlansStatistics] = React.useState(data);
    const [initialLoading, setInitialLoading] = useState(false);
    const [tmpData, setTmpData] = useState([]);
    //const [numberOfConflicts, setNumberOfConflicts] = useState(0)

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };



    const handleExport = useCallback(() => history.push('/menu/articles/export'), [history]);
    const handleImport = useCallback(() => history.push('/menu/articles/import'), [history]);

    const handleExportHistory = () => {
      setOpenExportHistory(true)
    }

    const handleCloseExportHistory = () => {
    setOpenExportHistory(false)
    }

    const getStatistics = () => {
      axios.get(endpoints.STATISTICS_ARTICLES,httpConfig)
         .then(response => {
           axios.get(endpoints.STATISTICS_MEASURES,httpConfig)
              .then(response2 => {
                axios.get(endpoints.STATISTICS_DEVICES + "/" + props.organizationId,httpConfig)
                  .then(response3 => {
                    axios.get(endpoints.STATISTICS_PLANS,httpConfig)
                      .then(response4 => {
                        setPlansStatistics(response4.data);
                        setDeviceStatistics(response3.data);
                        setMeasureStatistics(response2.data);
                        setArticleStatistics(response.data);
                      })
                      .catch(error => {
                        checkError401(error);
                      })
                  })
                  .catch(error => {
                    checkError401(error);
                  })
              })
              .catch(error => {
                checkError401(error);
              })
         })
         .catch(error => {
            checkError401(error);
         })
    }

    const getArticlesByPageAndSize = (page, size, currentData) => {
        axios.get(endpoints.BUNDLES + "?size=" + size + "&page=" + page, httpConfig)
            .then(response => {
                let newData = currentData.concat(response.data.data)
                if (response.data.totalPages > response.data.pageNumber)
                    getArticlesByPageAndSize(page + 1, size, newData);
                else {
                    setTmpData(setConflicts(newData))
                    setNumberOfConflicts(getNumberOfConflicts(newData))
                }
            })
            .catch(error => {
                console.log(error);
                checkError401(error);
            })
    }

    useEffect(() => {
        window.sessionStorage.setItem("lastRoute", routes.DASHBOARD);
        // console.log("UseEffect")
        if(init) {
          setInit(false);
          // console.log("GetStatistics")
          getStatistics();
          //getArticlesByPageAndSize(1, 100000, []);
        }
    });

    return (
        <Box className={classes.root}>
        <div className={classes.optionBtn}>
        <Dialog
          classes={{}}
          fullWidth={true}
          maxWidth={'xl'}
          open={openExportHistory}
          onClose={handleCloseExportHistory}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">{t("dialog_export_history")}</DialogTitle>
          <DialogContent>
            {/*<MeasureHistory data={historyArticleData} token={props.token} request={historyRequest} userSettings={props.userSettings} setPickedMeasureID={setPickedMeasureID} setCurrentArticleID={setCurrentArticleID} />*/}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseExportHistory} color="primary" variant="contained">
              {t("button_close")}
            </Button>
          </DialogActions>
        </Dialog>
        <StyledMenu
          classes={{}}
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem onClick={handleExport}>
            <ListItemIcon>
              <CloudDownloadIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary={t('dashboard_export')} />
          </StyledMenuItem>
          <StyledMenuItem onClick={handleImport}>
            <ListItemIcon>
              <AddIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary={t('dashboard_import')} />
          </StyledMenuItem>
          <StyledMenuItem onClick={handleExportHistory}>
            <ListItemIcon>
              <HistoryIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary={t('dashboard_export_history')} />
          </StyledMenuItem>
        </StyledMenu>
      </div>
      <Container maxWidth={true} className={classes.container}>
        <Grid container spacing={3} columns={{ xs: 9, sm: 10, md: 10, lg: 12, xl: 12 }} alignItems="stretch">
          <Grid item xs={10} sm={10} md ={5} lg={3} xl={3} className={classes.stats}>
            <TotalMeasures className={classes.statsInner} data={measureStatistics} setSelectedItem={props.setSelectedItem} setBold={props.setBold}/>
          </Grid>
          <Grid item xs={10} sm={10} md ={5} lg={3} xl={3} className={classes.stats}>
            <TotalArticles data={articleStatistics} setSelectedItem={props.setSelectedItem} setBold={props.setBold}/>
          </Grid>
          <Grid item xs={10} sm={10} md ={5} lg={3} xl={3} className={classes.stats}>
            <Conflicts className={classes.statsInner} data={articleStatistics.conflicts} setSelectedItem={props.setSelectedItem} setBold={props.setBold}/>
          </Grid>
          <Grid item xs={10} sm={10} md ={5} lg={3} xl={3} className={classes.stats}>
            <DeviceRegistrations data={deviceStatistics} setSelectedItem={props.setSelectedItem} setBold={props.setBold} permission={props.permission}/>
          </Grid>
          <Grid item xs={10} sm={10} md ={10} lg={3} xl={3} className={classes.stats}>
            <MeasuredProgress data={articleStatistics}/>
          </Grid>
          { (props.permissionSettings && props.permission >= props.permissionSettings.planning.view) &&
           <Grid item xs={10} sm={10} md ={10} lg={4} xl={4} className={classes.stats}>
            <Planning data={plansStatistics}/>
          </Grid> 
          }
          <Grid item xs={10} sm={10} md ={10} lg={5} xl={5} className={classes.daily}>
            <DailyMeasures data={measureStatistics} token={props.token}/>
          </Grid>
        </Grid>
      </Container>
    </Box>
    );
}

export default withAppInsights(Dashboard);