/**
 * Add device dialog
 * 
 * @author JHO 
 * Last changed: 05.10.2022
 * Changes: PBI-1132 Added device connect QR code
 */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import QRCode from "react-qr-code";
import * as endpoints from '../../../service/endpoints';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  qr: {
    margin: 'auto'
  },
}));

const AddDeviceDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle id="simple-dialog-title">{t('scan_qr_code')}</DialogTitle>
       <QRCode className={classes.qr} value={JSON.stringify(endpoints.QR_DATA)} />
    </Dialog>
  );
};

export default AddDeviceDialog;