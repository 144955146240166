/**
 * Measure progress chart component
 * 
 * @author JHO 
 * Last changed: 04.11.2021
 * Changes: PBI-623 Initial implementation
*/
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import { mdiTapeMeasure } from '@mdi/js'; 
import Icon from '@mdi/react';
import Tooltip from '@material-ui/core/Tooltip';
import DateRangeIcon from '@material-ui/icons/DateRange';

const BorderLinearProgressRed = withStyles((theme) => ({
    root: {
      height: 20,
      borderRadius: 5,
      marginBottom: 30
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#ade8f4',
    },
  }))(LinearProgress);

const BorderLinearProgressGreen = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
    marginBottom: 30
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#03045e',
  },
}))(LinearProgress);

const BorderLinearProgressYellow = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
    marginBottom: 30
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#0077b6',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative"
  },
  cards: {
    //height: '100%',
    boxShadow: "rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "0.75rem"
  },
  green: {
      backgroundColor: '#9CB390',
  },
  name: {
    textAlign: 'left'
  },
}));


function Planning(props) {
   const { t } = useTranslation();
   const classes = useStyles();

  return (
    <div className={classes.container}>
    <Card
      className={classes.cards}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          style={{ justifyContent: 'center' }}
        >
          <Grid item xs={12}>
            <Typography
              color="textSecondary"
              gutterBottom
              align='center'
              variant="h6"
            >
              {t('dashboard_active_plans')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
          {props.data.plans.map((plan) => {       
            return (
            <Box>
                <Typography variant="subtitle1" gutterBottom className={classes.name}>
                    { plan.name }
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                    { plan.progress > 0.7
                      ? <BorderLinearProgressGreen variant="determinate" value={plan.progress*100}/>
                      : ( plan.progress > 0.35 
                        ? <BorderLinearProgressYellow variant="determinate" value={plan.progress*100}/>
                        : <BorderLinearProgressRed variant="determinate" value={plan.progress*100}/>)
                    }
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                    <Typography variant="subtitle1" gutterBottom>
                        { Math.round(plan.progress*100) }%
                    </Typography>
                  </Grid>
                </Grid>
            </Box>
            )})}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    </div>
  );
}
  
export default Planning;