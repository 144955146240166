/**
 * Custom toolbar for device table
 * 
 * @author JHO 
 * Last changed: 05.10.2022
 * Changes: PBI-1132 Added device connect
 */

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';

const defaultToolbarStyles = {
  iconButton: {
  },
};

class TableToolbar extends React.Component {

  render() {
    const { classes } = this.props;
    const { t } = this.props;


    return (
      <React.Fragment>
        <Tooltip title={t("add_device")}>
          <IconButton className={classes.iconButton} onClick={this.props.handleClick}>
            <AddToQueueIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("dialog_refresh")}>
          <IconButton className={classes.iconButton} onClick={this.props.actionRefresh}>
            <RefreshIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withStyles(defaultToolbarStyles, { name: "TableToolbar" })(TableToolbar));