/**
 * Account component
 * 
 * @author JHO
 * Last changed: 16.08.2021
 * Changes: Multilanguage support
 */
import './Account.css';
import axios from 'axios';
import * as endpoints from '../../service/endpoints';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {checkError401} from '../../common/helpers';
const permissionLevels = require('../../common/permissions.json')

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        marginLeft: "82px",
        marginRight: "12px",
        marginTop: "82px",
    },
    paper: {
        textAlign: 'left',
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(1)
    },
    header: {
        padding: theme.spacing(2),
        color: "black",
        textStyle: "bold"
    },
    horizontal: {
        display: "inline"
    },
    icon: {
        float: "left",
        marginLeft: theme.spacing(2)
    },
    text: {
        overflow: "hidden",
        margin: theme.spacing(2),
    }
});

class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            email: '',
            firstName: '',
            lastName: '',
            permissionLevel: '',
            response: false
        }
    }

    componentDidMount() {
        this.findUser();
    }


    findUser() {

        const config = {
            headers: { Authorization: `Bearer ${this.props.token}` }
        };
        axios.get(endpoints.USERS + `/${this.props.userId}`, config)
            .then(res => {
                console.log(res);
                this.setState(
                    {
                        userName: res.data.userName,
                        email: res.data.email,
                        firstName: res.data.firstName,
                        lastName: res.data.lastName,
                        permissionLevel: permissionLevels.find(temp => temp.value === res.data.permissionLevel).label,
                        response: true
                    })
            })
            .catch(error => {
              checkError401(error);
            })
    }

    handleSubmit = () => {

    }

    render() {

        const { classes } = this.props;
        const { userName, email, firstName, lastName, response, permissionLevel } = this.state;
        const { t } = this.props;

        if (!response)
            return null
        return (

<div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h5" color="black" gutterBottom>
                  {t('account_header')}
                </Typography>
                <Divider />
                <div>
                  <Typography className={classes.text} variant="subtitle1" gutterBottom>
                  {response && (
                    <text>{t('table_header_firstname')}: <b>{firstName}</b></text>
                
                )
                }
                  </Typography>
                </div>
                <Divider />
                <div>
                  <Typography className={classes.text} variant="subtitle1" gutterBottom>
                  {response && (
                    <text>{t('table_header_lastname')}: <b>{lastName}</b></text>
                )
                }
                  </Typography>
                </div>
                <Divider />
                <div>
                  <Typography className={classes.text} variant="subtitle1" gutterBottom>
                  {response && (
                    <text>{t('table_header_username')}: <b>{userName}</b></text>
                )
                }
                  </Typography>
                </div>
                <Divider />
                <div>
                  <Typography className={classes.text} variant="subtitle1" gutterBottom>
                  {response && (
                   <text>{t('table_header_email')}: <b> {email}</b></text>
                )
                }
                  </Typography>
                </div>
                <Divider />
                <div>
                  <Typography className={classes.text} variant="subtitle1" gutterBottom>
                  {response && (
                   <text>{t('table_header_permission')}: <b> {permissionLevel}</b></text>
                )
                }
                  </Typography>
                </div>


              </Paper>
          </Grid>
          <Grid item xs={10}>
          </Grid>
        </Grid>
      </div>

        );
    }

}

Account.propTypes = {
    token: PropTypes.object.isRequired,
    userId: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
}



export default withTranslation()(withStyles(useStyles, { withTheme: true })(Account));