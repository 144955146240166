/**
 * Export settings dialog
 * 
 * @author JHO 
 * Last changed: 12.01.2022
 * Changes: PBI-703 Cloned dialog from articles and removed unnecessary input
 */

import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import GetAppIcon from '@material-ui/icons/GetApp';
import * as constants from '../../../common/constants';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios';
import * as endpoints from '../../../service/endpoints';
 

//size of  dialog
const useStyles = makeStyles((theme) => ({
    dialog: {
        minHeight: '50vh',
        maxHeight: '70vh',
        padding: theme.spacing(2)
     },
     root: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '500px',
        },
        '& .MuiButtonBase-root': {
        },
      },
      formControl: {
        marginBottom: theme.spacing(2),
        minWidth: '200px'
      },
      button: {
        minWidth: '200px'
      },
      buttons : {
        position: 'absolute',
        right: theme.spacing(3),
        bottom: theme.spacing(3),
      }
}));

const ExportSettingsDialog = (props) => {
const classes = useStyles();
const { t } = useTranslation();

const config = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

const [templates, setTemplates] = React.useState(Object.keys(props.csvConfig && props.csvConfig.templates));
const [template, setTemplate] = React.useState(props.csvConfig && props.csvConfig.templates[props.csvConfig.active] ? props.csvConfig.active : "new");

const handleChangeTemplate = (event) => {
      setTemplate(event.target.value);
  };

const handleSubmit = (event) => {
    event.preventDefault();
    let csvConfig = props.csvConfig;
    csvConfig.active = template;
    axios.patch(endpoints.PARAMETERS, csvConfig, config)
            .then(response => {
              props.setCSVConfig(csvConfig);
              window.localStorage.setItem("csvConfig", JSON.stringify(csvConfig));
            })
            .catch(error => {
              console.log(error)
            })
    props.handleSubmit()
}

return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth={true} maxWidth={'md'} open={props.open} onClose={props.handleClose}>
        <Typography variant="h5" color="black" gutterBottom>
                        {t('csv_export_header')}
        </Typography>
        <form className={classes.root} onSubmit={handleSubmit}>
            <FormControl component="fieldset" className={classes.formControl}>
            <TextField
                select
                label={t('settings_data_template')}
                value={template}
                onChange={e => handleChangeTemplate(e)}
                variant="outlined"
            >

                {templates.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
                ))}
            </TextField>
            </FormControl>

            <div className={classes.buttons}>
                <Button className={classes.button} type="submit" variant="contained" color="secondary" textColor="white" startIcon={<GetAppIcon />}>
                {t('button_export')}
                </Button>
            </div>
        </form>
    </Dialog>
    );
};

export default ExportSettingsDialog;