/**
 * Measures component
 * 
 * @author JHO 
 * Last changed: 12.01.2022
 * Changes: PBI-703 Added download options to table
 */
import './Measures.css';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import * as endpoints from '../../service/endpoints';
import * as routes from '../../routing/routes';
import * as constants from '../../common/constants';
import * as azureService from '../../common/services/azureDownload';
import { convertLength, convertVolume, convertWeight, formatTimestamp, checkError401, reformatDate} from '../../common/helpers'
import React, { useState, useEffect } from 'react';
import CustomToolbarSelect from "./table/CustomToolbarSelect";
import ExportSettingsDialog from './export/ExportSettingsDialog';
import ArticleInfo from "../measures/articleInfo/ArticleInfo";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import EditMeasureDialog from './edit/EditMeasureDialog';
import ShowImagesDialog from './images/ShowImagesDialog';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomToolbar from "./CustomToolbar";
import Badge from '@material-ui/core/Badge';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import { MultiSelect } from "react-multi-select-component";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Chip from '@material-ui/core/Chip';
import {format, formatDistance, formatRelative, isBefore, isValid, subDays} from 'date-fns'
import {
  FormGroup,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import Avatar from "@material-ui/core/Avatar";
import fileDownload from 'js-file-download';
import { buildCSVContent } from '../../common/csv/export'
import {Importer, ImporterField} from "react-csv-importer";
import {MEASURES} from "../../service/endpoints";
import withAppInsights from './../../common/appInsights'
import ConfirmDialog from "../confirm/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '10vh',
    maxHeight: '90vh',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  dialog: {
    minHeight: '30vh',
    maxHeight: '90vh',
    padding: theme.spacing(2),
    alignItems: 'left'
  },
  cell: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  },
  bread: {
    marginBottom: theme.spacing(1)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline'
    }
  },
  MSelect :
  {
    fontFamily: "Roboto",
    marginTop: "10px"
  }
}));

const importerStyle = {
  color: 'blue',
  textAlign: 'center'
};

function Measures(props) {
  //usages
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  //state
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [updated, setUpdated] = useState(0);
  const [promises, setPromises] = useState([]);
  const [openHistory, setOpenHistory] = useState(false);
  const [historyRequest, setHistoryRequest] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [measure, setMeasure] = useState({});
  const [allMeasures, setAllMeasures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [measuresLoading, setMeasuresLoading] = useState(false);
  const [imagesRequest, setImagesRequest] = useState(false);
  const [importDialog, setImportDialog] = useState((window.location.href.indexOf("import") > -1));
  const [openExport, setOpenExport] = useState((window.location.href.indexOf("export") > -1));
  const [colData, setColData] = useState();
  const [cellMeta, setCellMeta] = useState([]);
  const [openArticleInfo, setOpenArticleInfo] = useState(false);
  const [articleInfoRequest, setArticleInfoRequest] = useState(false);
  const [selectedExportData, setSelectedExportData] = useState([]);
  const [ok, setOK] = useState(0);
  const [selectedUser, setSelectedUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedSite, setSelectedSite] = useState([]);
  const [sites, setSites] = useState([]);
  const [error, setError] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [dialogState, setDialogState] = React.useState({
    "openImages": false,
    "imageSteps": [],
    "loaded": false
  })
  const [imagesDialogState, setImagesDialogState] = React.useState({
    "openImages": false,
    "imageSteps": [],
    "loaded": false
  })

  //configs
  const httpConfig = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  useEffect(() => {
    window.sessionStorage.setItem("lastRoute", routes.MEASURES);
  });

  useEffect(() => {
    getUsers()
    getSites()
  }, []);

  const httpConfigImages = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  const handleCloseArticleInfo = () => {
    setOpenArticleInfo(false)
    setArticleInfoRequest(false)
    setImagesDialogState({
      "openImages": false,
      "imageSteps": [],
      "loaded": false
    })
  };

  const handleConfirmDelete = (selectedRows) => {
    setConfirmDialogOpen(true)
  }

  const handleCloseConfirm = () => {
    setConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    handleCloseConfirm()
  }

  const handleConfirm = (selectedRows) => {
    handleDelete(selectedRows)
    handleCloseConfirm()
  }

  const handleOnComplete = () => {
    showSnackbar();
  }

  const handleClick = () => {
    openImportDialog();
  }

  const handleClose = () => {
    setImportDialog(false);
    setOpenHistory(false);
    setHistoryRequest(false);
    //history.push('/menu/measures');
  };

  const openImportDialog = () => {
    setImportDialog(true)
  }

  const handleCloseImport = () => {
    axios.get(endpoints.MEASURES + "?size=100000", httpConfig)
        .then(response => {
          setData(response.data.data);
          handleClose();
        })
        .catch(error => {
          console.log(error);
          checkError401(error);
        })
  };

  const showSnackbar = () => {
    if(ok > 0){
      enqueueSnackbar(t('notification_import_rows_ok') + ok, {
        variant: 'success',
        autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
      });}
    if(updated > 0){
      enqueueSnackbar(t('notification_import_rows_updated') + updated, {
        variant: 'info',
        autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
      });}
    if(error > 0){
      enqueueSnackbar(t('notification_import_rows_fail') + error, {
        variant: 'error',
        autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
      });}
  }

  const handleSuccessImage = (flag) => {
    let notification = 'notification_success_article_update';
    if(flag === 'upload_image')
      notification = 'notification_success_image_add'
    else if(flag === 'delete_image')
      notification = 'notification_success_image_delete'
    enqueueSnackbar(t(notification), {
      variant: 'success',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  //Dialogs state handler
  const handleSuccessEdit = () => {
    //update data in table
    axios.get(endpoints.MEASURES + "?size=100000",httpConfig)
       .then(response => {
         setData(response.data.data);
       })
       .catch(error => {
         console.log(error);
         checkError401(error);
    })
    enqueueSnackbar(t('notification_success_measure_update'), {
      variant: 'success',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const handleError = () => {
    enqueueSnackbar(t('notification_error_general'), {
      variant: 'error'
    });
  }

  // CSV methods
  const saveCsvStringAsFile = (csvString, fileName) => {
    const url = window.URL.createObjectURL(new Blob([csvString]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    refreshTable();
  };

  const handleCloseExport = () => {
    setOpenExport(false);
    //history.push('/menu/measures');
  };

  const handleSubmitExport = async () => {
    setOpenExport(false);
    setLoading(true);
    axios.get(endpoints.IMAGE_EXPORT, httpConfig)
        .then(async (response) => {
            const sasUrl = response.data['sasUrl'];
            if (selectedExportData.length < data.length) {
              await azureService.connectAndDownloadContainer(sasUrl,false,selectedExportData);
            } else {
              await azureService.connectAndDownloadContainer(sasUrl,true,null);
            }
            saveCsvStringAsFile(buildCSVContent(columns, selectedExportData, false, false, props, t, httpConfig, true), props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].filename : 'export.csv')
            setLoading(false);
    });
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
    axios.get(endpoints.MEASURES + "?size=100000",httpConfig)
       .then(response => {
         setData(response.data.data);
       })
       .catch(error => {
        checkError401(error);
       })
  };

  const handleCloseImages = () => {
    setDialogState({
      "openImages": false,
      "imageSteps": [],
      "loaded": false
    })
  };

  const handleDelete = (selectedRows) => {
    const indexesToDelete = selectedRows.data.map((row, k) => row.dataIndex);
    let temp = [];
    let deleteData = [];
    for (var i = 0; i < data.length; i++) {
      if (!indexesToDelete.includes(i)) {
        temp.push(data[i]);
      }
      else {
        deleteData.push(data[i]);
      }
    }
    setData(temp);
    if(deleteData.length > 0)
      deleteMeasuresById(deleteData,0);
  }

  const handleClickRefresh = () => {
    refreshTable();
  }

  const getMeasures = (measureID) => {
    setLoading(true)
    let images = []
    axios.get(endpoints.MEASURES + '/' + measureID, httpConfig)
        .then(response => {
          setMeasure(response.data)
          loadImagesArticle([response.data], 0, images)
        })

        .catch(error => {
          setLoading(false)
          enqueueSnackbar(t('notification_error_general'), {
            variant: 'error',
            autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
          });
        })
  }



  const loadImagesArticle = (data, idx, images) => {
    if (data[idx].images.length > 0)
      getImageArticle(data[idx], 0, images, data, idx)
    else {
      if (idx < data.length - 1) {
        loadImagesArticle(data, idx + 1, images)
      } else {
        if(images.length > 0)
        {
          setImagesDialogState({
            "openImages": true,
            "imageSteps": images,
            "loaded": true
          })
          setLoading(false)
        }
        else {
          setLoading(false)
        }
      }
    }
  }

  const getImageArticle = (measure, index, images, data, idx) => {
    let imageNr = measure.images[index].imageNumber
    axios.get(endpoints.IMAGES + '/' + measure.id + '/' + imageNr, httpConfigImages)
        .then(response => {
          let imageData = {
            path: measure.images[index].path,
            label: t('show_images_measure') + measure.id + " (" + measure.timeStamp + ")",
            img: response.data.imagePath,
            imageNumber: imageNr,
            measureID: measure.id
          }
          images.push(imageData);
          if (index < measure.images.length - 1)
            getImageArticle(measure, index + 1, images, data, idx)
          else {
            if (idx < data.length - 1) {
              loadImagesArticle(data, idx + 1, images)
            } else {
              setImagesDialogState({
                "openImages": true,
                "imageSteps": images,
                "loaded": true
              })
              setLoading(false)
            }
          }
        })
        .catch(error => {
          console.log(error);
          setLoading(false)
          enqueueSnackbar(t('notification_error_load_images'), {
            variant: 'error',
            autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
          });
        })
  }

//csv download
const handleDownload = (selectedRows) => {
  let selectedData = []
    for (let row of selectedRows) {
      selectedData.push(data[row.dataIndex])
    }
    setSelectedExportData(selectedData);
    setOpenExport(true);
}

const handleDownloadAll = () => {
  setSelectedExportData(data);
  setOpenExport(true);
}

//api images calls
const loadImages = (data) => {
  setLoading(true)
  let images = []
  if(data.images.length > 0)
      getImage(data,0,images)
  else {
    setLoading(false)
    enqueueSnackbar(t('notification_info_images_not_found_measure'), {
      variant: 'info',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }
}

const getImage = (measure, index, images) => {
    let imageNr = measure.images[index].imageNumber
    axios.get(endpoints.IMAGES + '/' + measure.id + '/' + imageNr, httpConfigImages)
    .then(response => {
        let imageData = {
            path: measure.images[index].path,
            label: '#' + imageNr,
            img: response.data.imagePath,
            imageNumber: measure.images[index].imageNumber,
            measureID: measure.id
        }
        images.push(imageData);
        if(index < measure.images.length - 1)
            getImage(measure, index + 1, images)
        else {
          setDialogState({
            "openImages": true,
            "imageSteps": images,
            "loaded": true
          })
          setLoading(false)
        }
    })
    .catch(error => {
        setLoading(false)
        enqueueSnackbar(t('notification_error_load_images'), {
          variant: 'error',
          autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
        });
        checkError401(error);
    })
  }
  const deleteMeasuresById = (data,idx) => {
    axios.delete(endpoints.MEASURES + '/' + data[idx].id,httpConfig)
          .then(response => {
            if(idx < (data.length -1)) {
              deleteMeasuresById(data, idx + 1);
            }
            else {
              axios.get(endpoints.MEASURES + "?size=100000",httpConfig)
                .then(response => {
                  setData(response.data.data);
                })
                .catch(error => {
                  handleError();
                  checkError401(error);
                })
                enqueueSnackbar(t('notification_success_measure_delete'), {
                  variant: 'info',
                  autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
                });
              }
          })
          .catch(error => {
            handleError();
          })
  }

  const refreshTable = () => {
    setMeasuresLoading(true);
    getMeasuresByPageAndSize(1,50000,[]);
  }

  const getMeasuresByPageAndSize = (page,size,data) => {
    axios.get(endpoints.MEASURES + "?size=" + size + "&page=" + page,httpConfig)
        .then(response => {
          let newData = data.concat(response.data.data)
          allMeasures.push(newData)
          setMeasuresLoading(false)
          if(response.data.totalPages > response.data.pageNumber)
            getMeasuresByPageAndSize(page+1,size,newData);
          else {
            setData(newData)
          }
        })
        .catch(error => {
          console.log(error);
          setMeasuresLoading(false);
          checkError401(error);
        })

  }

  const getUsers = () => {
    axios.get(endpoints.USERS,httpConfig)
      .then(response => {
        let userResponse = response.data.data
        let options = []
        for(let user of userResponse) {
          options.push({label: user.userName, value: user.userName})
        }
        setUsers(options);
      })
      .catch(error => {
        checkError401(error);
      })
  }

  const getSites = () => {
    axios.get(endpoints.SITES,httpConfig)
      .then(response => {
        let sitesResponse = response.data.data
        let options = []
        for(let site of sitesResponse) {
          options.push({label: site.name, value: site.name})
        }
        setSites(options);
      })
      .catch(error => {
        checkError401(error);
      })
  }


  //Datatable settings
  let columns = [
    {
      name: "id",
      label: t('table_header_id'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Measure ID") ? true : 'excluded',
      }
    },
    {
      name: "articleID",
      label: t('table_header_articleID'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Article ID") ? true : 'excluded',
        //download: props.csvConfig && props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['articleID'] : false,
        setCellProps: () => {
          return {
            className: classes.cell
            }
          }
        }
    },
    {
    name: "bundle.handlingUnit",
      label: t('table_header_hu'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Handling unit") ? true : 'excluded',
      }
    },
    {
      name: "bundle.itemsPerHU",
      label: t('table_header_pieces'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Items per HU") ? true : 'excluded',
      }
    },
    {
      name: "barcode.barcode",
      label: t('table_header_barcode'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Barcode") ? true : 'excluded',
        //download: props.csvConfig && props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['barcode'] : false
      }
    },
    {
      name: "bundleID",
      label: t('table_header_bundleID'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Bundle ID") ? true : 'excluded',
        //download: props.csvConfig &&  props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['bundleID'] : false
      }
    },
    {
      name: "length",
      label: t('table_header_length'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Length") ? true : 'excluded',
        //download: props.csvConfig && props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['length'] : false,
        customBodyRenderLite: dataIndex => convertLength(data[dataIndex]["length"],props.userSettings ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings && props.userSettings.length_unit  ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT)
      }
    },
    {
      name: "width",
      label: t('table_header_width'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Width") ? true : 'excluded',
        //download: props.csvConfig && props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['width'] : false,
        customBodyRenderLite: dataIndex => convertLength(data[dataIndex]["width"],props.userSettings ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings && props.userSettings.length_unit ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT)
      }
    },
    {
      name: "height",
      label: t('table_header_height'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Height") ? true : 'excluded',
        //download: props.csvConfig && props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['height'] : false,
        customBodyRenderLite: dataIndex => convertLength(data[dataIndex]["height"],props.userSettings ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings && props.userSettings.length_unit  ? props.userSettings.length_unit : constants.LENGTH_UNIT_DEFAULT)
      }
    },
    {
      name: "weight",
      label: t('table_header_weight'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Weight") ? true : 'excluded',
        //download: props.csvConfig && props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['weight'] : false,
        customBodyRenderLite: dataIndex => convertWeight(data[dataIndex]["weight"],props.userSettings ? props.userSettings.weight_unit : constants.WEIGHT_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings && props.userSettings.weight_unit  ? props.userSettings.weight_unit : constants.WEIGHT_UNIT_DEFAULT)
      }
    },
    {
      name: "volume",
      label: t('table_header_volume'),
      options: {
        filter: false,
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Volume") ? true : 'excluded',
        //download: props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['volume'] : false,
        customBodyRenderLite: dataIndex => <p>{convertVolume(data[dataIndex]["volume"],props.userSettings ? props.userSettings.volume_unit : constants.VOLUME_UNIT_DEFAULT, props.userSettings ? props.userSettings.decimal_places : constants.DECIMAL_PLACES_DEFAULT) + ' ' + (props.userSettings && props.userSettings.volume_unit ? props.userSettings.volume_unit : constants.VOLUME_UNIT_DEFAULT)}<sup>3</sup></p>
      }
    },
    {
      name: "site.name",
      label: t('table_header_site'),
      options: {
        filter: props.portalSettings && props.portalSettings.measure_filters && props.portalSettings.measure_filters.includes("Site"),
        sort: true,
        filterType: 'custom',
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Site") ? true : 'excluded',
        //download: props.csvConfig && props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['site'] : false,
        filterOptions: {
          names: [],
          logic(site, filterVal) {
            if(filterVal?.length > 0)
            {
              for(let val of filterVal)
              {
                if(site === val)
                  return false
              }
              return true
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>{t("table_header_site")}</FormLabel>
              <MultiSelect
                className={classes.MSelect}
                options={sites}
                value={selectedSite}
                onChange={(value) => {
                  let temp = []
                  for (let [i,item] of value.entries())
                  {
                    temp[i] = item.value
                  }
                  filterList[index] = temp
                  setSelectedSite(value)
                  onChange(filterList[index], index, column)
                }}
                labelledBy="Select"
              />
            </div>
          )
        },
        customFilterListOptions: {
          render: value => {  return value === undefined || value === "" ? t('filter_unknown') : value}
        }
      }
    },
    {
      name: "user.userName",
      label: t('table_header_user'),
      options: {
        filter: props.portalSettings && props.portalSettings.measure_filters && props.portalSettings.measure_filters.includes("User"),
        sort: true,
        filterType: 'custom',
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("User") ? true : 'excluded',
        //download: props.csvConfig && props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['user'] : false,
          filterOptions: {
          logic(user, filterVal) {
            if(filterVal?.length > 0)
            {
              for(let val of filterVal)
              {
                if(user === val)
                  return false
              }
              return true
            }
            return false;
          },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>{t("table_header_user")}</FormLabel>
                  <MultiSelect
                    className={classes.MSelect}
                    options={users}
                    value={selectedUser}
                    onChange={(value) => {
                      let temp = []
                      for (let [i,item] of value.entries())
                      {
                        temp[i] = item.value
                      }
                      filterList[index] = temp
                      setSelectedUser(value)
                      onChange(filterList[index], index, column)
                    }}
                    labelledBy="Select"
                  />
              </div>
            )
          }
        },
        customFilterListOptions: {
          render: value => {  return value === "" || value === undefined ? t('filter_unknown') : value}
        }
    },

    {
      name: "timeStamp",
      label: t('table_header_timestamp'),
      options: {
        filter: props.portalSettings && props.portalSettings.measure_filters && props.portalSettings.measure_filters.includes("Measure date"),
        sort: true,
        filterType: 'custom',
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Measure date") ? true : 'excluded',
        //download:props.csvConfig && props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['timeStamp'] : false,
        customBodyRenderLite: (dataIndex) => formatTimestamp(data[dataIndex]["timeStamp"],props.userSettings ? props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT,t('locale')),
        customFilterListOptions: {
          render: dates => {
            if(dates[0] && !dates[1])  //only start date entered by user
            {
              if(isValid(dates[0]))
                return t("filter_start_date") + ": " + format(dates[0], "dd-MM-yyyy");
            }
            if(!dates[0] && dates[1])  //only start date entered by user
            {
              if(isValid(dates[1]))
                return t("filter_end_date") + ": " + format(dates[1], "dd-MM-yyyy");
            }
            if(dates[0] && dates[1])  //only start date entered by user
            {
              if(isValid(dates[0]) && isValid(dates[1]))
                return [t("filter_start_date") + ": " + format(dates[0], "dd-MM-yyyy"), t("filter_end_date") + ": " + format(dates[1], "dd-MM-yyyy")];
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(timeStamp, filters) {
            let currentDate = new Date(timeStamp);
            if(filters[0] && !filters[1])  //only start date entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[0]))
                return !isBefore(filters[0], currentDate);
            }
            if(!filters[0] && filters[1])  //only end date entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[1]))
                return !isBefore(currentDate, filters[1]);
            }
            if(filters[0] && filters[1])  //both dates entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[0]) && isValid(filters[1]))
                return !(isBefore(filters[0], currentDate) && isBefore(currentDate, filters[1]));
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>{t("settings_measures")}</FormLabel>
                  <FormGroup>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                          label={t("filter_from")}
                          value={filterList[index][0] === undefined ? null: filterList[index][0]}
                          onChange={(value) => {
                            filterList[index][0] = value;
                            if(isValid(filterList[index][0]))
                              filterList[index][0].setHours(0,0,0,0);
                            onChange(filterList[index], index, column)
                          }}
                          renderInput={(params) => (
                              <TextField {...params} style = {{maxWidth: 250}}/>
                          )}
                          inputFormat="dd-MM-yyyy"
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                          label={t("filter_to")}
                          value={filterList[index][1] === undefined ? null: filterList[index][1]}
                          onChange={(value) => {
                            filterList[index][1] = value;
                            if(isValid(filterList[index][1])) {
                              filterList[index][1].setHours(23,59,59,59);
                            }
                            onChange(filterList[index], index, column)
                          }}
                          renderInput={(params) => (
                              <TextField {...params} style = {{maxWidth: 250}}/>
                          )}
                          inputFormat="dd-MM-yyyy"
                      />
                    </LocalizationProvider>
                  </FormGroup>
              </div>
          )
        }
      }
    },
    {
      name: "updatedAt",
      label: t('table_header_modified'),
      options: {
        filter: props.portalSettings && props.portalSettings.measure_filters && props.portalSettings.measure_filters.includes("Last modified"),
        filterType: "custom",
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Last modified") ? true : 'excluded',
        //download: props.csvConfig && props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['Last modified'] : false,
        customBodyRenderLite: (dataIndex) => formatTimestamp(data[dataIndex]["updatedAt"],props.userSettings ? props.userSettings.date_format : constants.DATE_FORMAT_DEFAULT,t('locale')),
        customFilterListOptions: {
          render: dates => {
            if(dates[0] && !dates[1])  //only start date entered by user
            {
              if(isValid(dates[0]))
                return t("filter_start_date_modified") + ": " + format(dates[0], "dd-MM-yyyy");
            }
            if(!dates[0] && dates[1])  //only start date entered by user
            {
              if(isValid(dates[1]))
                return t("filter_end_date_modified") + ": " + format(dates[1], "dd-MM-yyyy");
            }
            if(dates[0] && dates[1])  //only start date entered by user
            {
              if(isValid(dates[0]) && isValid(dates[1]))
                return [t("filter_start_date_modified") + ": " + format(dates[0], "dd-MM-yyyy"), t("filter_end_date_modified") + ": " + format(dates[1], "dd-MM-yyyy")];
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(updatedAt, filters) {
            let currentDate = new Date(updatedAt);
            if(filters[0] && !filters[1])  //only start date entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[0]))
                return !isBefore(filters[0], currentDate);
            }
            if(!filters[0] && filters[1])  //only end date entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[1]))
                return !isBefore(currentDate, filters[1]);
            }
            if(filters[0] && filters[1])  //both dates entered by user
            {
              if(!isValid(currentDate))
                return true;
              if(isValid(filters[0]) && isValid(filters[1]))
                return !(isBefore(filters[0], currentDate) && isBefore(currentDate, filters[1]));
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>{t("table_header_modified")}</FormLabel>
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        error={false}
                        label={t("filter_from")}
                        value={filterList[index][0] === undefined ? null: filterList[index][0]}
                        onChange={(value) => {
                          filterList[index][0] = value;
                          if(isValid(filterList[index][0]))
                            filterList[index][0].setHours(0,0,0,0);
                          onChange(filterList[index], index, column)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} style = {{maxWidth: 250}}/>
                        )}
                        inputFormat="dd-MM-yyyy"
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        error={false}
                        label={t("filter_to")}
                        value={filterList[index][1] === undefined ? null: filterList[index][1]}
                        onChange={(value) => {
                          filterList[index][1] = value;
                          if(isValid(filterList[index][1])) {
                            filterList[index][1].setHours(23,59,59,59);
                          }
                          onChange(filterList[index], index, column)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} style = {{maxWidth: 250}}/>
                        )}
                        inputFormat="dd-MM-yyyy"
                    />
                  </LocalizationProvider>
                </FormGroup>
              </div>
          )
        }
      }
    },
    {
      name: "exported",
      label: t('table_header_exported'),
      options: {
        filter: props.portalSettings && props.portalSettings.measure_filters && props.portalSettings.measure_filters.includes("Exported"),
        sort: true,
        display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Exported") ? true : 'excluded',
        customBodyRenderLite: (dataIndex) => {  return data[dataIndex]["exported"] ? <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_yes')}</span><CheckIcon className="export" style={{fill: "green"}}></CheckIcon></div> : <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_no')}</span><ClearIcon className="export" style={{fill: "red"}}></ClearIcon></div> },
        filterOptions: {
          renderValue: value => {  return value ? <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_yes')}</span><CheckIcon className="export" style={{fill: "green"}}></CheckIcon></div> : <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_no')}</span><ClearIcon className="export" style={{fill: "red"}}></ClearIcon></div> }
        },
        customFilterListOptions: {
          render: value => {  return value ? <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_yes')}</span><CheckIcon className="export" style={{fill: "green"}}></CheckIcon></div> : <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><span>{t('table_text_export_no')}</span><ClearIcon className="export" style={{fill: "red"}}></ClearIcon></div> }
        }
      }
    },
    {
      name: "images",
      label: t('table_header_images'),
      options: {
        filter: false,
        sort: false,
        //download: props.csvConfig && props.csvConfig.active !== undefined ? props.csvConfig.templates[props.csvConfig.active].fields['imagePaths'] : false,
        display: "excluded"
      }
    },
    {
      name: "numberOfImages",
      label: t('table_header_images'),
      options: {
        filter: props.portalSettings && props.portalSettings.measure_filters && props.portalSettings.measure_filters.includes("Images"),
        sort: false,
        //download: props.csvConfig ? props.csvConfig.fields['imagePaths'] : false,
        display: "excluded",
        filterOptions: {
          names: [t('filter_picture_available_true'), t('filter_picture_available_false')],
          logic(numberOfImages, filterVal) {
            const show =
              (filterVal.indexOf(t('filter_picture_available_true')) >= 0 && numberOfImages > 0) ||
              (filterVal.indexOf(t('filter_picture_available_false')) >= 0 && (numberOfImages === 0 || numberOfImages === null));
            return !show;
          }
        }
      }
    },

    {
    name: "images",
    label: t('table_header_images'),
    options: {
      filter: false,
      sort: true,
      download: false,
      viewColumns: false,
      empty: true,
      display: props.portalSettings && props.portalSettings.measure_columns && props.portalSettings.measure_columns.includes("Images") ? true : 'excluded',
      sortCompare: (order) =>
      ({ data: item1 }, { data: item2 }) => {
        if (item1.length === 0)
          return order === 'asc' ? -1 : 1;
        else if (item2.length === 0)
          return order === 'asc' ? 1 : -1;
        else
          return (order === 'asc' ? (item1.length >= item2.length ? 1 : -1) : (item1.length < item2.length ? 1 :-1));
      },
      customBodyRenderLite: (dataIndex) => {
        return data[dataIndex]["images"].length > 0 ? (
            <IconButton color="primary" aria-label="add an alarm" onClick={() => {setMeasure({...data[dataIndex]}); loadImages({...data[dataIndex]}); }}>
              <Badge badgeContent={data[dataIndex]["images"].length} color="error">
                <PhotoLibraryIcon />
              </Badge>
            </IconButton>
          ) : <RemoveIcon />;
        }
      }
    },
    {
    name: "",
    label: "",
    options: {
      filter: false,
      sort: false,
      download: false,
      viewColumns: false,
      empty: true,
      display: props.permissionSettings && props.permissionLevel >= props.permissionSettings.measures.edit ? true : 'excluded',
      customBodyRenderLite: (dataIndex) => {
        return (
          <IconButton color="primary" aria-label="add an alarm" onClick={() => {setMeasure({...data[dataIndex]}); setOpenEdit(true)}}>
            <EditIcon />
          </IconButton>
          );
        }
      }
    }
  ];

  const options = {
    filterType: 'checkbox',
    print: false,
    download: false,
    enableNestedDataAccess: '.',
    jumpToPage: true,
    rowsPerPageOptions: [10,20,50],
    sortOrder: {
      name: 'timeStamp',
      direction: 'desc'
    },
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      let count = 0
      currentRow.forEach(col => {
        count += 1
        if(count === 2)
        {
          let m = allMeasures[0].find(el => el.articleID === col)
          let article = m["article"]
          for (let key in article) {
            if (article.hasOwnProperty(key)) {
              if(article[key] !== null && article[key].toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0){
                isFound = true;
              }
            }
          }
        }
        if(col !== undefined && col !== null)
        {
          if(col.toString().indexOf(searchQuery) >= 0){
            isFound = true;
          }
        }
      })
      return isFound;
    },
    setFilterChipProps: (colIndex, colName, data) => {
      if(colName === "timeStamp")
      {
        return {
          variant: "outlined"
        };
      }
      else if(colName === "updatedAt")
      {
        return {

        };
      }
    },
    onCellClick: (colData, cellMeta) => {
      if(cellMeta.colIndex === 1)
      {
        let measure = data[cellMeta.dataIndex]
        setColData(measure);
        setCellMeta(cellMeta);
        getMeasures(measure.id);
        setOpenArticleInfo(true);
      }
    },
    onTableInit: (action, tableState) => {
      refreshTable();
    },
    textLabels: {
      body: {
          noMatch: measuresLoading ?
          <CircularProgress /> :
              t('table_no_data'),
          toolTip: t('table_body_toolTip'),    
      },
      selectedRows: {
        text: t('table_row_selected'),
        delete: t('table_row_delete'),
        deleteAria: t('table_row_deleteAria'),
      }, 
      pagination: {
        next: t('table_pagination_next'),
        previous: t('table_pagination_previous'),
        rowsPerPage: t('table_pagination_rowsPerPage'),
        displayRows: t('table_pagination_displayRows'),
        jumpToPage: t('table_pagination_jumpToPage'),
      },
      toolbar: {
        search: t('table_toolbar_search'),
        downloadCsv: t('table_toolbar_downloadCsv'),
        print: t('table_toolbar_print'),
        viewColumns: t('table_toolbar_viewColumns'),
        filterTable: t('table_toolbar_filterTable'),
      },
      filter: {
        all: t('table_filter_all'),
        title: t('table_filter_title'),
        reset: t('table_filter_reset'),
      },
      viewColumns: {
        title: t('table_viewColumns_title'),
        titleAria: t('table_viewColumns_titleAria'),
      },
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} onRowsDelete={handleConfirmDelete} handleDownload={handleDownload} permissionSettings={props.permissionSettings} permissionLevel={props.permissionLevel} open={confirmDialogOpen} handleCancel={handleCancel} handleConfirm={handleConfirm}/>
    ),
    customToolbar: () => {
      return (
        <CustomToolbar action={handleClick} actionRefresh={handleClickRefresh} permissionSettings={props.permissionSettings} permissionLevel={props.permissionLevel} handleDownload={handleDownloadAll} />
      );
    }
  };
  return (
    <div className="Measures">
      <Breadcrumbs aria-label="breadcrumb" className={classes.bread}>
        <Link to={routes.DASHBOARD} className={classes.link} onClick={() => {
          props.setSelectedItem(1)
          props.setBold(1)
        }}>
          <HomeIcon className={classes.icon} />
        </Link>
        <Link to={routes.MEASURES} className={classes.link}>
        {t('component_header_measures')}
        </Link>
    </Breadcrumbs>
      <MUIDataTable
        title={""}
        data={data}
        columns={columns}
        options={options}
      />
      <Dialog
          classes={{ paper: classes.dialogPaper }}
          fullWidth={true}
          maxWidth={'xl'}
          open={(importDialog && !measuresLoading)}
          onClose={handleClose}
          aria-labelledby="dialog-title"
      >
        <DialogTitle id="importDialog-title">{t("dialog_import")}</DialogTitle>
        <DialogContent >
          <div className="CSVImporter_FormatPreview__header">
            {( // hide setting if only one line anyway
                <label className="CSVImporter_FormatPreview__updateToggle">
                  <input
                      type="checkbox"
                      checked={update}
                      onChange={() => {
                        setUpdate(!update);
                      }}
                  />
                  <span>{t("dialog_import_update")}</span>
                </label>
            )}
          </div>
          <Importer
              chunkSize={50000} // optional, internal parsing chunk size in bytes
              assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
              restartable={true} // optional, lets user choose to upload another file when import is complete
              onStart={({ file, fields, columns, skipHeaders }) => {
                setOK(0);
                setUpdated(0);
                setError(0);
              }}

              processChunk={async (rows, { startIndex }) => {
                // required, receives a list of parsed objects based on defined fields and user column mapping;
                // may be called several times if file is large
                // (if this callback returns a promise, the widget will wait for it before parsing more data)
                // for (row of rows) {
                //   //await myAppMethod(row);
                // }
                promises.push(new Promise((resolve, reject) => {
                  rows.forEach(item => {
                    item.source = "Portal import"
                    axios.post(endpoints.MEASURES, item, httpConfig)
                        .then(response => {
                          setOK(prevOk => ++prevOk)
                          //TODO handle in API?:
                          //setOK(prevOk => prevOk + response.data.imported)
                          //setUpdated(prevUpdated => prevUpdated + response.data.updated)
                          //setError(prevError => prevError + response.data.failed)
                          resolve();
                        })
                        .catch(err => {
                          setError(prevError => ++prevError)  //TODO handle in API?
                          resolve();
                        })
                  })
                  //TODO: data format not implemented -> every row single call, should be one call with whole data like in articles.js
                  //console.log({ "data": rows })
                  // axios.post(endpoints.MEASURES + "?update=" + update, { "data": rows }, httpConfig) //data not implemented
                  //     .then(response => {
                  //       setOK(prevOk => prevOk + response.data.imported)
                  //       setUpdated(prevUpdated => prevUpdated + response.data.updated)
                  //       setError(prevError => prevError + response.data.failed)
                  //       resolve();
                  //     })
                  //     .catch(err => {
                  //       resolve();
                  //     })
                }))
              }}
              onComplete={({ file, preview, fields, columnFields }) => {
              }}
              onClose={async ({ file, preview, fields, columnFields }) => {
                setLoading(true);
                await Promise.all(promises).then(result => {
                  handleOnComplete();
                  handleCloseImport();
                  setLoading(false);
                });

              }}

              // CSV options passed directly to PapaParse if specified:
              // delimiter={...}
              // newline={...}
              // quoteChar={...}
              // escapeChar={...}
              // comments={...}
              // skipEmptyLines={...}
              // delimitersToGuess={...}
          >
            <ImporterField name="id" label={t("export_header_id")} optional/>
            <ImporterField name="articleID" label={t("export_header_articleID")} />
            <ImporterField name="length" label={t("export_header_length")} />
            <ImporterField name="width" label={t("export_header_width")} />
            <ImporterField name="height" label={t("export_header_height")} />
            <ImporterField name="weight" label={t("export_header_weight")} />
            <ImporterField name="volume" label={t("export_header_volume")} />
            <ImporterField name="user" label={t("export_header_user")} optional/>
            <ImporterField name="timeStamp" label={t("export_header_timestamp")} />
            <ImporterField name="exported" label={t("table_header_exported")} optional/>
            {props.customFields && props.customFields.custom_string_1.enabled && <ImporterField name="customString1" label={props.customFields.custom_string_1.name} optional/>}
            {props.customFields && props.customFields.custom_string_2.enabled && <ImporterField name="customString2" label={props.customFields.custom_string_2.name} optional/>}
            {props.customFields && props.customFields.custom_int_1.enabled && <ImporterField name="customInt1" label={props.customFields.custom_int_1.name} optional/>}
            {props.customFields && props.customFields.custom_int_2.enabled && <ImporterField name="customInt2" label={props.customFields.custom_int_2.name} optional/>}
            {props.customFields && props.customFields.custom_float.enabled && <ImporterField name="customFloat" label={props.customFields.custom_float.name} optional/>}
          </Importer>
        </DialogContent>
      </Dialog>
      <Dialog
          classes={{ paper: classes.dialogPaper }}
          fullWidth={true}
          maxWidth={'xl'}
          open={openArticleInfo}
          onClose={handleCloseArticleInfo}
          aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{t("dialog_article")} {"Info"}</DialogTitle>
        <DialogContent>
          <ArticleInfo measure={measure} request={articleInfoRequest} token={props.token}
                       userSettings={props.userSettings} imageRequest={imagesRequest} images={imagesDialogState.imageSteps}
                       loaded={imagesDialogState.loaded} handleSuccess={handleSuccessImage}
                       handleError={handleError} customFields={props.customFields}/>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ExportSettingsDialog open={(openExport && !measuresLoading)} handleClose={handleCloseExport} handleSubmit={handleSubmitExport} token={props.token} csvConfig={props.csvConfig} setCSVConfig={props.setCSVConfig}/>
      <EditMeasureDialog open={openEdit} handleClose={handleCloseEdit} handleSuccess={handleSuccessEdit} handleError={handleError}  token={props.token} measure={measure}/>
      <Dialog classes={{ paper: classes.dialog }} fullWidth={false} maxWidth={'md'} open={dialogState.openImages} onClose={handleCloseImages}>
        <Typography variant="h5" color="black" gutterBottom>
          {t('show_images_header')}
        </Typography>
        <ShowImagesDialog handleSuccess={handleSuccessEdit} handleError={handleError} handleClose={handleCloseImages} token={props.token} measure={measure} request={imagesRequest} images={dialogState.imageSteps} loaded={dialogState.loaded} location={"measureTable"}/>
      </Dialog>
      </div>
    );
}

export default withAppInsights(Measures);