/**
 * Settings permission category component
 * 
 * @author JHO 
 * Last changed: 18.10.2021
 * Changes: PBI-618 settings structure
 */


 import React from 'react';
 import { useTranslation } from "react-i18next";
 import { useSnackbar } from 'notistack';
 import Typography from '@material-ui/core/Typography';
 import Accordion from '@material-ui/core/Accordion';
 import AccordionSummary from '@material-ui/core/AccordionSummary';
 import AccordionDetails from '@material-ui/core/AccordionDetails';
 import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
 import InputLabel from '@material-ui/core/InputLabel';
 import MenuItem from '@material-ui/core/MenuItem';
 import FormControl from '@material-ui/core/FormControl';
 import Select from '@material-ui/core/Select';
 import SaveIcon from '@material-ui/icons/Save';
 import Button from '@material-ui/core/Button';
 import TextField from '@material-ui/core/TextField';
 import axios from 'axios';
 import * as endpoints from '../../../service/endpoints';
 import { makeStyles } from '@material-ui/core/styles';
 import * as constants from '../../../common/constants';
 
 const useStyles = makeStyles((theme) => ({
    header: {
      float: 'left',
      color: "black",
      textStyle: "bold",
      padding: theme.spacing(2)
    },
    page: {
      minHeight: '0',
      boxShadow: "5px 5px 30px -12px rgba(0,0,0,0.3)",
      "&:hover": {
        boxShadow: "0 10px 50px -12px rgba(0,0,0,0.3)"
      }
    },
    formControl: {
      minWidth: 250,
      marginRight: theme.spacing(2)
    },
    button: {
      float: 'right',
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    top: {
        overflow: "hidden"
    }
  }));
  
 function CategoryPermissions(props) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const httpConfig = {
        headers: { Authorization: `Bearer ${props.token}` }
    };

    //state
    const [userManagement, setUserManagement] = React.useState(props.permissionSettings ?  props.permissionSettings.user_management : '')
    const [deviceManagement, setDeviceManagement] = React.useState(props.permissionSettings ?  props.permissionSettings.device_management : '')
    const [editMeasures, setEditMeasures] = React.useState(props.permissionSettings ?  props.permissionSettings.measures.edit : '')
    const [deleteMeasures, setDeleteMeasures] = React.useState(props.permissionSettings ?  props.permissionSettings.measures.delete  : '')
    const [editArticles, setEditArticles] = React.useState(props.permissionSettings ?  props.permissionSettings.articles.edit  : '')
    const [deleteArticles, setDeleteArticles] = React.useState(props.permissionSettings ?  props.permissionSettings.articles.delete  : '')
    const [importPer, setImportPer] = React.useState(props.permissionSettings ?  props.permissionSettings.import : '')
    const [exportPer, setExportPer] = React.useState(props.permissionSettings ?  props.permissionSettings.export : '')
    const [vosAppSettings, setVosAppSettings] = React.useState(props.permissionSettings ?  props.permissionSettings.settings.vos_app : '')
    const [viewPlan, setViewPlan] = React.useState(props.permissionSettings ?  props.permissionSettings.planning.view : '')
    const [editPlan, setEditPlan] = React.useState(props.permissionSettings ?  props.permissionSettings.planning.edit : '')
    const [createPlan, setCreatePlan] = React.useState(props.permissionSettings ?  props.permissionSettings.planning.create : '')
    const [generalSettings, setGeneralSettings] = React.useState(props.permissionSettings ?  props.permissionSettings.settings.general : '')
    const [exportSettings, setExportSettings] = React.useState(props.permissionSettings ?  props.permissionSettings.settings.export : '')
    const [othersSettings, setOthersSettings] = React.useState(props.permissionSettings ?  props.permissionSettings.settings.others: '') 


    //select handlers
    const handleUserManagementChange = (event) => {
        setUserManagement(event.target.value);
    }
   const handleDeviceManagementChange = (event) => {
        setDeviceManagement(event.target.value);
   }
    const handleEditMeasuresChange = (event) => {
        setEditMeasures(event.target.value);
    }
    const handleDeleteMeasuresChange = (event) => {
        setDeleteMeasures(event.target.value);
    }
    const handleEditArticlesChange = (event) => {
        setEditArticles(event.target.value);
    }
    const handleDeleteArticlesChange = (event) => {
        setDeleteArticles(event.target.value);
    }
    const handleImportChange = (event) => {
        setImportPer(event.target.value);
    }
    const handleExportChange = (event) => {
        setExportPer(event.target.value);
    }
    const handleViewPlanChange = (event) => {
        setViewPlan(event.target.value);
    }
    const handleEditPlanChange = (event) => {
        setEditPlan(event.target.value);
    }
    const handleCreatePlanChange = (event) => {
        setCreatePlan(event.target.value);
    }
    const handleVosAppSettingsChange = (event) => {
        setVosAppSettings(event.target.value);
    }
    const handleGeneralSettingsChange = (event) => {
        setGeneralSettings(event.target.value);
    }
    const handleExportSettingsChange = (event) => {
        setExportSettings(event.target.value);
    }
    const handleOthersSettingsChange = (event) => {
        setOthersSettings(event.target.value);
    }

    //notifications
    const handleSuccess = () => {
        enqueueSnackbar(t('notification_success_update_permission_settings'), { 
        variant: 'success',
        autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
        });
    }

    const handleError = () => {
        enqueueSnackbar(t('notification_error_update_permission_settings'), { 
        variant: 'error',
        autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
        });
    }

    //TODO: Add permission for devices!
    const onSave = () => {
        //update parameters
        let permissionSettingsBody = {
            "user_management": userManagement,
            "device_management": deviceManagement,
            "measures": {
                "edit": editMeasures,
                "delete": deleteMeasures
            },
            "articles": {
                "edit": editArticles,
                "delete": deleteArticles
            },
            "import": importPer,
            "export": exportPer,
            "planning": {
                "view": viewPlan,
                "edit": editPlan,
                "create": createPlan
            },
            "settings": {
                "vos_app": vosAppSettings,
                "general": generalSettings,
                "export": exportSettings,
                "others": othersSettings
            }
        }

        axios.patch(endpoints.PARAMETERS,{"permissions":permissionSettingsBody},httpConfig)
        .then(response => {
            props.setPermissionSettings(permissionSettingsBody);
            window.localStorage.setItem("permissions",JSON.stringify(permissionSettingsBody))
            handleSuccess();
        })
        .catch(error => {
            handleError();
        })
    }
    
    return (
        <div className={classes.page}>
            <div className={classes.top}>
                <Typography className={classes.header} variant="h5" color="black" gutterBottom>
                    {t('settings_permissions')}  
                </Typography>
                <Button className={classes.button} onClick={onSave} variant="contained" color="secondary" textColor="white" startIcon={<SaveIcon />}>
                        {t('button_update')}
                </Button>
            </div>
            <Accordion defaultExpanded="true">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className={classes.heading}>{t('settings_user_management')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div>
        
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="min_um">{t('min_permission_level')}</InputLabel>
                    <Select
                    id="um"
                    label={t('min_permission_level')}
                    value={userManagement}
                    onChange={handleUserManagementChange}
                    >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                    </Select>
                </FormControl>
            
                </div>
                </AccordionDetails>
            </Accordion>

          <Accordion defaultExpanded="true">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>{t('settings_device_management')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>

                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="min_dm">{t('min_permission_level')}</InputLabel>
                  <Select
                    id="dm"
                    label={t('min_permission_level')}
                    value={deviceManagement}
                    onChange={handleDeviceManagementChange}
                  >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                  </Select>
                </FormControl>

              </div>
            </AccordionDetails>
          </Accordion>

            <Accordion defaultExpanded="true">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className={classes.heading}>{t('settings_measures')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div>

                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="min_measure_edit">{t('permission_edit')}</InputLabel>
                    <Select
                    id="measureEdit"
                    label={t('permission_edit')}
                    value={editMeasures}
                    onChange={handleEditMeasuresChange}
                    >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="min_measure_delete">{t('permission_delete')}</InputLabel>
                    <Select
                    id="measureDelete"
                    label={t('permission_delete')}
                    value={deleteMeasures}
                    onChange={handleDeleteMeasuresChange}
                    >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                    </Select>
                </FormControl>
                </div>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded="true">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className={classes.heading}>{t('settings_articles')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div>

                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="min_measure_edit">{t('permission_edit')}</InputLabel>
                    <Select
                    id="articlesEdit"
                    label={t('permission_edit')}
                    value={editArticles}
                    onChange={handleEditArticlesChange}
                    >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="min_measure_delete">{t('permission_delete')}</InputLabel>
                    <Select
                    id="articlesDelete"
                    label={t('permission_delete')}
                    value={deleteArticles}
                    onChange={handleDeleteArticlesChange}
                    >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                    </Select>
                </FormControl>
                </div>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded="true">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className={classes.heading}>{t('settings_import')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div>
        
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="min_um">{t('min_permission_level')}</InputLabel>
                    <Select
                    id="um"
                    label={t('min_permission_level')}
                    value={importPer}
                    onChange={handleImportChange}
                    >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                    </Select>
                </FormControl>
                </div>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded="true">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className={classes.heading}>{t('settings_export')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div>
        
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="min_um">{t('min_permission_level')}</InputLabel>
                    <Select
                    id="um"
                    label={t('min_permission_level')}
                    value={exportPer}
                    onChange={handleExportChange}
                    >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                    </Select>
                </FormControl>
                </div>
                </AccordionDetails>
            </Accordion>

          <Accordion defaultExpanded="true">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>{t('settings_planning')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="view">{t('settings_view')}</InputLabel>
                  <Select
                    id="viewPlan"
                    label={t('settings_view')}
                    value={viewPlan}
                    onChange={handleViewPlanChange}
                  >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                  </Select>
                </FormControl>

                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="edit">{t('settings_edit')}</InputLabel>
                  <Select
                    id="editPlan"
                    label={t('settings_edit')}
                    value={editPlan}
                    onChange={handleEditPlanChange}
                  >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                  </Select>
                </FormControl>

                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="create">{t('settings_create')}</InputLabel>
                  <Select
                    id="createPlan"
                    label={t('settings_create')}
                    value={createPlan}
                    onChange={handleCreatePlanChange}
                  >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </AccordionDetails>
          </Accordion>

            <Accordion defaultExpanded="true">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className={classes.heading}>{t('settings_settings')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div>

                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="vos">{t('settings_vos_app')}</InputLabel>
                    <Select
                    id="vosApp"
                    label={t('settings_vos_app')}
                    value={vosAppSettings}
                    onChange={handleVosAppSettingsChange}
                    >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="general">{t('settings_general')}</InputLabel>
                    <Select
                    id="generalSettings"
                    label={t('settings_general')}
                    value={generalSettings}
                    onChange={handleGeneralSettingsChange}
                    >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="export">{t('settings_export')}</InputLabel>
                    <Select
                    id="exportSettings"
                    label={t('settings_export')}
                    value={exportSettings}
                    onChange={handleExportSettingsChange}
                    >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="others">{t('settings_others')}</InputLabel>
                    <Select
                    id="othersSettings"
                    label={t('settings_others')}
                    value={othersSettings}
                    onChange={handleOthersSettingsChange}
                    >
                    <MenuItem value={1}>{t('permission_normal')}</MenuItem>
                    <MenuItem value={2}>{t('permission_key')}</MenuItem>
                    <MenuItem value={3}>{t('permission_admin')}</MenuItem>
                    </Select>
                </FormControl>
                </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
 }
 
 export default CategoryPermissions;