/**
 * Form for edit measure dialog
 * 
 * @author JHO 
 * Last changed: 11.10.2021
 * Changes: PBI-602 added measure value inputs units
 */

 import React, { useState } from 'react';
 import { makeStyles } from '@material-ui/core';
 import TextField from '@material-ui/core/TextField';
 import Button from '@material-ui/core/Button';
 import SaveIcon from '@material-ui/icons/Save';
 import axios from 'axios';
 import * as endpoints from '../../../service/endpoints';
 import { useTranslation } from 'react-i18next';
import {formatTimestamp} from "../../../common/helpers";
import InputAdornment from '@material-ui/core/InputAdornment';
 
 const useStyles = makeStyles(theme => ({
   root: {
     padding: theme.spacing(2),
 
     '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
     }
   },
    buttons : {
        position: 'absolute',
        right: theme.spacing(3),
        bottom: theme.spacing(3),
    }
 }));
 
 const EditMeasureForm = (props) => {
   const classes = useStyles();
   const { t } = useTranslation();
   const [articleID, setArticleID] = useState(props.measure.articleID);
   const [barcode, setBarcode] = useState(props.measure.barcode ? props.measure.barcode.barcode : '');
   const [hu, setHu] = useState(props.measure.bundle.handlingUnit);
   const [itemsPerHu, setItemsPerHu] = useState(props.measure.bundle.itemsPerHU);
   const [length, setLength] = useState(props.measure.length);
   const [width, setWidth] = useState(props.measure.width);
   const [height, setHeight] = useState(props.measure.height);
   const [weight, setWeight] = useState(props.measure.weight);
   const [volume, setVolume] = useState(props.measure.volume);
   const [site, setSite] = useState(props.measure.site ? props.measure.site.name : "");
   const [user, setUser] = useState(props.measure.user ? props.measure.user.userName : "");
   const [timestamp, setTimestamp] = useState(props.measure.timeStamp);
   const [modified, setModified] = useState(props.measure.updatedAt);

   const config = {
     headers: { Authorization: `Bearer ${props.token}` }
   };

   const handleSubmit = e => {
     e.preventDefault();
     const measureBody = {
       "articleID": articleID,
       "length": length,
       "width": width,
       "height": height,
       "weight": weight,
       "volume": volume,
       "bundle": {
           "handlingUnit": hu,
           "itemsPerHU": Number(itemsPerHu)
       }
     };

     axios.patch(endpoints.MEASURES + '/' + props.measure.id,measureBody,config)
     .then(response => {
         props.handleSuccess();
     })
     .catch(error => {
         props.handleError();
     })
     props.handleClose();
   };
 
   return (
     <form className={classes.root} onSubmit={handleSubmit}>
         <div>
       <TextField
         label={t('table_header_articleID')}
         variant="outlined"
         disabled
         value={articleID}
       />
       <TextField
         label={t('table_header_barcode')}
         variant="outlined"
         disabled
         value={barcode}
       />
       <TextField
         label={t('table_header_hu')}
         variant="outlined"
         type="text"
         value={hu}
         onChange={e => {setHu(e.target.value)}}
       />
       <TextField
         label={t('table_header_pieces')}
         variant="outlined"
         type="number"
         value={itemsPerHu}
         InputProps={{ inputProps: { min: 1 }}}
         onChange={e => {setItemsPerHu(e.target.value)}}
       />
       </div>
       <div>
       <TextField
         label={t('table_header_length')}
         variant="outlined"
         required
         type="number"
         value={length}
         InputProps={{ inputProps: { min: 1 }, endAdornment:<InputAdornment position="end">mm</InputAdornment>}}
         onChange={e => {setLength(e.target.value); setVolume(length*width*height)}}
       />
       <TextField
         label={t('table_header_width')}
         variant="outlined"
         required
         type="number"
         value={width}
         InputProps={{ inputProps: { min: 1}, endAdornment:<InputAdornment position="end">mm</InputAdornment>}}
         onChange={e => {setWidth(e.target.value); setVolume(length*width*height)}}
       />
       <TextField
         label={t('table_header_height')}
         variant="outlined"
         required
         type="number"
         value={height}
         InputProps={{ inputProps: { min: 1}, endAdornment:<InputAdornment position="end">mm</InputAdornment>}}
         onChange={e => {setHeight(e.target.value); setVolume(length*width*height)}}
       />
       <TextField
         label={t('table_header_weight')}
         variant="outlined"
         required
         type="number"
         value={weight}
         InputProps={{ inputProps: { min: 1}, endAdornment:<InputAdornment position="end">g</InputAdornment>}}
         onChange={e => {setWeight(e.target.value); setVolume(length*width*height)}}
       />
       <TextField
         label={t('table_header_volume')}
         variant="outlined"
         disabled
         InputProps={{ endAdornment:<InputAdornment position="end"><p>mm<sup>3</sup></p></InputAdornment>}}
         value={volume}
       />
       </div>
       <div>
       <TextField
         label={t('table_header_site')}
         variant="outlined"
         disabled
         value={site}
       />
       <TextField
         label={t('table_header_user')}
         variant="outlined"
         disabled
         value={user}
       />
       <TextField
         label={t('table_header_timestamp')}
         variant="outlined"
         disabled
         value={formatTimestamp(timestamp,props.userSettings ? props.userSettings.date_format : 'datetime_long',t('locale'))}
       />
       <TextField
           label={t('table_header_modified')}
           variant="outlined"
           disabled
           value={formatTimestamp(modified,props.userSettings ? props.userSettings.date_format : 'datetime_long',t('locale'))}
       />
       </div>
      
       <div className={classes.buttons}>
         <Button type="submit" variant="contained" color="secondary" textColor="white" startIcon={<SaveIcon />}>
           {t('button_update')}
         </Button>
       </div>
     </form>
   );
 };
 
 export default EditMeasureForm;