/**
 * Conflict chart component
 * 
 * @author MFA
 * Last changed: 17.10.2022
 * Changes: PBI-1119 Conflict cards alignment
*/
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { green } from '@material-ui/core/colors';
import {mdiBarcodeScan, mdiCheck, mdiAlertOutline} from '@mdi/js';
import Icon from '@mdi/react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as routes from '../../../routing/routes';
import Divider from "@material-ui/core/Divider";
  
const useStyles = makeStyles((theme) => ({
  conflicts:
  {
    marginTop: "-18px",
    marginLeft: "18px",
    position:"absolute",
    background: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
    boxShadow: "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    height: 70,
    width: 70,
    cursor:"pointer",
    "&:hover": {
      background: "linear-gradient(195deg, rgb(236, 94, 122), rgb(216, 64, 96));",
    }
  },
  noConflicts:
  {
    marginTop: "-18px",
    marginLeft: "18px",
    position:"absolute",
    background: "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));",
    boxShadow: "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem",
    height: 70,
    width: 70,
    cursor:"pointer",
    "&:hover": {
      background: "linear-gradient(195deg, rgb(102, 203, 106), rgb(67, 187, 71));",
    }
  },
  upperCase:
      {
          textTransform: "uppercase"
      },
  cards: {
    textAlign: "right",
    height: '100%',
    boxShadow: "rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "0.75rem"
  },
  container: {
    position: "relative"
  },
  white : {
    color:"white"
  },
  divider: {
    flexShrink: "0",
    borderTop: "0px solid rgba(0, 0, 0, 0.12)",
    borderRight: "0px solid rgba(0, 0, 0, 0.12)",
    borderLeft: "0px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "transparent",
    height: "0.0625rem",
    margin: "1rem 0px",
    borderBottom: "none",
    opacity: "0.35",
    backgroundImage: "linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0)) !important"
  }
}));

 function Conflicts(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const handleArticlesClick = () => {
    props.setSelectedItem(3)
    props.setBold(3)
    history.push({
        pathname: routes.ARTICLES,
        state: { detail: "fromDashboardConflict"}
    })
  }

   return (
     <div>
       {props.data > 0 && (<Tooltip title={t('dashboard_show_conflicts')}>
         <Avatar className={classes.conflicts} onClick={handleArticlesClick}>
           <Icon path={mdiAlertOutline} size={2} style={{paddingBottom: '5px'}}/>
         </Avatar>
       </Tooltip>)}
       {props.data === 0 && (
         <Avatar className={classes.noConflicts}>
           <Icon path={mdiCheck} size={1.3}/>
         </Avatar>
       )}
       <Card
         className={classes.cards}
       >
         <CardContent>
           <Grid
             container
             spacing={3}
             style={{ justifyContent: 'space-between' }}
           >
             <Grid item>

              </Grid>
             <Grid item>
               <Typography
                 color="textSecondary"
                 gutterBottom
                 variant="h6"
               >
                 {t('dashboard_conflicts')}
               </Typography>
               <Typography
                 color="textPrimary"
                 align='right'
                 variant="h3"
                 className={classes.data}
               >
                 {props.data}
               </Typography>
             </Grid>

           </Grid>
           <Divider className={classes.divider}>

           </Divider>
           <Box
             style={{
               pt: 2,
               display: 'flex',
               alignItems: 'center'
             }}
           >
             <Typography
               color="textSecondary"
               variant="body1"
             >
              <span>&#8203;</span>
             </Typography>
           </Box>
           <Box
             style={{
               pt: 2,
               display: 'flex',
               alignItems: 'center'
             }}
           >
             <Typography
               color="textSecondary"
               variant="body1"
             >
              <span>&#8203;</span>
             </Typography>
           </Box>
         </CardContent>
       </Card>
     </div>

  );
}
  
export default Conflicts;