/**
 * Reset Password component
 * 
 * @author LHO 
 * Last changed: 16.08.2021
 * Changes: Multilanguage support
 */
import './ResetPassword.css';
import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as endpoints from '../../service/endpoints';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ResetPassword extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      password: "",
      confirm: "",
      token: "",
      userId: "",
      submitted: false,
      error: false,
      messageFromServer: ''
    };

    this.handleReset = this.handleReset.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.setConfirmationPassword = this.setConfirmationPassword.bind(this);
    this.postReset = this.postReset.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleReset(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    this.postReset();
  };

  setPassword(event) {
    this.setState({ password: event.target.value });
  }
  setConfirmationPassword(event) {
    this.setState({ confirm: event.target.value });
  }

  postReset() {
    const query = new URLSearchParams(this.props.location.search);
    if (this.state.password !== "") {
      const authBody = {
        "token": query.get('token'),
        "password": this.state.password
      };

      axios.post(endpoints.RESET + '/' + query.get('id'), authBody)
        .then(response => {
          if (response.status === 204) {
            this.setState({ messageFromServer: 204 });
          }
          else {
            this.setState({ messageFromServer: 400 });
          }

        })
        .catch(error => {
          this.setState({ messageFromServer: 400 });
          this.setState({ error: true })
        })
    }
  }

  handleClose = (event, reason) => {
    this.setState({ error: false })
  };

  render() {
    const { classes } = this.props;
    const { messageFromServer } = this.state;
    const { t } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('reset_new_password')}?
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('reset_new_password')}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={this.setPassword}
              error={this.state.password === "" && this.state.submitted}
              helperText={this.state.password === "" && this.state.submitted ? t('error_password_empty') : ' '}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmpassword"
              label={t('input_confirm_password')}
              type="password"
              id="confirmpassword"
              autoComplete="current-password"
              onChange={this.setConfirmationPassword}
              error={this.state.password !== this.state.confirm && this.state.submitted}
              helperText={this.state.password !== this.state.confirm  && this.state.submitted ? t('error_password_not_matching') : ' '}
            />
            <p class="MuiFormHelperText-root">
              {t('password_header')}
              <ul>
                <li>{t('password_line1')}</li>
                <li>{t('password_line2')}</li>
                <li>{t('password_line3')}</li>
                <li>{t('password_line4')}</li> 
              </ul>
              {t('password_footer')}
            </p>
            <p>
            </p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleReset}
            >
              {t('button_reset_pw')}
            </Button>
          </form>
        </div>
        {messageFromServer === 204 
        &&
        setTimeout(function(){
          localStorage.clear();
          window.location.href = '/';
        },3000)
        && (
          <div>
            <h3>{t('notification_success_reset')}</h3>
          </div>
        )}
        {messageFromServer === 400 && (
          <div>
            <h3>{t('notification_error_invalid_pw')}</h3>
          </div>
        )}
        <Box mt={8}>
        </Box>
        <Snackbar open={this.state.error} autoHideDuration={6000} onClose={this.handleClose}>
          <MuiAlert severity="error">{t('notification_error_reset')}</MuiAlert>
        </Snackbar>
      </Container>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

//export default withStyles(useStyles, { withTheme: true }) withRouter(ResetPassword);
export default withTranslation()(withRouter((withStyles(useStyles, { withTheme: true })(ResetPassword))))
