/**
 * Navigation component
 *
 * @author JHO
 * Last changed: 23.12.2021
 * Changes: PBI-674 Added portal table column settings
 */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import './Navigation.scss';
import Dashboard from '../dashboard/Dashboard';
import Settings from '../settings/Settings';
import Account from '../account/Account';
import Measures from '../measures/Measures';
import Users from '../users/Users';
import Devices from '../devices/Devices';
import Info from '../info/Info';
import Articles from '../articles/Articles';
import Planning from '../planning/Planning';
import Landing from '../landing/Landing';
import * as routes from '../../routing/routes';
import logo from '../../common/logo_volumio.png';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InfoIcon from '@material-ui/icons/Info';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import { ProSidebar, Menu as SideMenu, MenuItem as SideMenuItem } from 'react-pro-sidebar';
import {Route, Link, useHistory} from "react-router-dom";
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {mdiAccount, mdiAccountCircle, mdiAlertOutline, mdiTapeMeasure, mdiTabletCellphone} from '@mdi/js';
import { mdiBarcodeScan } from '@mdi/js';
import { mdiAccountMultiplePlus } from '@mdi/js';
import { mdiTune } from '@mdi/js';
import Icon from '@mdi/react';
import { blueGrey} from '@material-ui/core/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DoubleArrowIcon from '@material-ui/icons//DoubleArrow';
import {orange, red} from "@mui/material/colors";
import AddIcon from "@material-ui/icons/Add";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ListItemText from "@material-ui/core/ListItemText";
import HistoryIcon from "@material-ui/icons/History";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {AccountCircle, VerifiedUser} from "@material-ui/icons";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '50px',
    marginLeft: "45px"
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0)
  },
  title: {
    flexGrow: 1,
  },
  purple: {
    color: "white",
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  divider: {
    height: '50%',
    margin: '1em',
  },
  logo: {
    width: '73%',
    maxWidth: '200px',
    paddingLeft: "0px"
  },
  appbar: {
    maxHeight: "60px",
    width: '100vw',
    borderBottom: "solid rgba(87, 87, 86, 0.2) 1px",
    paddingRight: "0 !important"
  },
  toolBar: {
    width: '100vw',
  },
  sideBarItem:{
    marginLeft: "-7.5px"
  },
  sideBarItemClicked:{
    marginLeft: "-7.5px",
    '& .pro-inner-item': {
      color: '#ef7d00'
    },
  },
  sideBar: {
    height: "calc(100% - 60px)",
    bottom: 0,
    position: "fixed",
    borderRight: "solid rgba(87, 87, 86, 0.2) 1px",
    background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25)) !important"
  }

}));


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.primary,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 'bold'
  },
}))(Tooltip);

function Navigation(props) {
  const token = props.token;
  const userId = props.userId;
  const organizationId = props.user.organization._id;
  const permissionLevel = props.permissionLevel;
  const useDashboard = true;
  const csvConfig = props.csvConfig;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showWarningInfo, setShowWarningInfo] = React.useState(null);
  const open = Boolean(anchorEl);
  const openInfo = Boolean(showWarningInfo);
  const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
  const [anchorElAction, setAnchorElAction] = React.useState(null);
  const [bold, setBold]  = React.useState(Number.isInteger(Number(localStorage.getItem("bold"))) ? Number(localStorage.getItem("bold")) : 0)
  const [collapsed, setCollapsed]= React.useState(localStorage.getItem("collapsed") !== "false")
  const [selectedItem, setSelectedItem]= React.useState((Number(localStorage.getItem("selected"))) > 0 ? Number(localStorage.getItem("selected")) : 0)
  const [language, setLanguage] = React.useState(() => {
    return window.localStorage.getItem("language") || "EN";
  });

  const [openExportHistory, setOpenExportHistory] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    i18next.changeLanguage((window.localStorage.getItem("language") || "EN").toLowerCase());
  }, []);

  useEffect(() => {
    localStorage.setItem("bold", String(bold))
  }, [bold])

  useEffect(() => {
    localStorage.setItem("collapsed", String(collapsed))
  }, [collapsed])

  useEffect(() => {
    localStorage.setItem("selected", String(selectedItem))
  }, [selectedItem])

  const sideBar = useRef(null);
  const { t } = useTranslation();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const showWarning = (event) => {
    setShowWarningInfo(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseInfo = () => {
    setShowWarningInfo(null);
  };

  const handleClickLanguage = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null);
  };

  const setEnglish = () => {
    setLanguage("EN");
    i18next.changeLanguage("en");
    setAnchorElLanguage(null);
    window.localStorage.setItem("language", "EN");
  };

  const setGerman = () => {
    setLanguage("DE");
    i18next.changeLanguage("de");
    setAnchorElLanguage(null);
    window.localStorage.setItem("language", "DE");
  };

  const setSerbian = () => {
    setLanguage("SR");
    i18next.changeLanguage("sr");
    setAnchorElLanguage(null);
    window.localStorage.setItem("language", "SR");
  };

  const setSlovenian = () => {
    setLanguage("SL");
    i18next.changeLanguage("sl");
    setAnchorElLanguage(null);
    window.localStorage.setItem("language", "SL");
  };

  const setCroatian = () => {
    setLanguage("HR");
    i18next.changeLanguage("hr");
    setAnchorElLanguage(null);
    window.localStorage.setItem("language", "HR");
  };


  const logout = () => {
    localStorage.setItem("selected", String(1))
    localStorage.setItem("bold", String(1))
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    sessionStorage.clear();
    window.location.href = '/';
  }
  const handleClickArrow = (event) => {
    if(collapsed)
    {
      setCollapsed(false)
    }
    else
    {
      setCollapsed(true)
    }
  };

  const handleClickAction = (event) => {
    setAnchorElAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  const handleExport = useCallback(() => {
    setSelectedItem(2)
    setBold(2)
    history.push('/menu/measures/export')
    setAnchorElAction(null)
    }, [history]);

  const handleImport = useCallback(() => {
    setSelectedItem(3)
    setBold(3)
    history.push('/menu/articles/import')
    setAnchorElAction(null)
    }, [history]);

  const handleExportHistory = () => {
    setOpenExportHistory(true)
    setAnchorElAction(null)
  }

  const handleCloseExportHistory = () => {
    setOpenExportHistory(false)
  }

  const boldItem = (origin) => {
    switch (origin) {
      case "dashboard":
        setBold(1)
        break
      case "measures":
        setBold(2)
        break
      case "articles":
        setBold(3)
        break
      case "planning":
        setBold(4)
        break
      case "users":
        setBold(5)
        break
      case "devices":
        setBold(6)
        break
      case "settings":
        setBold(7)
        break
      case "info":
        setBold(8)
        break
      default:
    }
  }

  const handleClickItem = (event, index) => {
    setBold(index)
    setSelectedItem(index)
  };

  return (
    <div className="Menu">
      <AppBar elevation={0} position="fixed" color="white" className={classes.appbar}>
        <Toolbar className="toolBar">
          {!matches && (<Link onClick={() => {
            setSelectedItem(1)
            setBold(1)
          }} to={routes.DASHBOARD}>
            <img src={logo} className={classes.logo} alt="logo"/>
          </Link>)}
          <Typography variant="h6" className={classes.title}>
          </Typography>
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="secondary"
            style={{marginRight: "10px"}}
            onClick={handleClickAction}
            startIcon={<AddIcon />}
          >
            {t('dashboard_action')}
          </Button>
          <StyledMenu
            classes={{}}
            id="customized-menu"
            anchorEl={anchorElAction}
            keepMounted
            open={Boolean(anchorElAction)}
            onClose={handleCloseAction}
          >
            <StyledMenuItem onClick={handleExport}>
              <ListItemIcon>
                <CloudDownloadIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary={t('dashboard_export')} />
            </StyledMenuItem>
            <StyledMenuItem onClick={handleImport}>
              <ListItemIcon>
                <AddIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary={t('dashboard_import')} />
            </StyledMenuItem>
          </StyledMenu>
          <Dialog
            classes={{}}
            fullWidth={true}
            maxWidth={'xl'}
            open={openExportHistory}
            onClose={handleCloseExportHistory}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">{t("dialog_export_history")}</DialogTitle>
            <DialogContent>
              {/*<MeasureHistory data={historyArticleData} token={props.token} request={historyRequest} userSettings={props.userSettings} setPickedMeasureID={setPickedMeasureID} setCurrentArticleID={setCurrentArticleID} />*/}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseExportHistory} color="primary" variant="contained">
                {t("button_close")}
              </Button>
            </DialogActions>
          </Dialog>
          <Button className="menuButton" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickLanguage} color="primary">
            <b>{language}</b>
          </Button>
          <div className="margin">
            { props.warning &&
              (<LightTooltip title={t('parameters_warning')}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={showWarning}
                  color="error"
                  size="large"    >
                  <ErrorOutlineIcon color="error"/>
                </IconButton>
              </LightTooltip>)}

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              style={{marginTop: "42px"}}
              open={open}
              onClose={handleClose}>
              <Link to={routes.ACCOUNT} style={{ textDecoration: 'none' }}><MenuItem onClick={handleClose}>{t('appbar_menu_account')}</MenuItem></Link>
              <MenuItem onClick={logout}>{t('appbar_menu_logout')}</MenuItem>
            </Menu>
            <Tooltip title={props.user.firstName + " " + props.user.lastName}>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                size="large"    >
                <Icon path={mdiAccountCircle} size={1.3}/>
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorElLanguage}
              style={{marginTop: "50px"}}
              keepMounted
              open={Boolean(anchorElLanguage)}
              onClose={handleCloseLanguage}
            >
              <MenuItem onClick={setEnglish}>{t('english')}</MenuItem>
              <MenuItem onClick={setGerman}>{t('german')}</MenuItem>
              <MenuItem onClick={setSerbian}>{t('serbian')}</MenuItem>
              <MenuItem onClick={setSlovenian}>{t('slovenian')}</MenuItem>
              <MenuItem onClick={setCroatian}>{t('croatian')}</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <div className="Sidebar">
        <ProSidebar ref={sideBar} className={classes.sideBar} collapsed={collapsed} collapsedWidth={"60px"}>
          <SideMenu iconShape="none">
            { useDashboard &&
              (collapsed ?
                (<Tooltip title={t('menu_tooltip_dashboard')} placement="right" arrow>
                  <SideMenuItem onClick={event => handleClickItem(event, 1)} className={selectedItem === 1 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <DashboardIcon color="white" style={{ fontSize: 28}}/> }><Link to={routes.DASHBOARD} />{!collapsed && t('menu_tooltip_dashboard')}</SideMenuItem>
                </Tooltip>) : (<SideMenuItem onClick={event => handleClickItem(event, 1)} className={selectedItem === 1 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <DashboardIcon color="white" style={{ fontSize: 28}}/> }><Link style={bold === 1 ? {fontWeight: "bold"} : {}} onClick={() => boldItem("dashboard")} to={routes.DASHBOARD}>{!collapsed && t('menu_tooltip_dashboard')}</Link></SideMenuItem>
                ))
            }
            {collapsed ?
              (<Tooltip title={t('menu_tooltip_measures')} placement="right"  arrow>
                <SideMenuItem onClick={event => handleClickItem(event, 2)} className={selectedItem === 2 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <Icon path={mdiTapeMeasure} title={t('menu_tooltip_measures')} size={1}/>}><Link to={routes.MEASURES} />{!collapsed && t('menu_tooltip_measures')}</SideMenuItem>
              </Tooltip>) : (<SideMenuItem onClick={event => handleClickItem(event, 2)} className={selectedItem === 2 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <Icon path={mdiTapeMeasure} title={t('menu_tooltip_measures')} size={1}/>}><Link style={bold === 2 ? {fontWeight: "bold"} : {}} onClick={() => boldItem("measures")} to={routes.MEASURES}>{!collapsed && t('menu_tooltip_measures')}</Link></SideMenuItem>
              )}
            {collapsed ?
              (<Tooltip title={t('menu_tooltip_articles')} placement="right"  arrow>
                <SideMenuItem onClick={event => handleClickItem(event, 3)} className={selectedItem === 3 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <Icon path={mdiBarcodeScan} title={t('menu_tooltip_articles')} size={1}/> }><Link to={routes.ARTICLES} />{!collapsed && t('menu_tooltip_articles')}</SideMenuItem>
              </Tooltip>) : (<SideMenuItem onClick={event => handleClickItem(event, 3)} className={selectedItem === 3 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <Icon path={mdiBarcodeScan} title={t('menu_tooltip_articles')} size={1}/> }><Link style={bold === 3 ? {fontWeight: "bold"} : {}} onClick={() => boldItem("articles")} to={routes.ARTICLES}>{!collapsed && t('menu_tooltip_articles')}</Link></SideMenuItem>
              )}

            { (props.permissionSettings && permissionLevel >= props.permissionSettings.planning.view) &&
              (collapsed ?
                (<Tooltip title={t('menu_tooltip_planning')} placement="right" arrow>
                  <SideMenuItem onClick={event => handleClickItem(event, 4)} className={selectedItem === 4 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <DateRangeIcon color="white" style={{ fontSize: 28 }} /> }><Link to={routes.PLANNING} /></SideMenuItem>
                </Tooltip>) : (<SideMenuItem onClick={event => handleClickItem(event, 4)} className={selectedItem === 4 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <DateRangeIcon color="white" style={{ fontSize: 28 }} />}><Link style={bold === 4 ? {fontWeight: "bold"} : {}} onClick={() => boldItem("planning")} to={routes.PLANNING}>{!collapsed && t('menu_tooltip_planning')}</Link></SideMenuItem>
                ))}
            { (props.permissionSettings && permissionLevel >= props.permissionSettings.user_management) &&
              (collapsed ?
                (<Tooltip title={t('menu_tooltip_users')} placement="right" arrow>
                  <SideMenuItem onClick={event => handleClickItem(event, 5)} className={selectedItem === 5 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <Icon path={mdiAccountMultiplePlus} title={t('menu_tooltip_users')} size={1}/> }><Link to={routes.USERS} />{!collapsed && t('menu_tooltip_users')}</SideMenuItem>
                </Tooltip>) : (<SideMenuItem onClick={event => handleClickItem(event, 5)} className={selectedItem === 5 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <Icon path={mdiAccountMultiplePlus} title={t('menu_tooltip_users')} size={1}/> }><Link style={bold === 5 ? {fontWeight: "bold"} : {}} onClick={() => boldItem("users")} to={routes.USERS}>{!collapsed && t('menu_tooltip_users')}</Link></SideMenuItem>
                ))
            }
            { (props.permissionSettings && permissionLevel >= props.permissionSettings.device_management) &&
              (collapsed ?
                (<Tooltip title={t('menu_tooltip_devices')} placement="right" arrow>
                  <SideMenuItem onClick={event => handleClickItem(event, 6)} className={selectedItem === 6 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <Icon path={mdiTabletCellphone} title={t('menu_tooltip_devices')} size={1}/> }><Link to={routes.DEVICES} />{!collapsed && t('menu_tooltip_devices')}</SideMenuItem>
                </Tooltip>) : (<SideMenuItem onClick={event => handleClickItem(event, 6)} className={selectedItem === 6 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <Icon path={mdiTabletCellphone} title={t('menu_tooltip_devices')} size={1}/> }><Link style={bold === 6 ? {fontWeight: "bold"} : {}} onClick={() => boldItem("devices")} to={routes.DEVICES}>{!collapsed && t('menu_tooltip_devices')}</Link></SideMenuItem>
                ))
            }
            {/*TODO:ToBottom moves tooltip slightly to the right at settings and info*/
              <div className="ToBottom" style={collapsed ? {width: "60px"} : {width: "270px"}}>
              {
                (collapsed ? (
                  <Tooltip title={t('menu_tooltip_settings')} placement="right" arrow>
                    <SideMenuItem onClick={event => handleClickItem(event, 7)} style={{borderTop: "solid rgba(87, 87, 86, 0.2) 1.5px", border: "15px"}} className={selectedItem === 7 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <SettingsIcon title={t('menu_tooltip_settings')} size={1}/> }><Link to={routes.SETTINGS} />{!collapsed && t('menu_tooltip_settings')}</SideMenuItem>
                  </Tooltip>) : (<SideMenuItem onClick={event => handleClickItem(event, 7)} style={{borderTop: "solid rgba(87, 87, 86, 0.2) 1.5px", border: "15px"}} className={selectedItem === 7 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <SettingsIcon title={t('menu_tooltip_settings')} size={1}/> }><Link style={bold === 7 ? {fontWeight: "bold"} : {}} onClick={() => boldItem("settings")} to={routes.SETTINGS}>{!collapsed && t('menu_tooltip_settings')}</Link></SideMenuItem>
                  ))}
                { (permissionLevel >= 3) && ( collapsed ? (
                  <Tooltip title={t('menu_tooltip_info')} placement="right" arrow>
                    <SideMenuItem onClick={event => handleClickItem(event, 8)} className={selectedItem === 8 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <InfoIcon color="white" style={{ fontSize: 28 }} /> }><Link to={routes.INFO} />{!collapsed && t('menu_tooltip_info')}</SideMenuItem>
                  </Tooltip>) : (<SideMenuItem onClick={event => handleClickItem(event, 8)} className={selectedItem === 8 ? classes.sideBarItemClicked : classes.sideBarItem} icon={ <InfoIcon color="white" style={{ fontSize: 28 }} /> }><Link style={bold === 8 ? {fontWeight: "bold"} : {}} onClick={() => boldItem("info")} to={routes.INFO}>{!collapsed && t('menu_tooltip_info')}</Link></SideMenuItem>
                ))}
                {collapsed ? (
                  <Tooltip title={collapsed ? t('menu_tooltip_collapsed') : t('menu_tooltip_expanded')} placement="right" arrow>
                <SideMenuItem className={"collapsed-arrow"} icon={ <DoubleArrowIcon onClick={handleClickArrow} className={"arrow"} color="white" style={{ fontSize: 28 }} /> } onClick={handleClickArrow}></SideMenuItem>
             </Tooltip>) : (<SideMenuItem className={"expanded-arrow"} icon={ <DoubleArrowIcon onClick={handleClickArrow} className={"arrow-rotated"} color="white" style={{ fontSize: 28 }} /> } onClick={handleClickArrow}></SideMenuItem>)}
            </div>}
          </SideMenu>
        </ProSidebar>
      </div>
      <div style={{height: "100%"}} className={!collapsed ? "margin-container" : "no-margin"}>
        <Route path={routes.DASHBOARD}>
          <Dashboard user={props.user} permission={permissionLevel} token={token} organizationId={organizationId} setSelectedItem={setSelectedItem} setBold={setBold} permissionSettings={props.permissionSettings} />
        </Route>
        <Route path={routes.MEASURES}>
          <Measures token={token} csvConfig={csvConfig} portalSettings={props.portalSettings} userSettings={props.userSettings} permissionSettings={props.permissionSettings} setCSVConfig={props.setCSVConfig} permissionLevel={props.permissionLevel} customFields={props.customFields} setSelectedItem={setSelectedItem} setBold={setBold}/>
        </Route>
        <Route path={routes.ARTICLES}>
          <Articles token={token} customFields={props.customFields} portalSettings={props.portalSettings} userSettings={props.userSettings} csvConfig={csvConfig} setCSVConfig={props.setCSVConfig} permissionSettings={props.permissionSettings} permissionLevel={props.permissionLevel} setSelectedItem={setSelectedItem} setBold={setBold}/>
        </Route>
        <Route path={routes.USERS}>
          <Users token={token} sites={props.sites} userSettings={props.userSettings} portalSettings={props.portalSettings} setSelectedItem={setSelectedItem} setBold={setBold}/>
        </Route>
        <Route path={routes.DEVICES}>
          <Devices token={token} sites={props.sites} userSettings={props.userSettings} portalSettings={props.portalSettings} setSelectedItem={setSelectedItem} setBold={setBold}/>
        </Route>
        <Route path={routes.SETTINGS}>
          <Settings token={token} userId={userId} sites={props.sites} setSites={props.setSites} csvConfig={csvConfig} setCSVConfig={props.setCSVConfig} customFields={props.customFields} setCustomFields={props.setCustomFields} userSettings={props.userSettings} setUserSettings={props.setUserSettings} permissionSettings={props.permissionSettings} setPermissionSettings={props.setPermissionSettings} permissionLevel={props.permissionLevel} handlingUnits={props.handlingUnits} setHandlingUnits={props.setHandlingUnits} appSettings={props.appSettings} setAppSettings={props.setAppSettings} portalSettings={props.portalSettings} setPortalSettings={props.setPortalSettings}/>
        </Route>
        <Route path={routes.INFO}>
          <Info />
        </Route>
        <Route path={routes.ACCOUNT}>
          <Account token={token} userId={userId}/>
        </Route>
        <Route exact path={routes.PLANNING}>
          <Planning token={token} userSettings={props.userSettings} permissionSettings={props.permissionSettings} permissionLevel={props.permissionLevel} setSelectedItem={setSelectedItem} setBold={setBold}/>
        </Route>
        <Route exact path={routes.MENU}>
          <Landing />
        </Route>
      </div>
    </div>
  );
}

export default Navigation;