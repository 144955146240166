/**
 * Settings data import/export category component
 * 
 * @author JHO 
 * Last changed: 18.11.2021
 * Changes: Bugfixing -> export units and date format
 */

import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios';
import * as endpoints from '../../../service/endpoints';
import * as constants from '../../../common/constants';
import {checkError401} from '../../../common/helpers';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  header: {
    color: "black",
    textStyle: "bold",
    marginBottom: theme.spacing(4)
  },
  page: {
    minHeight: '550px',
    maxHeight: '92vh',
    height: 'auto',
    padding: theme.spacing(3),
    boxShadow: "5px 5px 30px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 10px 50px -12px rgba(0,0,0,0.3)"
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '75px',
    maxHeight: '75px',
    padding: theme.spacing(1),
  },
  select: {
    marginTop: theme.spacing(2),
    minWidth: '200px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
    minWidth: '200px',
    marginRight: theme.spacing(2)
  },
  button: {
    marginLeft: theme.spacing(2),
  }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CategoryData(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getFieldNamesFromJson = (data) => {
    let names = []
    for (const el of data) {
      names.push(el.header)
    }
    return names;
  }
  const [active, setActive] = React.useState(props.csvConfig && props.csvConfig.active ? props.csvConfig.active : undefined);
  const [exportFields, setExportFields] = React.useState(props.csvConfig && props.csvConfig.templates[props.csvConfig.active] ? getFieldNamesFromJson(props.csvConfig.templates[props.csvConfig.active].fields): []);
  const [fileName, setFileName] = React.useState(props.csvConfig && props.csvConfig.templates[props.csvConfig.active] ? props.csvConfig.templates[props.csvConfig.active].filename.slice(0, -4) : "");
  const [delimiter, setDelimiter] = React.useState(props.csvConfig && props.csvConfig.templates[props.csvConfig.active] ? props.csvConfig.templates[props.csvConfig.active].delimiter : ";");
  const [useHeader, setUseHeader] = React.useState(props.csvConfig && props.csvConfig.templates[props.csvConfig.active] ? props.csvConfig.templates[props.csvConfig.active].headerline : true);
  const [exportImages, setExportImages] = React.useState(props.csvConfig && props.csvConfig.templates[props.csvConfig.active] ? props.csvConfig.templates[props.csvConfig.active].exportImages : true);
  const [template, setTemplate] = React.useState(props.csvConfig && props.csvConfig.templates[props.csvConfig.active] ? props.csvConfig.active : "new");
  const [templateName, setTemplateName] = React.useState(props.csvConfig && props.csvConfig.templates[props.csvConfig.active] ? props.csvConfig.active : t("settings_data_new_template"));
  const [templates, setTemplates] = React.useState(Object.keys(props.csvConfig && props.csvConfig.templates));
  const [dateFormat, setDateFormat] = React.useState(props.csvConfig && props.csvConfig.date_format ? props.csvConfig.date_format : '');
  const [lengthUnit, setLengthUnit] = React.useState(props.csvConfig && props.csvConfig.length_unit? props.csvConfig.length_unit : '');
  const [volumeUnit, setVolumeUnit] = React.useState(props.csvConfig && props.csvConfig.volume_unit ? props.csvConfig.volume_unit : '');
  const [weightUnit, setWeightUnit] = React.useState(props.csvConfig && props.csvConfig.weight_unit ? props.csvConfig.weight_unit : '');

  const config = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  //select handlers
  const handleDateformatChange = (event) => {
    setDateFormat(event.target.value);
  }
  const handleLengthUnitChange = (event) => {
    setLengthUnit(event.target.value);
  }
  const handleVolumeUnitChange = (event) => {
    setVolumeUnit(event.target.value);
  }
  const handleWeightUnitChange = (event) => {
    setWeightUnit(event.target.value);
  }

  //Dialogs state handler
  const handleSuccess = () => {
    enqueueSnackbar(t('notification_success_update_export'), {
      variant: 'success',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const handleError = () => {
    enqueueSnackbar(t('notification_error_update_export'), {
      variant: 'error',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const handleChangeRadio = (event) => {
    setDelimiter(event.target.value);
  };

  const handleChangeFilename = (event) => {
    setFileName(event.target.value);
  };
  const handleChangeTemplateName = (event) => {
    setTemplateName(event.target.value);
  };
  const handleChangeTemplate = (event) => {
    if (event.target.value !== "new") {
      setTemplate(event.target.value);
      setActive(event.target.value);
      setTemplateName(event.target.value);
      setExportFields(getFieldNamesFromJson(props.csvConfig.templates[event.target.value].fields));
      setFileName(props.csvConfig.templates[event.target.value].filename.slice(0, -4));
      setDateFormat(props.csvConfig.templates[event.target.value].date_format)
      setDelimiter(props.csvConfig.templates[event.target.value].delimiter)
      setUseHeader(props.csvConfig.templates[event.target.value].headerline)
      setExportImages(props.csvConfig.templates[event.target.value].exportImages)
      setLengthUnit(props.csvConfig.templates[event.target.value].length_unit)
      setVolumeUnit(props.csvConfig.templates[event.target.value].volume_unit)
      setWeightUnit(props.csvConfig.templates[event.target.value].weight_unit)
    }
    else {
      setExportFields(getFieldNamesFromJson([]));
      setTemplate("new");
      setTemplateName("");
      setFileName(t("settings_data_new_template"));
    }
  };
  const handleChange = (event, value) => {
    setExportFields(value);
  };

  const handleChangeSwitch = (event) => {
    setUseHeader(event.target.checked)
  };

  const handleChangeImages = (event) => {
    setExportImages(event.target.checked)
  };

  const deleteParameter = (e) => {
    e.preventDefault();
    //Get parameters and modify
    axios.get(endpoints.PARAMETERS, config)
      .then(response => {
        try {
          let csvConfig = response.data['csv_export']
          if(csvConfig && csvConfig.templates[template]){
            delete csvConfig.templates[template];
            if(Object.keys(csvConfig.templates).length > 0){
            setActive(Object.keys(csvConfig.templates)[0]) 
            csvConfig.active = Object.keys(csvConfig.templates)[0];
            setTemplateName(Object.keys(csvConfig.templates)[0]);
            setExportFields(getFieldNamesFromJson(csvConfig.templates[Object.keys(csvConfig.templates)[0]].fields));
            setFileName(csvConfig.templates[Object.keys(csvConfig.templates)[0]].filename.slice(0, -4));
            }
            else {
              setActive(undefined);
              csvConfig.active = undefined;
              setExportFields(getFieldNamesFromJson([]));
              setTemplate("new");
              setTemplateName(t("settings_data_new_template"));
              setFileName("");
            }

          }
          
          setTemplates(Object.keys(csvConfig.templates));
          setTemplate(csvConfig.active);

          //update parameters
          axios.patch(endpoints.PARAMETERS , {"csv_export": csvConfig}, config)
            .then(response => {
              props.setCSVConfig(csvConfig);

              handleSuccess();
            })
            .catch(error => {
              console.log(error)
              handleError();
            })
        }
        catch (error) {
          console.log(error)
          handleError(); 
        }
      })
      .catch(error => {
        console.log(error)
        handleError();
        checkError401(error);
      })
  }

  const updateParameters = (e) => {
    e.preventDefault();
    //Get parameters and modify
    axios.get(endpoints.PARAMETERS, config)
      .then(response => {
        try {
          let csvConfig = response.data['csv_export']
          let fields = []
          csvConfig.active = templateName;
          if(csvConfig.templates[template]){
            delete csvConfig.templates[template];
          }
          csvConfig.templates[templateName] = {};
          csvConfig.templates[templateName].filename = fileName + '.csv'
          csvConfig.templates[templateName].delimiter = delimiter
          csvConfig.templates[templateName].headerline = useHeader
          csvConfig.templates[templateName].exportImages = exportImages
          csvConfig.templates[templateName].date_format = dateFormat
          csvConfig.templates[templateName].length_unit = lengthUnit
          csvConfig.templates[templateName].volume_unit = volumeUnit
          csvConfig.templates[templateName].weight_unit = weightUnit
          for (let field of exportFields) {
            fields.push({
              "key": constants.FIELDS_MAP[field],
              "header": field,
              "lang_key": constants.LANG_MAP[field]
            })
          }
          csvConfig.templates[templateName].fields = fields
          csvConfig.date_format = dateFormat
          csvConfig.length_unit = lengthUnit
          csvConfig.weight_unit = weightUnit
          csvConfig.volume_unit = volumeUnit

          setTemplates(Object.keys(csvConfig.templates));
          setTemplate(csvConfig.active);

          //update parameters
          axios.patch(endpoints.PARAMETERS, {"csv_export": csvConfig}, config)
            .then(response => {
              props.setCSVConfig(csvConfig);
              window.localStorage.setItem("csvConfig", JSON.stringify(csvConfig));
              handleSuccess();
            })
            .catch(error => {
              console.log(error)
              handleError();
            })
        }
        catch (err) {
          console.log(err)
          handleError();
        }
      })
      .catch(error => {
        console.log(error)
        handleError();
        checkError401(error);
      })
  }

  return (
    <div className={classes.page}>
      <Typography className={classes.header} variant="h5" color="black" gutterBottom>
        {t('settings_data')}
      </Typography>
      <form onSubmit={updateParameters}>
        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            select
            label={t('settings_data_template')}
            value={template}
            onChange={e => handleChangeTemplate(e)}
            variant="outlined"
          >
            <MenuItem value="new">
              <em>{t('settings_data_new_template')}</em>
            </MenuItem>
            {templates.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <div className={classes.formControl}>
          <TextField required label={t('settings_data_template_name')} variant="outlined"
            value={templateName} onChange={handleChangeTemplateName}
          />
        </div>
        <div className={classes.formControl}>
          <TextField required label={t('settings_data_filename')} variant="outlined"
            value={fileName} onChange={handleChangeFilename} InputProps={{
              endAdornment: <InputAdornment position="end">.csv</InputAdornment>
            }}
          />
        </div>
        <Autocomplete
          className={classes.formControl}
          multiple
          options={constants.FIELDS_LIST}
          onChange={handleChange}
          disableCloseOnSelect
          value={exportFields}
          getOptionLabel={(option) => option}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </React.Fragment>
          )}
          style={{ width: 1000 }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={t('settings_data_columns')} placeholder={t('settings_data_fields')} />
          )}
        />
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">{t('settings_data_delimiter')}</FormLabel>
          <RadioGroup row aria-label="position" name="position" defaultValue=";" value={delimiter} onChange={handleChangeRadio}>
            <FormControlLabel
              value=";"
              control={<Radio color="primary" />}
              label=";"
              labelPlacement="end"
            />
            <FormControlLabel
              value=","
              control={<Radio color="primary" />}
              label=","
              labelPlacement="end"
            />
            <FormControlLabel
              value="|"
              control={<Radio color="primary" />}
              label="|"
              labelPlacement="end"
            />

          </RadioGroup>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={useHeader}
              onChange={handleChangeSwitch}
              color="secondary"
            />
          }
          labelPlacement="top"
          label={t('settings_data_header')}
          className={classes.formControl}
        />
        <FormControlLabel
          control={
            <Switch
              checked={exportImages}
              onChange={handleChangeImages}
              color="secondary"
            />
          }
          labelPlacement="top"
          label={t('settings_export_images')}
          className={classes.formControl}
        />
        <div>
        <FormControl className={classes.formControl} variant="outlined">
             <InputLabel id="datetime">{t('measure_date_format')}</InputLabel>
             <Select
               id="datetime"
               label={t('measure_date_format')}
               value={dateFormat}
               onChange={handleDateformatChange}
             >
               <MenuItem value={'datetime_long'}>{t('datetime_long')}</MenuItem>
               <MenuItem value={'datetime_middel'}>{t('datetime_middel')}</MenuItem>
               <MenuItem value={'datetime_short'}>{t('datetime_short')}</MenuItem>
               <MenuItem value={'datetime_auto'}>{t('datetime_auto')}</MenuItem>
             </Select>
           </FormControl>
           <FormControl className={classes.formControl} variant="outlined">
             <InputLabel id="length">{t('measure_unit_length')}</InputLabel>
             <Select
               id="length"
               label={t('measure_unit_length')}
               value={lengthUnit}
               onChange={handleLengthUnitChange}
             >
               <MenuItem value={'mm'}>mm</MenuItem>
               <MenuItem value={'cm'}>cm</MenuItem>
               <MenuItem value={'dm'}>dm</MenuItem>
               <MenuItem value={'m'}>m</MenuItem>
               <MenuItem value={'inches'}>inches</MenuItem>
             </Select>
           </FormControl>
           <FormControl className={classes.formControl} variant="outlined">
             <InputLabel id="volume">{t('measure_unit_volume')}</InputLabel>
             <Select
               id="volume"
               label={t('measure_unit_volume')}
               value={volumeUnit}
               onChange={handleVolumeUnitChange}
             >
               <MenuItem value={'mm'}>mm<sup>3</sup></MenuItem>
               <MenuItem value={'cm'}>cm<sup>3</sup></MenuItem>
               <MenuItem value={'dm'}>dm<sup>3</sup></MenuItem>
               <MenuItem value={'m'}>m<sup>3</sup></MenuItem>
             </Select>
           </FormControl>
           <FormControl className={classes.formControl} variant="outlined">
             <InputLabel id="weight">{t('measure_unit_weight')}</InputLabel>
             <Select
               id="weight"
               label={t('measure_unit_weight')}
               value={weightUnit}
               onChange={handleWeightUnitChange}
             >
               <MenuItem value={'g'}>g</MenuItem>
               <MenuItem value={'kg'}>kg</MenuItem>
             </Select>
           </FormControl>
           </div>
        <div className={classes.formControl}>
          <Button type="submit" variant="contained" color="secondary" textColor="white" startIcon={<SaveIcon />} className={classes.button}>
            {t('button_update')}
          </Button>
          <Button type="submit" onClick={deleteParameter} variant="contained" color="secondary" textColor="white" startIcon={<DeleteIcon />} className={classes.button}>
            {t('button_delete')}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CategoryData;