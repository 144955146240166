/**
 * Submit plan dialog
 *
 * @author MFA
 * Last changed: 19.05.20212
 * Changes: initial implementation
 */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import SubmitPlanForm from "./SubmitPlanForm";


//size of measure history dialog
const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '10vh',
    maxHeight: '20vh',
    padding: theme.spacing(2),
    width: 'auto',
    minWidth: '300px'
  }
}));

const SubmitPlanDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth={true} maxWidth={'md'} open={props.open} onClose={props.handleClose}>
      <SubmitPlanForm index={props.index} userSettings={props.userSettings} users={props.users} handleClose={props.handleClose} token={props.token} plan={props.plan}/>
    </Dialog>
  );
};

export default SubmitPlanDialog;