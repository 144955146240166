/**
 * Edit article dialog
 * 
 * @author JHO 
 * Last changed: 30.08.2021
 * Changes: added custom fields to props
 */

 import React from 'react';
 import Dialog from '@material-ui/core/Dialog';
 import EditArticleForm from './EditArticleForm';
 import Typography from '@material-ui/core/Typography';
 import { makeStyles } from '@material-ui/core/styles';
 import { useTranslation } from "react-i18next";
 
 //size of measure history dialog
const useStyles = makeStyles((theme) => ({
    dialog: {
        minHeight: '50vh',
        maxHeight: '70vh',
        padding: theme.spacing(2)
     },
}));

 const EditArticleDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Dialog classes={{ paper: classes.dialog }} fullWidth={true} maxWidth={'md'} open={props.open} onClose={props.handleClose}>
            <Typography variant="h5" color="black" gutterBottom>
                            {t('edit_article_header')}
            </Typography>
            <EditArticleForm handleClose={props.handleClose} handleSuccess={props.handleSuccess} handleError={props.handleError} token={props.token} article={props.article} customFields={props.customFields}/>
        </Dialog>
    );
 };
 
 export default EditArticleDialog;