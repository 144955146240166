/**
 *
 */
import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import * as endpoints from '../../../service/endpoints';
import { useTranslation } from "react-i18next";
import * as constants from '../../../common/constants';

function SKUInfo(props) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState(props.data);
    const [articles, setArticles] = useState(props.data?.articles);
    const [articlesExist, setArticlesExist] = useState(props.data?.articles?.length > 0);

    const httpConfig = {
        headers: { Authorization: `Bearer ${props.token}` }
      };

    const handleDeleteClick = (id) => {
      let newData = articles?.slice()
      let toDelete = null
      for (let index in newData)
      {
        if(newData[index]?._id === id)
        {
          toDelete = index
        }
      }
      newData.splice(toDelete, 1)


      const planBody = {
        "articles": newData,
      };

      axios.patch(endpoints.PLANS + "/" + data?._id, planBody, httpConfig)
        .then(response => {
          enqueueSnackbar(t('notification_success_edit_plan'), {
            variant: 'info',
            autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
          });
          setArticles(newData)
        })
        .catch(error => {
          enqueueSnackbar(t('notification_error_edit_plan'), {
            variant: 'error',
            autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time * 1000 : constants.NOTIFICATION_TIME_DEFAULT * 1000
          })
        })
      };

    return (
        <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">
            {t('sku_info')}
            </Typography>
            <Table size="small" aria-label="purchases">
                {!articlesExist && (<caption>{t('table_no_data')}</caption>)}
                <TableHead>
                    <TableRow>
                        <TableCell align={"left"}>{t('table_header_articleID')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_description')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_hu')}</TableCell>
                        <TableCell align={"left"}>{t('table_header_delete')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {articles?.map((article) => (
                    <TableRow key={article?.articleID}>
                        <TableCell>{article?.articleID}</TableCell>
                        <TableCell>{article?.description}</TableCell>
                        <TableCell>{article?.handlingUnit}</TableCell>
                        <TableCell><IconButton aria-label="delete" onClick={() =>{handleDeleteClick(article?._id)}}><DeleteIcon /></IconButton></TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
}

export default SKUInfo;