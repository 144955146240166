/**
 * Measure progress chart component
 * 
 * @author JHO 
 * Last changed: 04.11.2021
 * Changes: PBI-623 Initial implementation
*/
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import { Doughnut } from 'react-chartjs-2';


const useStyles = makeStyles((theme) => ({
  cards: {
    //height: '100%',
    boxShadow: "rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "0.75rem"
  }
}));


function MeasuredProgress(props) {
   const { t } = useTranslation();
   const classes = useStyles();

   const data = {
    labels: ['Measured', 'Not measured'],
    datasets: [
      {
        data: [props.data.measured_articles, (props.data.total_articles - props.data.measured_articles)],
        backgroundColor: [
          'rgba(102, 187, 106, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderColor: [
          'rgba(102, 187, 106, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Card
      className={classes.cards}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          style={{ justifyContent: 'center' }}
        >
          <Grid item xs={12}>
            <Typography
              color="textSecondary"
              gutterBottom
              align='center'
              variant="h6"
            >
              {t('dashboard_measured_articles')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Doughnut data={data} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              align='center'
              variant="h5"
            >
              {(props.data.total_articles > 0) ? ((props.data.measured_articles / props.data.total_articles)*100).toFixed(2) : 0} % ({props.data.measured_articles}/{props.data.total_articles})
            </Typography>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
}
  
export default MeasuredProgress;