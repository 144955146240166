/**
 * Custom toolbar for users table
 * 
 * @author JHO 
 * Last changed: 11.11.2021
 * Changes: PBI-662 Removed import from user table
 */

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {
  },
};

class TableToolbar extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"Add user"}>
          <IconButton className={classes.iconButton} onClick={this.props.handleClick}>
            <PersonAddIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "TableToolbar" })(TableToolbar);