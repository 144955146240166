/**
 * Settings VOSApp category component
 * 
 * @author JHO 
 * Last changed: 30.11.2021
 * Changes: PBI-568 Added vosapp settings
 */
import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import * as constants from '../../../common/constants';
import axios from 'axios';
import * as endpoints from '../../../service/endpoints';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import logo from "../../../common/screws_imagetag.jpg";
import classNames from 'classnames'

import {IMAGE_TAG_ENABLED_DEFAULT, IMAGE_TAG_POSITION_DEFAULT} from "../../../common/constants";
import * as routes from "../../../routing/routes";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { CirclePicker } from 'react-color';
import {Box, Grid, Input, Slider} from "@mui/material";
import {Label} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    header: {
      float: 'left',
      color: "black",
      textStyle: "bold",
      padding: theme.spacing(2)
    },
    page: {
      minHeight: '0',
      boxShadow: "5px 5px 30px -12px rgba(0,0,0,0.3)",
      "&:hover": {
        boxShadow: "0 10px 50px -12px rgba(0,0,0,0.3)"
      }
    },
    settings: {
      display: "block"
    },
    formControl: {
      minWidth: 250,
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
    formControlImageTag: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(8),
      color: "rgba(0, 0, 0, 0.54)"
    },
    button: {
      float: 'right',
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    top: {
        overflow: "hidden"
    },
    text: {
        float: "left",
        padding: theme.spacing(1)
    },
    select: {
      height: 54
    },
    img: {
      marginTop: "15px",
      height: "100%",
      width: "100%"
    },
    container: {
      width: "50%",
      height: "50%",
      marginTop: "auto",
      marginBottom: "auto",
      position: "relative",
    },
    tagBottom: {
      position: "absolute",
      bottom: "-10px",
      fontWeight: "bold",
      fontSize: "large",
    },
    tagTop: {
      position: "absolute",
      top: "23px",
      fontWeight: "bold",
      fontSize: "large",
    },
    tagCenter: {
      position: "absolute",
      top: "40%",
      fontWeight: "bold"
    },
    tagRight: {
      right: "20px",
    },
    tagLeft: {
      left: "20px",
    },
    tagMiddle: {
      left: "35%",
    },
    circlePicker: {
      flexWrap: "nowrap",
      minWidth:"500px",
        '& span:nth-child(1)': {
           '& div': {
             '& span': {
               '& div': {
                 backgroundColor: "#e5e5f7 !important",
                 opacity: "0.8",
                 backgroundImage:  "repeating-linear-gradient(45deg, #909090 25%, transparent 25%, transparent 75%, #909090 75%, #909090), repeating-linear-gradient(45deg, #909090 25%, #e5e5f7 25%, #e5e5f7 75%, #909090 75%, #909090) !important",
                 backgroundPosition: "0 0, 10px 10px !important",
                 backgroundSize: "20px 20px !important",
               }
             }
           }
        },
      '& span:nth-child(3)': {
        '& div': {
          '& span': {
            '& div': {
              border: "#575756 solid 1px"
            }
          }
        }
      }
    },
    label: {
      marginBottom: "15px"
    },
    tagPositionContainer: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
        '& div': {
          '& label': {
            minWidth: "75px"
          }
        }
    },
    imageTagContainer: {
      display: "flex",
      flexFlow: "row",
      justifyContent: "space-between",
      marginLeft: "18px",
      marginRight:"18px"
    }
}))
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CategoryVOSApp(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const httpConfig = {
    headers: { Authorization: `Bearer ${props.token}` }
  };

  const [mandatoryFields, setMandatoryFields] = React.useState(props.appSettings && props.appSettings.mandatory_fields ? props.appSettings.mandatory_fields : []);
  const [inputFields, setInputFields] = React.useState(props.appSettings && props.appSettings.input_fields ? props.appSettings.input_fields : []);
  const [lengthUnit, setLengthUnit] = React.useState(props.appSettings && props.appSettings.measure_units ? props.appSettings.measure_units.length : constants.LENGTH_UNIT_DEFAULT);
  const [volumeUnit, setVolumeUnit] = React.useState(props.appSettings && props.appSettings.measure_units ? props.appSettings.measure_units.volume : constants.VOLUME_UNIT_DEFAULT);
  const [weightUnit, setWeightUnit] = React.useState(props.appSettings && props.appSettings.measure_units ? props.appSettings.measure_units.weight : constants.WEIGHT_UNIT_DEFAULT);
  const [numberOfImages, setNumberOfImages] = React.useState(props.appSettings && props.appSettings.max_number_images ? props.appSettings.max_number_images : constants.IMAGES_NUM_DEFAULT);
  const [retentionDays, setRetentionDays] = React.useState(props.appSettings && props.appSettings.retention_policy_day ? props.appSettings.retention_policy_day : constants.RETENTION_DAYS_DEFAULT);
  const [checkEAN, setCheckEAN] = React.useState(props.appSettings && props.appSettings.checks.ean_exists_check ? props.appSettings.checks.ean_exists_check : constants.CHECK_EAN_DEFAULT);
  const [skipImages, setSkipImages] = React.useState(props.appSettings && props.appSettings.skip_images ? !props.appSettings.images.skip_images : !constants.SKIP_IMAGES);
  const [rotateImages, setRotateImages] = React.useState(props.appSettings && props.appSettings.rotate_images ? props.appSettings.rotate_images : constants.ROTATE_IMAGES);
  const [storageSpace, setStorageSpace] = React.useState(props.appSettings && props.appSettings.storage_space ? props.appSettings.storage_space : constants.STORAGE_SPACE);
  const [imageTagEnabled, setImageTagEnabled] = React.useState(props.appSettings && props.appSettings.images && props.appSettings.images.image_tag.enabled ? props.appSettings.images.image_tag.enabled : constants.IMAGE_TAG_ENABLED_DEFAULT);
  const [imageTagFontSize, setImageTagFontSize] = React.useState(props.appSettings && props.appSettings.images && props.appSettings.images.image_tag.tag_size ? props.appSettings.images.image_tag.tag_size : constants.IMAGE_TAG_SIZE_DEFAULT);
  const [imageTagFontColor, setImageTagFontColor] = React.useState(props.appSettings && props.appSettings.images && props.appSettings.images.image_tag.tag_color ? props.appSettings.images.image_tag.tag_color : constants.IMAGE_TAG_COLOR_DEFAULT);
  const [imageTagBackgroundColor, setImageTagBackgroundColor] = React.useState(props.appSettings && props.appSettings.images && props.appSettings.images.image_tag.tag_bg_color ? props.appSettings.images.image_tag.tag_bg_color : constants.IMAGE_TAG_BACKGROUND_COLOR_DEFAULT);
  const [imageTagPosition, setImageTagPosition] = React.useState(props.appSettings && props.appSettings.images && props.appSettings.images.image_tag.tag_position ? props.appSettings.images.image_tag.tag_position : constants.IMAGE_TAG_POSITION_DEFAULT);
  const [imageTagPositionFirst, setImageTagPositionFirst] = React.useState(imageTagPosition.split(/\s+/)[0]);
  const [imageTagPositionSecond, setImageTagPositionSecond] = React.useState(imageTagPosition.split(/\s+/)[1]);
  const [imageTagValue, setImageTagValue] = React.useState(props.appSettings && props.appSettings.images && props.appSettings.images.image_tag.tag_value ? props.appSettings.images.image_tag.tag_value : constants.IMAGE_TAG_VALUE_DEFAULT);

  class ColorPicker extends React.Component {
    state = {
      background: imageTagFontColor.hex,
    };

    handleChangeComplete = (color) => {
      setImageTagFontColor(color.hex);
    };

    render() {
      return (
        <CirclePicker
          className={classes.circlePicker}
          color={ this.state.background }
          onChangeComplete={ this.handleChangeComplete }
          colors={["#00000000", "#000000", "#ffffff", "#ff0000", "#00ff00", "#0000ff", "#ffff00"]}
          circleSize={40}
          circleSpacing={25}
        />
      );
    }
  }

  class BackgroundColorPicker extends React.Component {
    state = {
      background: imageTagBackgroundColor.hex,
    };

    handleChangeComplete = (color) => {
      setImageTagBackgroundColor(color.hex);
    };

    render() {
      return (
        <CirclePicker
          className={classes.circlePicker}
          color={ this.state.background }
          onChangeComplete={ this.handleChangeComplete }
          colors={["#00000000", "#000000", "#ffffff", "#ff0000", "#00ff00", "#0000ff", "#ffff00"]}
          circleSize={40}
          circleSpacing={25}
        />
      );
    }
  }


  const handleCheckChange = (event) => {
    setCheckEAN(!event.target.checked);
  };

  const handleChange = (event, value) => {
    setMandatoryFields(value);
  };

  const handleChangeInput = (event, value) => {
    setInputFields(value);
  };

  const handleLengthUnitChange = (event) => {
    setLengthUnit(event.target.value);
  }
  const handleVolumeUnitChange = (event) => {
    setVolumeUnit(event.target.value);
  }
  const handleImageTagValueChange = (event) => {
    setImageTagValue(event.target.value);
  }
  const handleWeightUnitChange = (event) => {
    setWeightUnit(event.target.value);
  }

  const handleImagesNumChange = (event) => {
    setNumberOfImages(event.target.value);
  }

  const handleRetentionDaysChange = (event) => {
    setRetentionDays(event.target.value);
  }

  const handleChangeSwitch = (event) => {
    setImageTagEnabled(event.target.checked);
  }

  const handleSkipImageChange = (event) => {
    setSkipImages(event.target.checked);
  }

  const handleRotateImageChange = (event) => {
    setRotateImages(event.target.checked);
  }

  const handleStorageSpaceChange = (event) => {
    setStorageSpace(event.target.value);
  }

  const handleImageTagFirstPositionChange = (event) => {
    setImageTagPositionFirst(event.target.value)
    setImageTagPosition(event.target.value + " " + imageTagPositionSecond)
  }

  const handleImageTagSecondPositionChange = (event) => {
    setImageTagPositionSecond(event.target.value)
    setImageTagPosition(imageTagPositionFirst + " " + event.target.value)
  }

   //notifications
   const handleSuccess = () => {
    enqueueSnackbar(t('notification_success_update_user_settings'), { 
      variant: 'success',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const handleError = () => {
    enqueueSnackbar(t('notification_error_update_user_settings'), { 
      variant: 'error',
      autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
    });
  }

  const onSave = e => {
    e.preventDefault();
    //update parameters
    let appSettingsBody = {
      "checks": {
          "ean_exists_check": checkEAN
      },
      "input_fields": inputFields,
      "mandatory_fields": mandatoryFields,
      "images": {
        "skip_images": !skipImages,
        "rotate_images": rotateImages,
        "storage_space": storageSpace,
        "image_tag":
        {
          "enabled": imageTagEnabled,
          "tag_size": imageTagFontSize,
          "tag_color": imageTagFontColor,
          "tag_bg_color": imageTagBackgroundColor,
          "tag_position": imageTagPosition,
          "tag_value": imageTagValue
        },
      },
      "max_number_images": numberOfImages,
      "measure_units": {
          "length": lengthUnit,
          "volume": volumeUnit,
          "weight": weightUnit
      },
      "retention_policy_day": retentionDays
  }
  

    axios.patch(endpoints.PARAMETERS,{"vosapp":appSettingsBody},httpConfig)
      .then(response => {
        props.setAppSettings(appSettingsBody);
        window.localStorage.setItem("vosapp", JSON.stringify(appSettingsBody))
        handleSuccess();
      })
      .catch(error => {
        handleError();
      })
  }


  return (
    <div className={classes.page}>
      <form className={classes.root} onSubmit={onSave}>
        <div className={classes.top}>
          <Typography className={classes.header} variant="h5" color="black" gutterBottom>
            {t('settings_vos_app')}
          </Typography>
          <Button type="submit" className={classes.button} variant="contained" color="secondary" textColor="white" startIcon={<SaveIcon />}>
                                {t('button_update')}
          </Button>
        </div>
        <Accordion defaultExpanded="true">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>{t('settings_app_fields')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.settings}>
            <Autocomplete
              className={classes.formControl}
              multiple
              options={constants.MANDATORY_FIELDS_LIST}
              onChange={handleChange}
              disableCloseOnSelect
              value={mandatoryFields}
              getOptionLabel={(option) => option}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </React.Fragment>
              )}
              style={{ width: 1000 }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label={t('settings_mandatory_fields')} placeholder={t('settings_mandatory_fields')} />
              )}
            />
            <Autocomplete
              className={classes.formControl}
              multiple
              options={constants.INPUT_FIELDS_LIST}
              onChange={handleChangeInput}
              disableCloseOnSelect
              value={inputFields}
              getOptionLabel={(option) => option}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </React.Fragment>
              )}
              style={{ width: 1000 }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label={t('settings_input_fields')} placeholder={t('settings_input_fields')} />
              )}
            />

            </AccordionDetails>
          </Accordion>
        <Accordion defaultExpanded="true">
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
           >
             <Typography className={classes.heading}>{t('settings_measure_units')}</Typography>
           </AccordionSummary>
           <AccordionDetails>
           <div>
           <FormControl className={classes.formControl} variant="outlined">
             <InputLabel id="length">{t('measure_unit_length')}</InputLabel>
             <Select
               id="length"
               label={t('measure_unit_length')}
               value={lengthUnit}
               className={classes.select}
               onChange={handleLengthUnitChange}
             >
               <MenuItem value={'mm'}>mm</MenuItem>
               <MenuItem value={'cm'}>cm</MenuItem>
               <MenuItem value={'dm'}>dm</MenuItem>
               <MenuItem value={'m'}>m</MenuItem>
               <MenuItem value={'inches'}>inches</MenuItem>
             </Select>
           </FormControl>
           <FormControl className={classes.formControl} variant="outlined">
             <InputLabel id="volume">{t('measure_unit_volume')}</InputLabel>
             <Select
               id="volume"
               label={t('measure_unit_volume')}
               value={volumeUnit}
               className={classes.select}
               onChange={handleVolumeUnitChange}
             >
               <MenuItem value={'mm'}>mm<sup>3</sup></MenuItem>
               <MenuItem value={'cm'}>cm<sup>3</sup></MenuItem>
               <MenuItem value={'dm'}>dm<sup>3</sup></MenuItem>
               <MenuItem value={'m'}>m<sup>3</sup></MenuItem>
             </Select>
           </FormControl>
           <FormControl className={classes.formControl} variant="outlined">
             <InputLabel id="weight">{t('measure_unit_weight')}</InputLabel>
             <Select
               id="weight"
               label={t('measure_unit_weight')}
               value={weightUnit}
               className={classes.select}
               onChange={handleWeightUnitChange}
             >
               <MenuItem value={'g'}>g</MenuItem>
               <MenuItem value={'kg'}>kg</MenuItem>
             </Select>
           </FormControl>
           </div>
           </AccordionDetails>
         </Accordion>
        <Accordion defaultExpanded="true">
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
           >
             <Typography className={classes.heading}>{t('settings_images')}</Typography>
           </AccordionSummary>
           <AccordionDetails style={{display: 'flex', flexFlow: "column"}}>
             <div style={{display: "flex", flexFlow: "row", alignItems: "flex-start"}}>
             <FormControlLabel
               control={
                 <Switch
                   checked={skipImages}
                   onChange={handleSkipImageChange}
                   color="secondary"
                 />
               }
               labelPlacement="top"
               label={t('settings_skip_images')}
               className={classes.formControlImageTag}
             />
             </div>
             {skipImages && (<div style={{display: "flex", flexFlow: "row", alignItems: "flex-start"}}>
               <FormControlLabel
                 control={
                   <TextField
                     type="number"
                     required
                     InputProps={{ inputProps: { min: 1, max: 5 } }}
                     style={{minWidth: "50px", marginRight: "73px"}}
                     onChange={handleImagesNumChange}
                     value={numberOfImages}
                     variant="standard"
                   />}
                 labelPlacement="top"
                 label={t('settings_number_of_images')}
                 className={classes.formControlImageTag}
               />
               <FormControlLabel
                 control={
                   <Switch
                     checked={rotateImages}
                     onChange={handleRotateImageChange}
                     color="secondary"
                   />
                 }
                 labelPlacement="top"
                 label={t('settings_rotate_images')}
                 className={classes.formControlImageTag}
               />
               <FormControlLabel
                 control={
                   <TextField
                     type="number"
                     required
                     InputProps={{ inputProps: {step: 100, min: 0, max: 500000 } }}
                     style={{minWidth: "50px", marginRight: "73px"}}
                     onChange={handleStorageSpaceChange}
                     value={storageSpace}
                     variant="standard"
                   />}
                 labelPlacement="top"
                 label={t('settings_storage_space')}
                 className={classes.formControlImageTag}
               />
               <FormControlLabel
                 control={
                   <Switch
                     checked={imageTagEnabled}
                     onChange={handleChangeSwitch}
                     color="secondary"
                   />
                 }
                 labelPlacement="top"
                 label={t('settings_image_tag')}
                 className={classes.formControlImageTag}
               />
             </div> )}
             {(imageTagEnabled && skipImages) && (
               <div className={classes.imageTagContainer}>
                 <div style={{display: "flex", flexFlow: "column"}}>
                   <FormControl component="fieldset" className={classes.formControl} style={{marginTop: "15px"}}>
                     <FormLabel className={classes.label} component="legend">{t('settings_image_tag_size')}</FormLabel>

                     <Box sx={{ maxWidth: 400 }}>
                       <Grid container spacing={2} alignItems="center">
                         <Grid item xs>
                           <Slider
                             sx={{
                               color: '#ef7d00',
                             }}
                             size="medium"
                             aria-label="size"
                             defaultValue={5}
                             valueLabelDisplay="auto"
                             step={1}
                             marks
                             min={1}
                             max={10}
                             value={typeof parseInt(imageTagFontSize) === 'number' ? parseInt(imageTagFontSize) : 0}
                             onChange={event => setImageTagFontSize(event.target.value)}
                           />
                         </Grid>
                       </Grid>
                     </Box>
                   </FormControl>
                   <FormControl component="fieldset" className={classes.tagPositionContainer} style={{marginTop: "15px"}}>
                     <FormLabel className={classes.label} component="legend">{t('settings_image_tag_position')}</FormLabel>
                     <RadioGroup row aria-label="positionVertical" name="positionVertical" defaultValue="Bottom" value={imageTagPositionFirst} onChange={handleImageTagFirstPositionChange}>
                       <FormControlLabel
                         value="Bottom"
                         control={<Radio color="primary" />}
                         label="Bottom"
                         labelPlacement="top"
                       />
                       <FormControlLabel
                         value="Center"
                         control={<Radio color="primary" />}
                         label="Center"
                         labelPlacement="top"
                       />
                       <FormControlLabel
                         value="Top"
                         control={<Radio color="primary" />}
                         label="Top"
                         labelPlacement="top"
                       />
                     </RadioGroup>
                     <RadioGroup row aria-label="positionHorizontal" name="positionHorizontal" defaultValue="Left" value={imageTagPositionSecond} onChange={handleImageTagSecondPositionChange}>
                       <FormControlLabel
                         value="Left"
                         control={<Radio color="primary" />}
                         label="Left"
                         labelPlacement="top"
                       />
                       <FormControlLabel
                         value="Middle"
                         control={<Radio color="primary" />}
                         label="Middle"
                         labelPlacement="top"
                       />
                       <FormControlLabel
                         value="Right"
                         control={<Radio color="primary" />}
                         label="Right"
                         labelPlacement="top"
                       />
                     </RadioGroup>
                   </FormControl>
                   <FormControl component="fieldset" className={classes.formControl} style={{marginTop: "15px"}}>
                     <FormLabel className={classes.label} component="legend">{t('settings_image_tag_color')}</FormLabel>
                     <ColorPicker></ColorPicker>
                   </FormControl>
                   <FormControl component="fieldset" className={classes.formControl} style={{marginTop: "15px"}}>
                     <FormLabel className={classes.label} component="legend">{t('settings_image_tag_bg_color')}</FormLabel>
                     <BackgroundColorPicker></BackgroundColorPicker>
                   </FormControl>
                   <FormControl className={classes.formControl} style={{marginTop: "20px", maxWidth: "250px"}} variant="outlined">
                     <InputLabel id="tagValue">{t('settings_image_tag_value')}</InputLabel>
                     <Select
                       id="tagValue"
                       label={t('settings_image_tag_value')}
                       value={imageTagValue}
                       className={classes.select}
                       onChange={handleImageTagValueChange}
                     >
                       <MenuItem value={'articlenumber'}>articlenumber</MenuItem>
                       <MenuItem value={'barcode'}>barcode</MenuItem>
                       <MenuItem value={'description'}>description</MenuItem>
                       <MenuItem value={'handling_unit'}>handling_unit</MenuItem>
                       <MenuItem value={'handling_count'}>handling_count</MenuItem>
                       <MenuItem value={'custom_string1'}>custom_string1</MenuItem>
                       <MenuItem value={'custom_string2'}>custom_string2</MenuItem>
                       <MenuItem value={'custom_int1'}>custom_int1</MenuItem>
                       <MenuItem value={'custom_int2'}>custom_int2</MenuItem>
                       <MenuItem value={'custom_float'}>custom_float</MenuItem>
                       <MenuItem value={'measure_id'}>measure_id</MenuItem>
                       <MenuItem value={'length'}>length</MenuItem>
                       <MenuItem value={'volume'}>volume</MenuItem>
                       <MenuItem value={'weight'}>weight</MenuItem>
                       <MenuItem value={'image_path1'}>image_path1</MenuItem>
                       <MenuItem value={'image_path2'}>image_path2</MenuItem>
                       <MenuItem value={'image_path3'}>image_path3</MenuItem>
                       <MenuItem value={'timestamp'}>timestamp</MenuItem>
                     </Select>
                   </FormControl>
                 </div>
                 <div className={classes.container}>
                   <FormLabel>{t('settings_image_tag_preview')}</FormLabel>
                   <img className={classes.img} src={logo}/>
                   <p className={classNames(imageTagPositionFirst === "Bottom" ? classes.tagBottom : (imageTagPositionFirst === "Top" ? classes.tagTop : (imageTagPositionFirst === "Center" ? classes.tagCenter : classes.tagBottom)), imageTagPositionSecond === "Left" ? classes.tagLeft : (imageTagPositionSecond === "Right" ? classes.tagRight : (imageTagPositionSecond === "Middle" ? classes.tagMiddle : classes.tagLeft)))}
                      style={{fontSize: (imageTagFontSize * 3 + 15) + "px",color: imageTagFontColor, background: imageTagBackgroundColor}}>{imageTagValue}</p>
                 </div>
               </div>
               )}
           </AccordionDetails>
         </Accordion>
        <Accordion defaultExpanded="true">
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
           >
             <Typography className={classes.heading}>{t('settings_others')}</Typography>
           </AccordionSummary>
           <AccordionDetails>
          <div>
           <div className={classes.settings}>
            <TextField
                className={classes.formControl}
                label={t('settings_retention_days')}
                type="number"
                required
                InputProps={{ inputProps: { min: 1, max: 365 } }}
                onChange={handleRetentionDaysChange}
                value={retentionDays}
                variant="outlined"
              />
            </div>
            <div>
              <Typography variant="body1" className={classes.text}>Allow users to measure articles that do have no VOSPortal entry</Typography>
              <Checkbox
                  className={classes.top}
                  checked={!checkEAN}
                  onChange={handleCheckChange}>
              </Checkbox>
            </div>
           </div>
           </AccordionDetails>
         </Accordion>
      </form>
    </div>
  );
}

export default CategoryVOSApp;