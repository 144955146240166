/**
 * CSV import functions
 * 
 * @author JHO 
 * Last changed: 07.02.2023
 * Changes: Initial implementation
 */

export function buildImportArticlesBody(csvRows) {
    let jsonData = [];

    csvRows.forEach(row => {
        const articleId = row.articleID;
        const bundle = row.handlingUnit;
        const barcode = row.barcodes;
          // check if articleId exists in jsonData
        const articleIndex = jsonData.findIndex(item => item.articleID === articleId);
        if (articleIndex >= 0) {
            // articleId already exists, check if bundle exists
            const bundleIndex = jsonData[articleIndex].bundles.findIndex(item => item.handlingUnit === bundle);
            if (bundleIndex >= 0 && barcode !== "") {
            // bundle already exists, add barcode to it
            jsonData[articleIndex].bundles[bundleIndex].barcodes = addBarcodesJson(barcode,jsonData[articleIndex].bundles[bundleIndex].barcodes);
            } else {
            // bundle doesn't exist, create a new bundle with barcode
            if(row.barcodes !== "") {row.barcodes = addBarcodesJson(row.barcodes,[]);}
            else{ delete row.barcodes}
            jsonData[articleIndex].bundles = addBundlesJson(row.articleID,row.handlingUnit,row.itemsPerHU,"Portal import",row.barcodes, jsonData[articleIndex].bundles);
            }
        } else {
            // articleId doesn't exist, create a new article with bundle and barcode
            addArticleElement(row,jsonData)
  }

    });






    const body = {
        "data": jsonData
    }
    return body;
} 

function addArticleElement(row,data) {
    row.source = "Portal import";
    if(row.barcodes !== "") {row.barcodes = addBarcodesJson(row.barcodes,[]);}
    else{ delete row.barcodes}
    row.bundles = addBundlesJson(row.articleID,row.handlingUnit,row.itemsPerHU,row.source,row.barcodes,[]);
    data.push(row);
}

function updateArticleElement(row,data,idx) {
    data[idx].barcodes = addBarcodesJson(row.barcodes,data[idx].barcodes);
    data[idx].bundles = addBundlesJson(row.articleID,row.handlingUnit,row.itemsPerHU,row.source,row.barcodes,data[idx].bundles);
}

function getArticleIdxIfExists(articleID,data) {
    for (let i = 0; i < data.length; i++) {
        if (data[i].articleID === articleID) {
            return i;
        }
    }
    return null;
}

function addBarcodesJson(barcode,barcodesList) {
    const barcodeElement = {
        "barcode":  barcode,
        "type": ""
    }
    barcodesList.push(barcodeElement);
    return barcodesList;
}

function addBundlesJson(articleId,hu,itemsPerHU,source,barcode,bundleList) {
    const bundleElement = {
        "articleID": articleId,
        "handlingUnit": hu,
        "itemsPerHU": itemsPerHU,
        "source": source,
        "barcodes": barcode
    }
    bundleList.push(bundleElement);
    return bundleList;
}