/**
 * Settings general category component
 * 
 * @author JHO 
 * Last changed: 30.11.2021
 * Changes: PBI-568 styling
 */

import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as endpoints from '../../../service/endpoints';
import * as constants from '../../../common/constants';

const useStyles = makeStyles((theme) => ({
    header: {
      float: 'left',
      color: "black",
      textStyle: "bold",
      padding: theme.spacing(2)
    },
    page: {
      minHeight: '0',
      boxShadow: "5px 5px 30px -12px rgba(0,0,0,0.3)",
      "&:hover": {
        boxShadow: "0 10px 50px -12px rgba(0,0,0,0.3)"
      }
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    fieldInput: {
      whiteSpace: "nowrap",
      display: 'inline',
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(2)
    },
    button: {
      float: 'right',
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
      marginRight:theme.spacing(2)
    },
    chip: {
      margin: theme.spacing(0.5),
      float: 'right',
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    top: {
        overflow: "hidden"
    },
    child: {
      whiteSpace: 'nowrap',

    }
}));

function CategoryGeneral(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const httpConfig = {
      headers: { Authorization: `Bearer ${props.token}` }
    };

    const [sites, setSites] = React.useState(
      props.sites.map((item) => {return { key: item.id, label: item.name }})
    );

    const [useCustomFields, setUseCustomFields] = React.useState({
      useCustomString1: props.customFields ? props.customFields.custom_string_1.enabled : false,
      useCustomString2: props.customFields ? props.customFields.custom_string_2.enabled : false,
      useCustomInt1: props.customFields ? props.customFields.custom_int_1.enabled : false,
      useCustomInt2: props.customFields ? props.customFields.custom_int_2.enabled : false,
      useCustomFloat: props.customFields ? props.customFields.custom_float.enabled : false
    });
    const [customFieldsName, setCustomFieldsName] = React.useState({
      customString1: props.customFields ? props.customFields.custom_string_1.name : "",
      customString2: props.customFields ? props.customFields.custom_string_2.name : "",
      customInt1: props.customFields ? props.customFields.custom_int_1.name: "",
      customInt2: props.customFields ? props.customFields.custom_int_2.name: "",
      customFloat: props.customFields ? props.customFields.custom_float.name: ""
    });
    const { useCustomString1, useCustomString2, useCustomInt1, useCustomInt2, useCustomFloat } = useCustomFields;
    const { customString1, customString2, customInt1, customInt2, customFloat } = customFieldsName;

    const [useHandlingUnits, setUseHandlingUnits] = React.useState({
      useHandlingUnit1: props.handlingUnits ? props.handlingUnits.unit1.enabled : false,
      useHandlingUnit2: props.handlingUnits  ? props.handlingUnits.unit2.enabled : false,
      useHandlingUnit3: props.handlingUnits  ? props.handlingUnits.unit3.enabled : false,
      useHandlingUnit4: props.handlingUnits  ? props.handlingUnits.unit4.enabled : false,
    });
    const [handlingUnitsName, setHandlingUnitsName] = React.useState({
      handlingUnit1: props.handlingUnits ? props.handlingUnits.unit1.name : '',
      handlingUnit2: props.handlingUnits  ? props.handlingUnits.unit2.name : '',
      handlingUnit3: props.handlingUnits  ? props.handlingUnits.unit3.name : '',
      handlingUnit4: props.handlingUnits  ? props.handlingUnits.unit4.name : '',
    });
    const { useHandlingUnit1, useHandlingUnit2, useHandlingUnit3, useHandlingUnit4} = useHandlingUnits;
    const { handlingUnit1, handlingUnit2, handlingUnit3, handlingUnit4} = handlingUnitsName;

    const [addSite, setAddSite] = React.useState(false);
    const [newSite, setNewSite] = React.useState("");

    const handleChangeCheckbox = (event) => {
      setUseCustomFields({ ...useCustomFields, [event.target.name]: event.target.checked });
    };

    const handleChangeText = (event) => {
      setCustomFieldsName({ ...customFieldsName, [event.target.name]: event.target.value});
    };

    const handleChangeUnitCheckbox = (event) => {
      setUseHandlingUnits({ ...useHandlingUnits, [event.target.name]: event.target.checked });
    };

    const handleChangeUnitText = (event) => {
      setHandlingUnitsName({ ...handlingUnitsName, [event.target.name]: event.target.value});
    };

    const handleSuccess = () => {
      enqueueSnackbar(t('notification_success_update_custom_fields'), { 
        variant: 'success',
        autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
      });
    }

    const handleError = () => {
      enqueueSnackbar(t('notification_error_update_custom_fields'), { 
        variant: 'error',
        autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
      });
    }

    const handleSiteError = (type) => {
      var msg = ''
      switch(type) {
        case 'exists':  { msg = t('notification_error_add_site'); break;}
        default: { msg = t('notification_error_general'); }
      }
      enqueueSnackbar(msg, { 
        variant: 'error',
        autoHideDuration: props.userSettings.notification_time ? props.userSettings.notification_time*1000 : constants.NOTIFICATION_TIME_DEFAULT*1000
      });
    }

    const handleNewSite = (event) => {
      setNewSite(event.target.value)
    }

    const handleSitesDelete = (site) => () => {
      setSites((chips) => chips.filter((chip) => chip.key !== site.key));
      axios.delete(endpoints.SITES + '/' + site.key,httpConfig)
        .then(response => {
          axios.get(endpoints.SITES, httpConfig)
            .then(response => {
              this.props.setSites(response.data.data);
              window.localStorage.setItem("sites", JSON.stringify(response.data.data));
              })
              .catch(err => {
            
              })
          handleSuccess();
        })
        .catch(error => {
          handleError();
        })
    }

    const onAdd = () => {
      setAddSite(true)
    }

    const onAddSite = () => {
      setAddSite(false)
      axios.post(endpoints.SITES,{ "name": newSite},httpConfig)
        .then(response => {
          setSites([...sites, {"key": response.data.id, "label": newSite}])
          axios.get(endpoints.SITES, httpConfig)
            .then(response => {
              props.setSites(response.data.data);
              window.localStorage.setItem("sites", JSON.stringify(response.data.data));
              handleSuccess();
              })
              .catch(err => {
                console.log(err)
                handleSiteError('exists');
              })
        })
        .catch(error => {
          handleSiteError('exists');
        })
      setNewSite("")
    }

    const updateHandlingUnits = () => {
      let customUnitsBody = {
        "unit1": {
          "name": handlingUnit1,
          "enabled": useHandlingUnit1
        },
        "unit2": {
          "name": handlingUnit2,
          "enabled": useHandlingUnit2
        },
        "unit3": {
          "name": handlingUnit3,
          "enabled": useHandlingUnit3
        },
        "unit4": {
          "name": handlingUnit4,
          "enabled": useHandlingUnit4
        }
      }
      axios.patch(endpoints.PARAMETERS,{"handling_units":customUnitsBody},httpConfig)
        .then(response => {
          props.setHandlingUnits(customUnitsBody)
          window.localStorage.setItem("handlingUnits", JSON.stringify(customUnitsBody));
          handleSuccess();
        })
        .catch(error => {
          console.log(error);
          handleError();
        })
    }

    const updateCustomFields = () => {
      let customFieldsBody = {
        "custom_string_1": {
          "name": customString1,
          "enabled": useCustomString1
        },
        "custom_string_2": {
          "name": customString2,
          "enabled": useCustomString2
        },
        "custom_int_1": {
          "name": customInt1,
          "enabled": useCustomInt1
        },
        "custom_int_2": {
          "name": customInt2,
          "enabled": useCustomInt2
        },
        "custom_float": {
          "name": customFloat,
          "enabled": useCustomFloat
        }
      }
      axios.patch(endpoints.PARAMETERS,{"custom_fields":customFieldsBody},httpConfig)
        .then(response => {
          props.setCustomFields(customFieldsBody)
          window.localStorage.setItem("customFields", JSON.stringify(customFieldsBody));
          updateHandlingUnits();
        })
        .catch(error => {
          handleError();
        })
    }

    const onSave = () => {
      //update parameters
      updateCustomFields();
    }

    return (
      <div className={classes.page}>
        <div className={classes.top}>
          <Typography className={classes.header} variant="h5" color="black" gutterBottom>
            {t('settings_general')}
          </Typography>
          <Button className={classes.button} onClick={onSave} variant="contained" color="secondary" textColor="white" startIcon={<SaveIcon />}>
                              {t('button_update')}
            </Button>
        </div>
        <Accordion defaultExpanded="true">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={classes.heading}>{t('settings_custom_fields')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <form>
            <div className={classes.fieldInput}>
              <TextField 
                name='customString1'
                label="Custom String 1" 
                variant="outlined" 
                onChange={handleChangeText}
                disabled={!useCustomString1} 
                value={customString1}/>
              <Checkbox
                checked={useCustomString1}
                onChange={handleChangeCheckbox}
                name='useCustomString1'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <div className={classes.fieldInput}>
            <TextField className={classes.child}
                name='customString2'
                label="Custom String 2" 
                variant="outlined" 
                onChange={handleChangeText}
                disabled={!useCustomString2} 
                value={customString2}/>
              <Checkbox className={classes.child}
              checked={useCustomString2}
              onChange={handleChangeCheckbox}
              name='useCustomString2'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <div className={classes.fieldInput}>
            <TextField className={classes.child}
                name='customInt1'
                label="Custom Int 1" 
                variant="outlined" 
                onChange={handleChangeText}
                disabled={!useCustomInt1} 
                value={customInt1}/>
              <Checkbox className={classes.child}
              checked={useCustomInt1}
              onChange={handleChangeCheckbox}
              name='useCustomInt1'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <div className={classes.fieldInput}>
            <TextField className={classes.child}
                name='customInt2'
                label="Custom Int 2" 
                variant="outlined" 
                onChange={handleChangeText}
                disabled={!useCustomInt2} 
                value={customInt2}/>
              <Checkbox className={classes.child}
              checked={useCustomInt2}
              onChange={handleChangeCheckbox}
              name='useCustomInt2'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <span className={classes.fieldInput}>
              <TextField className={classes.child}
                name='customFloat'
                label="Custom Float" 
                variant="outlined" 
                onChange={handleChangeText}
                disabled={!useCustomFloat} 
                value={customFloat}/>
              <Checkbox className={classes.child}
              checked={useCustomFloat}
              onChange={handleChangeCheckbox}
              name='useCustomFloat'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </span>
            </form>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded="true">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={classes.heading}>{t('settings_sites')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Paper component="ul" className={classes.root}>
            {sites.map((data) => {
              return (
                <li key={data.key}>
                  <Chip
                    label={data.label}
                    onDelete={data.label === 'React' ? undefined : handleSitesDelete(data)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Paper>
          {!addSite && (<IconButton onClick={onAdd}>
            <AddIcon />
          </IconButton>)}
          {addSite && (<TextField 
                name='newSite'
                label="Sitename" 
                variant="outlined" 
                onChange={handleNewSite}
                value={newSite}/>)}
          {addSite && ( <Button className={classes.button} onClick={onAddSite} variant="contained" color="secondary" textColor="white" startIcon={<AddIcon />}>
              {t('button_add')}
          </Button>)}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded="true">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={classes.heading}>{t('settings_handling_units')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <form>
            <div className={classes.fieldInput}>
              <TextField 
                name='handlingUnit1'
                label={t('settings_hu1')}
                variant="outlined" 
                onChange={handleChangeUnitText}
                disabled={!useHandlingUnit1} 
                value={handlingUnit1}/>
              <Checkbox
                checked={useHandlingUnit1}
                onChange={handleChangeUnitCheckbox}
                name='useHandlingUnit1'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <div className={classes.fieldInput}>
            <TextField 
                name='handlingUnit2'
                label={t('settings_hu2')}
                variant="outlined" 
                onChange={handleChangeUnitText}
                disabled={!useHandlingUnit2} 
                value={handlingUnit2}/>
              <Checkbox
                checked={useHandlingUnit2}
                onChange={handleChangeUnitCheckbox}
                name='useHandlingUnit2'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <div className={classes.fieldInput}>
              <TextField 
                name='handlingUnit3'
                label={t('settings_hu3')}
                variant="outlined" 
                onChange={handleChangeUnitText}
                disabled={!useHandlingUnit3} 
                value={handlingUnit3}/>
              <Checkbox
                checked={useHandlingUnit3}
                onChange={handleChangeUnitCheckbox}
                name='useHandlingUnit3'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <div className={classes.fieldInput}>
            <TextField 
                name='handlingUnit4'
                label={t('settings_hu4')}
                variant="outlined" 
                onChange={handleChangeUnitText}
                disabled={!useHandlingUnit4} 
                value={handlingUnit4}/>
              <Checkbox
                checked={useHandlingUnit4}
                onChange={handleChangeUnitCheckbox}
                name='useHandlingUnit4'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            </form>
          </AccordionDetails>
        </Accordion>
        
      </div>
    );
}

export default CategoryGeneral;