/**
 * Forgot Password component
 * 
 * @author LHO 
 * Last changed: 16.08.2021
 * Changes: Multilanguage support
 */
 import './SignUp.css';
 import React from 'react';
 import axios from 'axios';
 import PropTypes from 'prop-types';
 import Avatar from '@material-ui/core/Avatar';
 import Typography from '@material-ui/core/Typography';
 import Button from '@material-ui/core/Button';
 import CssBaseline from '@material-ui/core/CssBaseline';
 import TextField from '@material-ui/core/TextField';
 import Box from '@material-ui/core/Box';
 import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'; import { withStyles } from '@material-ui/core/styles';
 import Container from '@material-ui/core/Container';
 import Snackbar from '@material-ui/core/Snackbar';
 import MuiAlert from '@material-ui/lab/Alert';
 import * as endpoints from '../../service/endpoints';
 import InfoIcon from '@material-ui/icons/Info';
 import { withTranslation } from 'react-i18next';
import {render} from "enzyme";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {InputLabel, OutlinedInput} from "@mui/material";
import {useSnackbar} from "notistack";
import * as constants from "../../common/constants";

const useStyles = theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  });  

function SignUp (props){
  const { classes } = props;
  const { t } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = React.useState({
    email: "",
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    permissionLevel: 3,
    submitted: false,
    error: false,
    messageFromServer: '',
    confirmPassword: "",
    organization: "",
    country: "",
    showPassword: false,
    showConfirmPassword: false
  });


  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setState({
      ...state,
      showConfirmPassword: !state.showConfirmPassword,
    });
  };

  const handleReset = (event) => {
    event.preventDefault();
    setState({
      ...state,
      submitted: true,
    });
    getReset();
  };

  const setEmail = (event) => {
    setState({
      ...state,
      email: event.target.value,
    });
  }

  const setFirstName = (event) => {
    setState({
      ...state,
      firstName: event.target.value,
    });
  }

  const setLastName = (event) => {
    setState({
      ...state,
      lastName: event.target.value,
    });
  }

  const setUserName = (event) => {
    setState({
      ...state,
      userName: event.target.value,
    });
  }

  const setPassword = (event) => {
    setState({
      ...state,
      password: event.target.value,
    });
  }

  const setConfirmPassword = (event) => {
    setState({
      ...state,
      confirmPassword: event.target.value,
    });
  }

  const setOrganization = (event) => {
    setState({
      ...state,
      organization: event.target.value,
    });
  }

  const setCountry = (event) => {
    setState({
      ...state,
      country: event.target.value,
    });
  }

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const getReset = () => {
    if(state.email !== "") {
      const authBody = {
        "firstName": state.firstName,
        "lastName": state.lastName,
        "userName": state.userName,
        "email": state.email,
        "password": state.password,
        "organization": state.organization,
        "country": state.country,
        "permissionLevel": 3,
      };
      axios.post(endpoints.SIGNUP,authBody)
        .then(async response => {
          if (response.statusText === "Created") {
            setState({
              ...state,
              messageFromServer: 'reset email sent'
            });
            enqueueSnackbar(t('notification_success_signup'), {
              variant: 'success',
              autoHideDuration: 3000
            });
            await delay(1500)
            window.location.href = "auth";
          }
        })
        .catch(error => {
          setState({
            ...state,
            error: true
          })
          if(error.response.status === 409)
          {
            enqueueSnackbar(t('notification_email_already_in_use'), {
              variant: 'error',
              autoHideDuration: 3000
            });
          }
        })
    }
  }

  const handleClose = (event, reason) => {
    setState({
      ...state,
      error: false
    });
  };

  return (
    <Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <PersonAddOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t('user_create')}
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          //id="firstname"
          label={t('input_first_name')}
          name="firstname"
          autoComplete="firstname"
          autoFocus
          onChange={setFirstName}
          error={state.firstName === "" && state.submitted}
          helperText={state.firstName === "" && state.submitted ? t('input_first_name') : ' '}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          //id="lastname"
          label={t('input_last_name')}
          name="lastName"
          autoComplete="lastname"
          //autoFocus
          onChange={setLastName}
          error={state.lastName === "" && state.submitted}
          helperText={state.lastName === "" && state.submitted ? t('input_last_name') : ' '}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          //id="userName"
          label={t('input_user_name')}
          name="userName"
          autoComplete="userName"
          //autoFocus
          onChange={setUserName}
          error={state.userName === "" && state.submitted}
          helperText={state.userName === "" && state.submitted ? t('input_user_name') : ' '}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          //id="email"
          label={t('table_header_email')}
          name="email"
          autoComplete="email"
          //autoFocus
          onChange={setEmail}
          error={state.email === "" && state.submitted}
          helperText={state.email === "" && state.submitted ? t('error_email_empty') : ' '}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          //id="password"
          label={t('input_password')}
          name="password"
          autoComplete="password"
          type={state.showPassword ? 'text' : 'password'}
          //autoFocus
          onChange={setPassword}
          error={state.password === "" && state.submitted}
          helperText={state.password === "" && state.submitted ? t('error_email_empty') : ' '}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  >
                  {state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <InfoIcon/>
        <p className="MuiFormHelperText-root">
          {t('password_header')}
        </p>
          <ul className="MuiFormHelperText-root">
            <li>{t('password_line1')}</li>
            <li>{t('password_line2')}</li>
            <li>{t('password_line3')}</li>
            <li>{t('password_line4')}</li>
          </ul>
          <p className="MuiFormHelperText-root">
          {t('password_footer')}
          <br></br>
          {t('password_length')}
        </p>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          //id="confirmPassword"
          label={t('input_confirm_password')}
          name="confirmPassword"
          autoComplete="confirmPassword"
          type={state.showConfirmPassword ? 'text' : 'password'}
          //autoFocus
          onChange={setConfirmPassword}
          error={state.confirmPassword !== state.password}
          helperText={state.confirmPassword !== state.password ? t('error_password_not_matching') : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowConfirmPassword}
                >
                  {state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          //id="organization"
          label={t('input_organization')}
          name="organization"
          autoComplete="organization"
          //autoFocus
          onChange={setOrganization}
          error={state.organization === "" && state.submitted}
          helperText={state.organization === "" && state.submitted ? t('error_organization') : ''}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          //id="country"
          label={t('input_country')}
          name="country"
          autoComplete="country"
          //autoFocus
          onChange={setCountry}
          error={state.country === "" && state.submitted}
          helperText={state.country === "" && state.submitted ? t('error_country') : ''}
        />


        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleReset}
        >
          {t('button_create_user')}
        </Button>
      </form>
    </div>
    {state.messageFromServer === 'reset email sent' && (
      <div>
        {/*<h3>{t('notification_success_create_user')}</h3>*/}
      </div>
    )}
    <Box mt={8}>
    </Box>
    {/*<Snackbar open={state.error} autoHideDuration={6000} onClose={handleClose}>*/}
    {/*  <MuiAlert severity="error">{t('notification_error_create_user')}</MuiAlert>*/}
    {/*</Snackbar>*/}
  </Container>
  );
}
  
  SignUp.propTypes = {
    classes: PropTypes.object.isRequired,
  }
  
  export default withTranslation()(withStyles(useStyles, { withTheme: true })(SignUp));