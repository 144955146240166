/**
 * Settings component
 * 
 * @author JHO 
 * Last changed: 23.12.2021
 * Changes: PBI-674 Added portal table column settings
 */
 import './Settings.css';
 import * as routes from '../../routing/routes';
  import React, { useState, useEffect } from 'react';
 import Paper from '@material-ui/core/Paper';
 import Grid from '@material-ui/core/Grid';
 import Typography from '@material-ui/core/Typography';
 import Divider from '@material-ui/core/Divider';
 import { makeStyles } from '@material-ui/core/styles';
 import { useTranslation } from "react-i18next";
 import CategoryWebPortal from './categories/CategoryWebPortal';
 import CategoryVOSApp from './categories/CategoryVOSApp';
 import CategoryGeneral from './categories/CategoryGeneral';
 import CategoryData from './categories/CategoryData';
 import CategoryOthers from './categories/CategoryOthers';
 import CategoryPermissions from './categories/CategoryPermissions';
 import withAppInsights from './../../common/appInsights'

 const useStyles = makeStyles((theme) => ({
   root: {
       flexGrow: 1,
       marginLeft: "60px",
       marginRight: "12px",
       marginTop: "64px",
       height: "100%",
   },
   paper: {
       textAlign: 'left',
       color: theme.palette.text.secondary
   },
   item: {
     padding: theme.spacing(1),
     cursor:"pointer",
     '&:hover': {
       background: theme.palette.secondary.main,
       '& $text': {
         color: "white"
       }
     },
   },
   itemClicked: {
     padding: theme.spacing(1),
     background: theme.palette.secondary.main,
     '& $text': {
       color: "white"
      }
     },
   header: {
     paddingLeft: "15px",
     color: "black",
     textStyle: "bold",
     padding: theme.spacing(2),
   },
 horizontal: {
     display: "inline"
 },
 icon: {
     float: "left",
     marginLeft: theme.spacing(2)
 },
 text: {
     padding: theme.spacing(2),
     marginBottom: "0 !important",
     overflow: "hidden",
 }
 }));
 
 function Settings(props) {
     const classes = useStyles();
     const [categoryItem, setCategoryItem] = useState(0);
     const { t } = useTranslation();

     useEffect(() => {
        window.sessionStorage.setItem("lastRoute", routes.SETTINGS);
     });
     
     return (
       <div className={classes.root}>
         <Grid container spacing={2} style={{height: "100%"}}>
           <Grid item xs={2} className="settings-container">
             <div className="settings">
                 <Typography className={classes.header} variant="h5" color="black" gutterBottom>
                   {t('component_header_settings')}
                 </Typography>
               <Divider />
               <div className={categoryItem === 0 ? classes.itemClicked : classes.item} onClick={() => {setCategoryItem(0)}}>
                 <Typography className={classes.text} variant="subtitle1" gutterBottom>
                   {t('settings_web_portal')}
                 </Typography>
                 </div>
               <Divider />
               { (props.permissionSettings && props.permissionLevel >= props.permissionSettings.settings.vos_app) &&
                    <div className={categoryItem === 1 ? classes.itemClicked : classes.item} onClick={() => {setCategoryItem(1)}}>
                    <Typography className={classes.text} variant="subtitle1" gutterBottom>
                      {t('settings_vos_app')}
                    </Typography>
                    </div>
                  }
               <Divider />
               { (props.permissionSettings && props.permissionLevel >= props.permissionSettings.settings.general) &&
                    <div className={categoryItem === 2 ? classes.itemClicked : classes.item} onClick={() => {setCategoryItem(2)}}>
                    <Typography className={classes.text} variant="subtitle1" gutterBottom>
                      {t('settings_general')}
                    </Typography>
                    </div>    
                  }
               <Divider />
               { (props.permissionSettings && props.permissionLevel >= props.permissionSettings.settings.export) &&
                    <div className={categoryItem === 3 ? classes.itemClicked : classes.item} onClick={() => {setCategoryItem(3)}}>
                    <Typography className={classes.text} variant="subtitle1" gutterBottom>
                      {t('settings_data')}
                    </Typography>
                    </div>
                  }
               <Divider />
               { (props.permissionSettings && props.permissionLevel >= 3) &&
                  <div className={categoryItem === 4 ? classes.itemClicked : classes.item} onClick={() => {setCategoryItem(4)}}>
                  <Typography className={classes.text} variant="subtitle1" gutterBottom>
                    {t('settings_permissions')}
                  </Typography>
                  </div>
                  }
               <Divider />
               { (props.permissionSettings && props.permissionLevel >= props.permissionSettings.settings.others) &&
                    <div className={categoryItem === 5 ? classes.itemClicked : classes.item} onClick={() => {setCategoryItem(5)}}>
                    <Typography className={classes.text} variant="subtitle1" gutterBottom>
                      {t('settings_others')}
                    </Typography>
                    </div>
                  }
               </div>
           </Grid>
           <Grid item xs={10} ys={10}>
             <Paper className={classes.paper} elevation={0}>
                 {categoryItem === 0 && (<CategoryWebPortal token={props.token} userId={props.userId} userSettings={props.userSettings} setUserSettings={props.setUserSettings} portalSettings={props.portalSettings} setPortalSettings={props.setPortalSettings} permissionLevel={props.permissionLevel}/>)}
                 {categoryItem === 1 && (<CategoryVOSApp token={props.token} appSettings={props.appSettings} setAppSettings={props.setAppSettings} userSettings={props.userSettings}/>)}
                 {categoryItem === 2 && (<CategoryGeneral token={props.token} sites={props.sites} setSites={props.setSites} customFields={props.customFields} setCustomFields={props.setCustomFields} handlingUnits={props.handlingUnits} setHandlingUnits={props.setHandlingUnits} userSettings={props.userSettings}/>)}
                 {categoryItem === 3 && (<CategoryData token={props.token} csvConfig={props.csvConfig} setCSVConfig={props.setCSVConfig} userSettings={props.userSettings}/>)}
                 {categoryItem === 4 && (<CategoryPermissions token={props.token} permissionSettings={props.permissionSettings} setPermissionSettings={props.setPermissionSettings} userSettings={props.userSettings}/>)}
                 {categoryItem === 5 && (<CategoryOthers/>)}
             </Paper>
           </Grid>
         </Grid>
       </div>
       );
 }
 export default withAppInsights(Settings);