/**
 * Info component
 * 
 * @author JHO 
 * Last changed: 13.09.2021
 * Changes: PBI-478 Save current rout to sessionStorage
 */
import './Info.css';
import * as routes from '../../routing/routes';
import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import QRCode from "react-qr-code";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import CodeIcon from '@material-ui/icons/Code';
import NotesIcon from '@material-ui/icons/Notes';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from "react-i18next";
import CHANGELOG from '../../docs/CHANGELOG.md'
import * as constants from '../../common/constants'
import badge from '../../common/google-play-badge.png'
import * as endpoints from '../../service/endpoints';
import logo from "../../common/lnc_logo_appbar_large.png";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadSvg from '../../common/download.svg';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "82px",
    marginRight: "12px",
    marginTop: "82px",
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
    boxShadow: "5px 5px 30px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 10px 50px -12px rgba(0,0,0,0.3)"
    }
  },
  paperQR: {
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
    boxShadow: "5px 5px 30px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 10px 50px -12px rgba(0,0,0,0.3)"
    }
  },
  header: {
    textAlign: 'left',
    padding: theme.spacing(2),
    color: "black",
    textStyle: "bold"
  },
  horizontal: {
    display: "inline"
  },
  icon: {
    float: "left",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  text: {
    overflow: "hidden",
    margin: theme.spacing(2),
  },
  changelog: {
    padding: theme.spacing(3)
  },
  badge : {
    maxWidth: "50%"
  },
  qr: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(10)
  }
}));

function Info() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ content, setContent] = useState({md: ""});

  useEffect(() => {
    window.sessionStorage.setItem("lastRoute", routes.INFO);
    fetch(CHANGELOG)
            .then((res) => res.text())
            .then((md) => {
                setContent({ md })
            })
  });

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Paper className={classes.paper} style={{height: "92.5%"}}>
                <Typography className={classes.header} variant="h5" color="black" gutterBottom>
                  {t('contact_header')}
                </Typography>
                <Divider />
                <div>
                  <EmailIcon className={classes.icon}>
                  </EmailIcon>
                  <Typography className={classes.text} variant="subtitle1" gutterBottom>
                  <a href="mailto:volumio@lnconsult.at?subject=VOS%20APP%20Development">{t('contact_email')}</a>
                  </Typography>
                </div>
                <Divider />
                <div>
                  <PhoneIcon className={classes.icon}>
                  </PhoneIcon>
                  <Typography className={classes.text} variant="subtitle1" gutterBottom>
                  <a href="tel:+4359809100">{t('contact_phone')}</a>
                  </Typography>
                </div>
              </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paperQR} style={{height: "92.5%"}}>
              <Typography className={classes.header} variant="h5" color="black" gutterBottom>
                {t('playstore_text')}
              </Typography>
              <Divider />
              <Tooltip title={t('download_click')}>
                <a href={endpoints.VOSAPP} download>
                  <img
                  src={DownloadSvg}
                  style={{ height: 180, width: 180 }}
                  alt="vosapp download"
                  />
                </a>
              </Tooltip>
              <Tooltip title={t('download_scan')} placement="top-end">
                <QRCode size={130} className={classes.qr} value={endpoints.VOSAPP}/>
              </Tooltip>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs>
            <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h5" color="black" gutterBottom>
                  {t('info_header')}
                </Typography>
                <Divider />
                <div>
                  <CodeIcon className={classes.icon}>
                  </CodeIcon>
                  <Typography className={classes.text} variant="subtitle1" gutterBottom>
                  {t('info_version')}: {constants.VERSION}
                  </Typography>
                </div>
                <Divider />
                <div className={classes.changelog}>
                  <Markdown children={content.md}></Markdown>
                </div>
              </Paper>
          </Grid>
          <Grid item xs={2}>
          </Grid>
        </Grid>
      </div>
      );
}


export default Info;